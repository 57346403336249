(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File !== 'undefined' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[36m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.bT.aF === region.bb.aF)
	{
		return 'on line ' + region.bT.aF;
	}
	return 'on lines ' + region.bT.aF + ' through ' + region.bb.aF;
}



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return !isNaN(word)
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.dN,
		impl.ea,
		impl.d5,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	var initPair = init(result.a);
	var model = initPair.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		var pair = A2(update, msg, model);
		stepper(model = pair.a, viewMetadata);
		_Platform_enqueueEffects(managers, pair.b, subscriptions(model));
	}

	_Platform_enqueueEffects(managers, initPair.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS
//
// Effects must be queued!
//
// Say your init contains a synchronous command, like Time.now or Time.here
//
//   - This will produce a batch of effects (FX_1)
//   - The synchronous task triggers the subsequent `update` call
//   - This will produce a batch of effects (FX_2)
//
// If we just start dispatching FX_2, subscriptions from FX_2 can be processed
// before subscriptions from FX_1. No good! Earlier versions of this code had
// this problem, leading to these reports:
//
//   https://github.com/elm/core/issues/980
//   https://github.com/elm/core/pull/981
//   https://github.com/elm/compiler/issues/1776
//
// The queue is necessary to avoid ordering issues for synchronous commands.


// Why use true/false here? Why not just check the length of the queue?
// The goal is to detect "are we currently dispatching effects?" If we
// are, we need to bail and let the ongoing while loop handle things.
//
// Now say the queue has 1 element. When we dequeue the final element,
// the queue will be empty, but we are still actively dispatching effects.
// So you could get queue jumping in a really tricky category of cases.
//
var _Platform_effectsQueue = [];
var _Platform_effectsActive = false;


function _Platform_enqueueEffects(managers, cmdBag, subBag)
{
	_Platform_effectsQueue.push({ p: managers, q: cmdBag, r: subBag });

	if (_Platform_effectsActive) return;

	_Platform_effectsActive = true;
	for (var fx; fx = _Platform_effectsQueue.shift(); )
	{
		_Platform_dispatchEffects(fx.p, fx.q, fx.r);
	}
	_Platform_effectsActive = false;
}


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				s: bag.n,
				t: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.t)
		{
			x = temp.s(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		u: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		u: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS
//
// For some reason, tabs can appear in href protocols and it still works.
// So '\tjava\tSCRIPT:alert("!!!")' and 'javascript:alert("!!!")' are the same
// in practice. That is why _VirtualDom_RE_js and _VirtualDom_RE_js_html look
// so freaky.
//
// Pulling the regular expressions out to the top level gives a slight speed
// boost in small benchmarks (4-10%) but hoisting values to reduce allocation
// can be unpredictable in large programs where JIT may have a harder time with
// functions are not fully self-contained. The benefit is more that the js and
// js_html ones are so weird that I prefer to see them near each other.


var _VirtualDom_RE_script = /^script$/i;
var _VirtualDom_RE_on_formAction = /^(on|formAction$)/i;
var _VirtualDom_RE_js = /^\s*j\s*a\s*v\s*a\s*s\s*c\s*r\s*i\s*p\s*t\s*:/i;
var _VirtualDom_RE_js_html = /^\s*(j\s*a\s*v\s*a\s*s\s*c\s*r\s*i\s*p\s*t\s*:|d\s*a\s*t\s*a\s*:\s*t\s*e\s*x\s*t\s*\/\s*h\s*t\s*m\s*l\s*(,|;))/i;


function _VirtualDom_noScript(tag)
{
	return _VirtualDom_RE_script.test(tag) ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return _VirtualDom_RE_on_formAction.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return _VirtualDom_RE_js.test(value)
		? /**/''//*//**_UNUSED/'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'//*/
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return _VirtualDom_RE_js_html.test(value)
		? /**/''//*//**_UNUSED/'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'//*/
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlJson(value)
{
	return (typeof _Json_unwrap(value) === 'string' && _VirtualDom_RE_js_html.test(_Json_unwrap(value)))
		? _Json_wrap(
			/**/''//*//**_UNUSED/'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'//*/
		) : value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		J: func(record.J),
		cs: record.cs,
		co: record.co
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.J;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.cs;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.co) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.dN,
		impl.ea,
		impl.d5,
		function(sendToApp, initialModel) {
			var view = impl.ec;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.dN,
		impl.ea,
		impl.d5,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.cr && impl.cr(sendToApp)
			var view = impl.ec;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.dw);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.d7) && (_VirtualDom_doc.title = title = doc.d7);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.dX;
	var onUrlRequest = impl.dY;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		cr: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.c8 === next.c8
							&& curr.cQ === next.cQ
							&& curr.c4.a === next.c4.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		dN: function(flags)
		{
			return A3(impl.dN, flags, _Browser_getUrl(), key);
		},
		ec: impl.ec,
		ea: impl.ea,
		d5: impl.d5
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { dJ: 'hidden', dx: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { dJ: 'mozHidden', dx: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { dJ: 'msHidden', dx: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { dJ: 'webkitHidden', dx: 'webkitvisibilitychange' }
		: { dJ: 'hidden', dx: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		dd: _Browser_getScene(),
		$7: {
			dp: _Browser_window.pageXOffset,
			dq: _Browser_window.pageYOffset,
			b1: _Browser_doc.documentElement.clientWidth,
			bj: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		b1: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		bj: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			dd: {
				b1: node.scrollWidth,
				bj: node.scrollHeight
			},
			$7: {
				dp: node.scrollLeft,
				dq: node.scrollTop,
				b1: node.clientWidth,
				bj: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			dd: _Browser_getScene(),
			$7: {
				dp: x,
				dq: y,
				b1: _Browser_doc.documentElement.clientWidth,
				bj: _Browser_doc.documentElement.clientHeight
			},
			dF: {
				dp: x + rect.left,
				dq: y + rect.top,
				b1: rect.width,
				bj: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}



function _Time_now(millisToPosix)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(millisToPosix(Date.now())));
	});
}

var _Time_setInterval = F2(function(interval, task)
{
	return _Scheduler_binding(function(callback)
	{
		var id = setInterval(function() { _Scheduler_rawSpawn(task); }, interval);
		return function() { clearInterval(id); };
	});
});

function _Time_here()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(
			A2($elm$time$Time$customZone, -(new Date().getTimezoneOffset()), _List_Nil)
		));
	});
}


function _Time_getZoneName()
{
	return _Scheduler_binding(function(callback)
	{
		try
		{
			var name = $elm$time$Time$Name(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
		catch (e)
		{
			var name = $elm$time$Time$Offset(new Date().getTimezoneOffset());
		}
		callback(_Scheduler_succeed(name));
	});
}


function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return $elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return $elm$core$Maybe$Nothing;
	}
}


var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $elm$core$List$cons = _List_cons;
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.h) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.j),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.j);
		} else {
			var treeLen = builder.h * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.k) : builder.k;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.h);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.j) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.j);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{k: nodeList, h: (len / $elm$core$Array$branchFactor) | 0, j: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {cP: fragment, cQ: host, d_: path, c4: port_, c8: protocol, c9: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$element = _Browser_element;
var $author$project$Main$Now = function (a) {
	return {$: 1, a: a};
};
var $author$project$Main$ROIMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$Main$Timezone = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $elm$time$Time$Name = function (a) {
	return {$: 0, a: a};
};
var $elm$time$Time$Offset = function (a) {
	return {$: 1, a: a};
};
var $elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$customZone = $elm$time$Time$Zone;
var $elm$time$Time$here = _Time_here(0);
var $author$project$ROI$Update$DateRangeMsg = function (a) {
	return {$: 7, a: a};
};
var $author$project$Component$DateRange$Now = function (a) {
	return {$: 1, a: a};
};
var $elm$time$Time$Posix = $elm$core$Basics$identity;
var $elm$time$Time$millisToPosix = $elm$core$Basics$identity;
var $elm$time$Time$now = _Time_now($elm$time$Time$millisToPosix);
var $author$project$Component$DateRange$init = _Utils_Tuple2(
	$elm$core$Maybe$Nothing,
	A2($elm$core$Task$perform, $author$project$Component$DateRange$Now, $elm$time$Time$now));
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $author$project$ROI$Update$initialModel = F2(
	function (dateRange, numberOfGreenhouses) {
		var area = $elm$core$String$fromInt(
			A2(
				$elm$core$Maybe$withDefault,
				3000,
				A2(
					$elm$core$Maybe$map,
					function (n) {
						return n * 3000;
					},
					numberOfGreenhouses)));
		return {b6: area, cd: '18', a5: dateRange, cg: '15', cn: '25', cp: '15', cq: '7', cv: '15'};
	});
var $elm$core$Platform$Cmd$map = _Platform_map;
var $author$project$ROI$Update$init = function (numberOfGreenhouses) {
	var _v0 = $author$project$Component$DateRange$init;
	var dateRange = _v0.a;
	var dateRangeCmd = _v0.b;
	return _Utils_Tuple2(
		A2($author$project$ROI$Update$initialModel, dateRange, numberOfGreenhouses),
		A2($elm$core$Platform$Cmd$map, $author$project$ROI$Update$DateRangeMsg, dateRangeCmd));
};
var $author$project$Language$French = 1;
var $author$project$Models$ROIParameters$defaultMonthlySubscription = 50;
var $author$project$Models$ROIParameters$defaultNumberOfGreenhouses = 1;
var $author$project$Models$ROIParameters$defaultSystemPrice = 3200;
var $author$project$Main$initialModel = F2(
	function (roi, roiParameters) {
		var systemPrice = A2($elm$core$Maybe$withDefault, $author$project$Models$ROIParameters$defaultSystemPrice, roiParameters.bV);
		var quoteLanguage = A2($elm$core$Maybe$withDefault, 1, roiParameters.aj);
		var numberOfGreenhouses = A2($elm$core$Maybe$withDefault, $author$project$Models$ROIParameters$defaultNumberOfGreenhouses, roiParameters.aK);
		var numberOfEquipments = A2($elm$core$Maybe$withDefault, $author$project$Models$ROIParameters$defaultNumberOfGreenhouses, roiParameters.bz);
		var monthlySubscription = A2($elm$core$Maybe$withDefault, $author$project$Models$ROIParameters$defaultMonthlySubscription, roiParameters.bw);
		return {aj: quoteLanguage, bw: monthlySubscription, c$: $elm$core$Maybe$Nothing, bz: numberOfEquipments, aK: numberOfGreenhouses, aO: roi, bV: systemPrice, cu: $elm$core$Maybe$Nothing};
	});
var $author$project$Models$ROIParameters$defaultNumberOfEquipments = 0;
var $author$project$Models$ROIParameters$initialModel = {
	aj: $elm$core$Maybe$Just(1),
	bw: $elm$core$Maybe$Just($author$project$Models$ROIParameters$defaultMonthlySubscription),
	bz: $elm$core$Maybe$Just($author$project$Models$ROIParameters$defaultNumberOfEquipments),
	aK: $elm$core$Maybe$Just($author$project$Models$ROIParameters$defaultNumberOfGreenhouses),
	bV: $elm$core$Maybe$Just($author$project$Models$ROIParameters$defaultSystemPrice)
};
var $elm$url$Url$Parser$State = F5(
	function (visited, unvisited, params, frag, value) {
		return {S: frag, V: params, O: unvisited, D: value, _: visited};
	});
var $elm$url$Url$Parser$getFirstMatch = function (states) {
	getFirstMatch:
	while (true) {
		if (!states.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			var state = states.a;
			var rest = states.b;
			var _v1 = state.O;
			if (!_v1.b) {
				return $elm$core$Maybe$Just(state.D);
			} else {
				if ((_v1.a === '') && (!_v1.b.b)) {
					return $elm$core$Maybe$Just(state.D);
				} else {
					var $temp$states = rest;
					states = $temp$states;
					continue getFirstMatch;
				}
			}
		}
	}
};
var $elm$url$Url$Parser$removeFinalEmpty = function (segments) {
	if (!segments.b) {
		return _List_Nil;
	} else {
		if ((segments.a === '') && (!segments.b.b)) {
			return _List_Nil;
		} else {
			var segment = segments.a;
			var rest = segments.b;
			return A2(
				$elm$core$List$cons,
				segment,
				$elm$url$Url$Parser$removeFinalEmpty(rest));
		}
	}
};
var $elm$url$Url$Parser$preparePath = function (path) {
	var _v0 = A2($elm$core$String$split, '/', path);
	if (_v0.b && (_v0.a === '')) {
		var segments = _v0.b;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	} else {
		var segments = _v0;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	}
};
var $elm$url$Url$Parser$addToParametersHelp = F2(
	function (value, maybeList) {
		if (maybeList.$ === 1) {
			return $elm$core$Maybe$Just(
				_List_fromArray(
					[value]));
		} else {
			var list = maybeList.a;
			return $elm$core$Maybe$Just(
				A2($elm$core$List$cons, value, list));
		}
	});
var $elm$url$Url$percentDecode = _Url_percentDecode;
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$url$Url$Parser$addParam = F2(
	function (segment, dict) {
		var _v0 = A2($elm$core$String$split, '=', segment);
		if ((_v0.b && _v0.b.b) && (!_v0.b.b.b)) {
			var rawKey = _v0.a;
			var _v1 = _v0.b;
			var rawValue = _v1.a;
			var _v2 = $elm$url$Url$percentDecode(rawKey);
			if (_v2.$ === 1) {
				return dict;
			} else {
				var key = _v2.a;
				var _v3 = $elm$url$Url$percentDecode(rawValue);
				if (_v3.$ === 1) {
					return dict;
				} else {
					var value = _v3.a;
					return A3(
						$elm$core$Dict$update,
						key,
						$elm$url$Url$Parser$addToParametersHelp(value),
						dict);
				}
			}
		} else {
			return dict;
		}
	});
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $elm$url$Url$Parser$prepareQuery = function (maybeQuery) {
	if (maybeQuery.$ === 1) {
		return $elm$core$Dict$empty;
	} else {
		var qry = maybeQuery.a;
		return A3(
			$elm$core$List$foldr,
			$elm$url$Url$Parser$addParam,
			$elm$core$Dict$empty,
			A2($elm$core$String$split, '&', qry));
	}
};
var $elm$url$Url$Parser$parse = F2(
	function (_v0, url) {
		var parser = _v0;
		return $elm$url$Url$Parser$getFirstMatch(
			parser(
				A5(
					$elm$url$Url$Parser$State,
					_List_Nil,
					$elm$url$Url$Parser$preparePath(url.d_),
					$elm$url$Url$Parser$prepareQuery(url.c9),
					url.cP,
					$elm$core$Basics$identity)));
	});
var $author$project$Models$ROIParameters$ROIParameters = F5(
	function (language, numberOfGreenhouses, systemPrice, monthlySubscription, numberOfEquipments) {
		return {aj: language, bw: monthlySubscription, bz: numberOfEquipments, aK: numberOfGreenhouses, bV: systemPrice};
	});
var $elm$core$Maybe$andThen = F2(
	function (callback, maybeValue) {
		if (!maybeValue.$) {
			var value = maybeValue.a;
			return callback(value);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$url$Url$Parser$Internal$Parser = $elm$core$Basics$identity;
var $elm$url$Url$Parser$Query$map = F2(
	function (func, _v0) {
		var a = _v0;
		return function (dict) {
			return func(
				a(dict));
		};
	});
var $author$project$Language$English = 0;
var $author$project$Routing$parseLanguage = function (str) {
	switch (str) {
		case 'FR':
			return $elm$core$Maybe$Just(1);
		case 'EN':
			return $elm$core$Maybe$Just(0);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $elm$url$Url$Parser$Query$custom = F2(
	function (key, func) {
		return function (dict) {
			return func(
				A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					A2($elm$core$Dict$get, key, dict)));
		};
	});
var $elm$url$Url$Parser$Query$string = function (key) {
	return A2(
		$elm$url$Url$Parser$Query$custom,
		key,
		function (stringList) {
			if (stringList.b && (!stringList.b.b)) {
				var str = stringList.a;
				return $elm$core$Maybe$Just(str);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		});
};
var $author$project$Routing$languageParser = A2(
	$elm$url$Url$Parser$Query$map,
	$elm$core$Maybe$andThen($author$project$Routing$parseLanguage),
	$elm$url$Url$Parser$Query$string('language'));
var $elm$url$Url$Parser$Query$map5 = F6(
	function (func, _v0, _v1, _v2, _v3, _v4) {
		var a = _v0;
		var b = _v1;
		var c = _v2;
		var d = _v3;
		var e = _v4;
		return function (dict) {
			return A5(
				func,
				a(dict),
				b(dict),
				c(dict),
				d(dict),
				e(dict));
		};
	});
var $elm$url$Url$Parser$Query$int = function (key) {
	return A2(
		$elm$url$Url$Parser$Query$custom,
		key,
		function (stringList) {
			if (stringList.b && (!stringList.b.b)) {
				var str = stringList.a;
				return $elm$core$String$toInt(str);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		});
};
var $author$project$Routing$monthlySubscriptionParser = $elm$url$Url$Parser$Query$int('monthlySubscription');
var $author$project$Routing$numberOfEquipmentsParser = $elm$url$Url$Parser$Query$int('numberOfEquipments');
var $author$project$Routing$numberOfGreenhousesParser = $elm$url$Url$Parser$Query$int('numberOfGreenhouses');
var $elm$url$Url$Parser$Parser = $elm$core$Basics$identity;
var $elm$url$Url$Parser$query = function (_v0) {
	var queryParser = _v0;
	return function (_v1) {
		var visited = _v1._;
		var unvisited = _v1.O;
		var params = _v1.V;
		var frag = _v1.S;
		var value = _v1.D;
		return _List_fromArray(
			[
				A5(
				$elm$url$Url$Parser$State,
				visited,
				unvisited,
				params,
				frag,
				value(
					queryParser(params)))
			]);
	};
};
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $elm$core$List$concatMap = F2(
	function (f, list) {
		return $elm$core$List$concat(
			A2($elm$core$List$map, f, list));
	});
var $elm$url$Url$Parser$slash = F2(
	function (_v0, _v1) {
		var parseBefore = _v0;
		var parseAfter = _v1;
		return function (state) {
			return A2(
				$elm$core$List$concatMap,
				parseAfter,
				parseBefore(state));
		};
	});
var $elm$url$Url$Parser$questionMark = F2(
	function (parser, queryParser) {
		return A2(
			$elm$url$Url$Parser$slash,
			parser,
			$elm$url$Url$Parser$query(queryParser));
	});
var $elm$url$Url$Parser$s = function (str) {
	return function (_v0) {
		var visited = _v0._;
		var unvisited = _v0.O;
		var params = _v0.V;
		var frag = _v0.S;
		var value = _v0.D;
		if (!unvisited.b) {
			return _List_Nil;
		} else {
			var next = unvisited.a;
			var rest = unvisited.b;
			return _Utils_eq(next, str) ? _List_fromArray(
				[
					A5(
					$elm$url$Url$Parser$State,
					A2($elm$core$List$cons, next, visited),
					rest,
					params,
					frag,
					value)
				]) : _List_Nil;
		}
	};
};
var $author$project$Routing$systemPriceParser = $elm$url$Url$Parser$Query$int('systemPrice');
var $author$project$Routing$roiParser = function () {
	var queryParser = A6($elm$url$Url$Parser$Query$map5, $author$project$Models$ROIParameters$ROIParameters, $author$project$Routing$languageParser, $author$project$Routing$numberOfGreenhousesParser, $author$project$Routing$systemPriceParser, $author$project$Routing$monthlySubscriptionParser, $author$project$Routing$numberOfEquipmentsParser);
	return A2(
		$elm$url$Url$Parser$questionMark,
		$elm$url$Url$Parser$s('roi'),
		queryParser);
}();
var $author$project$Routing$parseUrl = function (rawUrl) {
	var url = $elm$url$Url$fromString(rawUrl);
	if (!url.$) {
		var u = url.a;
		var taggedUrl = _Utils_update(
			u,
			{d_: 'roi'});
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$Models$ROIParameters$initialModel,
			A2($elm$url$Url$Parser$parse, $author$project$Routing$roiParser, taggedUrl));
	} else {
		return $author$project$Models$ROIParameters$initialModel;
	}
};
var $author$project$Main$init = function (url) {
	var queryParameters = $author$project$Routing$parseUrl(url);
	var _v0 = $author$project$ROI$Update$init(queryParameters.aK);
	var roiModel = _v0.a;
	var roiCmd = _v0.b;
	return _Utils_Tuple2(
		A2($author$project$Main$initialModel, roiModel, queryParameters),
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					A2($elm$core$Platform$Cmd$map, $author$project$Main$ROIMsg, roiCmd),
					A2($elm$core$Task$perform, $author$project$Main$Now, $elm$time$Time$now),
					A2($elm$core$Task$perform, $author$project$Main$Timezone, $elm$time$Time$here)
				])));
};
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $elm$core$Platform$Sub$none = $elm$core$Platform$Sub$batch(_List_Nil);
var $elm$json$Json$Decode$string = _Json_decodeString;
var $rtfeldman$elm_css$VirtualDom$Styled$UnscopedStyles = function (a) {
	return {$: 0, a: a};
};
var $elm$core$String$cons = _String_cons;
var $robinheghan$murmur3$Murmur3$HashData = F4(
	function (shift, seed, hash, charsProcessed) {
		return {ad: charsProcessed, ai: hash, X: seed, aq: shift};
	});
var $robinheghan$murmur3$Murmur3$c1 = 3432918353;
var $robinheghan$murmur3$Murmur3$c2 = 461845907;
var $elm$core$Bitwise$and = _Bitwise_and;
var $elm$core$Bitwise$shiftLeftBy = _Bitwise_shiftLeftBy;
var $elm$core$Bitwise$shiftRightZfBy = _Bitwise_shiftRightZfBy;
var $robinheghan$murmur3$Murmur3$multiplyBy = F2(
	function (b, a) {
		return ((a & 65535) * b) + ((((a >>> 16) * b) & 65535) << 16);
	});
var $elm$core$Basics$neq = _Utils_notEqual;
var $elm$core$Bitwise$or = _Bitwise_or;
var $robinheghan$murmur3$Murmur3$rotlBy = F2(
	function (b, a) {
		return (a << b) | (a >>> (32 - b));
	});
var $elm$core$Bitwise$xor = _Bitwise_xor;
var $robinheghan$murmur3$Murmur3$finalize = function (data) {
	var acc = (!(!data.ai)) ? (data.X ^ A2(
		$robinheghan$murmur3$Murmur3$multiplyBy,
		$robinheghan$murmur3$Murmur3$c2,
		A2(
			$robinheghan$murmur3$Murmur3$rotlBy,
			15,
			A2($robinheghan$murmur3$Murmur3$multiplyBy, $robinheghan$murmur3$Murmur3$c1, data.ai)))) : data.X;
	var h0 = acc ^ data.ad;
	var h1 = A2($robinheghan$murmur3$Murmur3$multiplyBy, 2246822507, h0 ^ (h0 >>> 16));
	var h2 = A2($robinheghan$murmur3$Murmur3$multiplyBy, 3266489909, h1 ^ (h1 >>> 13));
	return (h2 ^ (h2 >>> 16)) >>> 0;
};
var $elm$core$String$foldl = _String_foldl;
var $robinheghan$murmur3$Murmur3$mix = F2(
	function (h1, k1) {
		return A2(
			$robinheghan$murmur3$Murmur3$multiplyBy,
			5,
			A2(
				$robinheghan$murmur3$Murmur3$rotlBy,
				13,
				h1 ^ A2(
					$robinheghan$murmur3$Murmur3$multiplyBy,
					$robinheghan$murmur3$Murmur3$c2,
					A2(
						$robinheghan$murmur3$Murmur3$rotlBy,
						15,
						A2($robinheghan$murmur3$Murmur3$multiplyBy, $robinheghan$murmur3$Murmur3$c1, k1))))) + 3864292196;
	});
var $robinheghan$murmur3$Murmur3$hashFold = F2(
	function (c, data) {
		var res = data.ai | ((255 & $elm$core$Char$toCode(c)) << data.aq);
		var _v0 = data.aq;
		if (_v0 === 24) {
			return {
				ad: data.ad + 1,
				ai: 0,
				X: A2($robinheghan$murmur3$Murmur3$mix, data.X, res),
				aq: 0
			};
		} else {
			return {ad: data.ad + 1, ai: res, X: data.X, aq: data.aq + 8};
		}
	});
var $robinheghan$murmur3$Murmur3$hashString = F2(
	function (seed, str) {
		return $robinheghan$murmur3$Murmur3$finalize(
			A3(
				$elm$core$String$foldl,
				$robinheghan$murmur3$Murmur3$hashFold,
				A4($robinheghan$murmur3$Murmur3$HashData, 0, seed, 0, 0),
				str));
	});
var $rtfeldman$elm_css$Hash$initialSeed = 15739;
var $elm$core$String$fromList = _String_fromList;
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $elm$core$Basics$modBy = _Basics_modBy;
var $rtfeldman$elm_hex$Hex$unsafeToDigit = function (num) {
	unsafeToDigit:
	while (true) {
		switch (num) {
			case 0:
				return '0';
			case 1:
				return '1';
			case 2:
				return '2';
			case 3:
				return '3';
			case 4:
				return '4';
			case 5:
				return '5';
			case 6:
				return '6';
			case 7:
				return '7';
			case 8:
				return '8';
			case 9:
				return '9';
			case 10:
				return 'a';
			case 11:
				return 'b';
			case 12:
				return 'c';
			case 13:
				return 'd';
			case 14:
				return 'e';
			case 15:
				return 'f';
			default:
				var $temp$num = num;
				num = $temp$num;
				continue unsafeToDigit;
		}
	}
};
var $rtfeldman$elm_hex$Hex$unsafePositiveToDigits = F2(
	function (digits, num) {
		unsafePositiveToDigits:
		while (true) {
			if (num < 16) {
				return A2(
					$elm$core$List$cons,
					$rtfeldman$elm_hex$Hex$unsafeToDigit(num),
					digits);
			} else {
				var $temp$digits = A2(
					$elm$core$List$cons,
					$rtfeldman$elm_hex$Hex$unsafeToDigit(
						A2($elm$core$Basics$modBy, 16, num)),
					digits),
					$temp$num = (num / 16) | 0;
				digits = $temp$digits;
				num = $temp$num;
				continue unsafePositiveToDigits;
			}
		}
	});
var $rtfeldman$elm_hex$Hex$toString = function (num) {
	return $elm$core$String$fromList(
		(num < 0) ? A2(
			$elm$core$List$cons,
			'-',
			A2($rtfeldman$elm_hex$Hex$unsafePositiveToDigits, _List_Nil, -num)) : A2($rtfeldman$elm_hex$Hex$unsafePositiveToDigits, _List_Nil, num));
};
var $rtfeldman$elm_css$Hash$fromString = function (str) {
	return A2(
		$elm$core$String$cons,
		'_',
		$rtfeldman$elm_hex$Hex$toString(
			A2($robinheghan$murmur3$Murmur3$hashString, $rtfeldman$elm_css$Hash$initialSeed, str)));
};
var $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles = F2(
	function (_v0, styles) {
		var isCssStyles = _v0.b;
		var cssTemplate = _v0.c;
		if (isCssStyles) {
			var _v1 = A2($elm$core$Dict$get, cssTemplate, styles);
			if (!_v1.$) {
				return styles;
			} else {
				return A3(
					$elm$core$Dict$insert,
					cssTemplate,
					$rtfeldman$elm_css$Hash$fromString(cssTemplate),
					styles);
			}
		} else {
			return styles;
		}
	});
var $elm$virtual_dom$VirtualDom$property = F2(
	function (key, value) {
		return A2(
			_VirtualDom_property,
			_VirtualDom_noInnerHtmlOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlJson(value));
	});
var $elm$json$Json$Encode$string = _Json_wrap;
var $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute = F2(
	function (styles, _v0) {
		var val = _v0.a;
		var isCssStyles = _v0.b;
		var cssTemplate = _v0.c;
		if (isCssStyles) {
			var _v1 = A2($elm$core$Dict$get, cssTemplate, styles);
			if (!_v1.$) {
				var classname = _v1.a;
				return A2(
					$elm$virtual_dom$VirtualDom$property,
					'className',
					$elm$json$Json$Encode$string(classname));
			} else {
				return A2(
					$elm$virtual_dom$VirtualDom$property,
					'className',
					$elm$json$Json$Encode$string('_unstyled'));
			}
		} else {
			return val;
		}
	});
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttributeNS = F2(
	function (styles, _v0) {
		var val = _v0.a;
		var isCssStyles = _v0.b;
		var cssTemplate = _v0.c;
		if (isCssStyles) {
			var _v1 = A2($elm$core$Dict$get, cssTemplate, styles);
			if (!_v1.$) {
				var classname = _v1.a;
				return A2($elm$virtual_dom$VirtualDom$attribute, 'class', classname);
			} else {
				return A2($elm$virtual_dom$VirtualDom$attribute, 'class', '_unstyled');
			}
		} else {
			return val;
		}
	});
var $elm$virtual_dom$VirtualDom$keyedNode = function (tag) {
	return _VirtualDom_keyedNode(
		_VirtualDom_noScript(tag));
};
var $elm$virtual_dom$VirtualDom$keyedNodeNS = F2(
	function (namespace, tag) {
		return A2(
			_VirtualDom_keyedNodeNS,
			namespace,
			_VirtualDom_noScript(tag));
	});
var $elm$virtual_dom$VirtualDom$node = function (tag) {
	return _VirtualDom_node(
		_VirtualDom_noScript(tag));
};
var $elm$virtual_dom$VirtualDom$nodeNS = F2(
	function (namespace, tag) {
		return A2(
			_VirtualDom_nodeNS,
			namespace,
			_VirtualDom_noScript(tag));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml = F2(
	function (_v6, _v7) {
		var key = _v6.a;
		var html = _v6.b;
		var pairs = _v7.a;
		var styles = _v7.b;
		switch (html.$) {
			case 4:
				var vdom = html.a;
				return _Utils_Tuple2(
					A2(
						$elm$core$List$cons,
						_Utils_Tuple2(key, vdom),
						pairs),
					styles);
			case 0:
				var elemType = html.a;
				var properties = html.b;
				var children = html.c;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v9 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v9.a;
				var finalStyles = _v9.b;
				var vdom = A3(
					$elm$virtual_dom$VirtualDom$node,
					elemType,
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute(finalStyles),
						properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2(
						$elm$core$List$cons,
						_Utils_Tuple2(key, vdom),
						pairs),
					finalStyles);
			case 1:
				var ns = html.a;
				var elemType = html.b;
				var properties = html.c;
				var children = html.d;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v10 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v10.a;
				var finalStyles = _v10.b;
				var vdom = A4(
					$elm$virtual_dom$VirtualDom$nodeNS,
					ns,
					elemType,
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute(finalStyles),
						properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2(
						$elm$core$List$cons,
						_Utils_Tuple2(key, vdom),
						pairs),
					finalStyles);
			case 2:
				var elemType = html.a;
				var properties = html.b;
				var children = html.c;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v11 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v11.a;
				var finalStyles = _v11.b;
				var vdom = A3(
					$elm$virtual_dom$VirtualDom$keyedNode,
					elemType,
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute(finalStyles),
						properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2(
						$elm$core$List$cons,
						_Utils_Tuple2(key, vdom),
						pairs),
					finalStyles);
			default:
				var ns = html.a;
				var elemType = html.b;
				var properties = html.c;
				var children = html.d;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v12 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v12.a;
				var finalStyles = _v12.b;
				var vdom = A4(
					$elm$virtual_dom$VirtualDom$keyedNodeNS,
					ns,
					elemType,
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute(finalStyles),
						properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2(
						$elm$core$List$cons,
						_Utils_Tuple2(key, vdom),
						pairs),
					finalStyles);
		}
	});
var $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml = F2(
	function (html, _v0) {
		var nodes = _v0.a;
		var styles = _v0.b;
		switch (html.$) {
			case 4:
				var vdomNode = html.a;
				return _Utils_Tuple2(
					A2($elm$core$List$cons, vdomNode, nodes),
					styles);
			case 0:
				var elemType = html.a;
				var properties = html.b;
				var children = html.c;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v2 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v2.a;
				var finalStyles = _v2.b;
				var vdomNode = A3(
					$elm$virtual_dom$VirtualDom$node,
					elemType,
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute(finalStyles),
						properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2($elm$core$List$cons, vdomNode, nodes),
					finalStyles);
			case 1:
				var ns = html.a;
				var elemType = html.b;
				var properties = html.c;
				var children = html.d;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v3 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v3.a;
				var finalStyles = _v3.b;
				var vdomNode = A4(
					$elm$virtual_dom$VirtualDom$nodeNS,
					ns,
					elemType,
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttributeNS(finalStyles),
						properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2($elm$core$List$cons, vdomNode, nodes),
					finalStyles);
			case 2:
				var elemType = html.a;
				var properties = html.b;
				var children = html.c;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v4 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v4.a;
				var finalStyles = _v4.b;
				var vdomNode = A3(
					$elm$virtual_dom$VirtualDom$keyedNode,
					elemType,
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute(finalStyles),
						properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2($elm$core$List$cons, vdomNode, nodes),
					finalStyles);
			default:
				var ns = html.a;
				var elemType = html.b;
				var properties = html.c;
				var children = html.d;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v5 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v5.a;
				var finalStyles = _v5.b;
				var vdomNode = A4(
					$elm$virtual_dom$VirtualDom$keyedNodeNS,
					ns,
					elemType,
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttributeNS(finalStyles),
						properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2($elm$core$List$cons, vdomNode, nodes),
					finalStyles);
		}
	});
var $elm$core$List$singleton = function (value) {
	return _List_fromArray(
		[value]);
};
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === -2) {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var $rtfeldman$elm_css$VirtualDom$Styled$classnameStandin = '\u0007';
var $elm$core$String$replace = F3(
	function (before, after, string) {
		return A2(
			$elm$core$String$join,
			after,
			A2($elm$core$String$split, before, string));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$styleToDeclaration = F3(
	function (template, classname, declaration) {
		return declaration + ('\n' + A3($elm$core$String$replace, $rtfeldman$elm_css$VirtualDom$Styled$classnameStandin, classname, template));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$toDeclaration = function (dict) {
	return A3($elm$core$Dict$foldl, $rtfeldman$elm_css$VirtualDom$Styled$styleToDeclaration, '', dict);
};
var $rtfeldman$elm_css$VirtualDom$Styled$toScopedDeclaration = F2(
	function (scopingPrefix, dict) {
		return A3(
			$elm$core$Dict$foldl,
			F3(
				function (template, classname, declaration) {
					return declaration + ('\n' + A3($elm$core$String$replace, '.' + $rtfeldman$elm_css$VirtualDom$Styled$classnameStandin, '#' + (scopingPrefix + ('.' + classname)), template));
				}),
			'',
			dict);
	});
var $rtfeldman$elm_css$VirtualDom$Styled$toStyleNode = F2(
	function (maybeNonce, accumulatedStyles) {
		var cssText = function () {
			if (!accumulatedStyles.$) {
				var allStyles = accumulatedStyles.a;
				return $rtfeldman$elm_css$VirtualDom$Styled$toDeclaration(allStyles);
			} else {
				var scope = accumulatedStyles.a;
				var rootStyles = accumulatedStyles.b;
				var descendantStyles = accumulatedStyles.c;
				return A2($rtfeldman$elm_css$VirtualDom$Styled$toScopedDeclaration, scope, rootStyles) + ('\n' + A2($rtfeldman$elm_css$VirtualDom$Styled$toScopedDeclaration, scope + ' ', descendantStyles));
			}
		}();
		return A3(
			$elm$virtual_dom$VirtualDom$node,
			'span',
			_List_fromArray(
				[
					A2($elm$virtual_dom$VirtualDom$attribute, 'style', 'display: none;'),
					A2($elm$virtual_dom$VirtualDom$attribute, 'class', 'elm-css-style-wrapper')
				]),
			_List_fromArray(
				[
					A3(
					$elm$virtual_dom$VirtualDom$node,
					'style',
					function () {
						if (!maybeNonce.$) {
							var nonce = maybeNonce.a;
							return _List_fromArray(
								[
									A2($elm$virtual_dom$VirtualDom$attribute, 'nonce', nonce)
								]);
						} else {
							return _List_Nil;
						}
					}(),
					$elm$core$List$singleton(
						$elm$virtual_dom$VirtualDom$text(cssText)))
				]));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$unstyle = F4(
	function (maybeNonce, elemType, properties, children) {
		var initialStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, $elm$core$Dict$empty, properties);
		var _v0 = A3(
			$elm$core$List$foldl,
			$rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
			_Utils_Tuple2(_List_Nil, initialStyles),
			children);
		var childNodes = _v0.a;
		var styles = _v0.b;
		var styleNode = A2(
			$rtfeldman$elm_css$VirtualDom$Styled$toStyleNode,
			maybeNonce,
			$rtfeldman$elm_css$VirtualDom$Styled$UnscopedStyles(styles));
		var unstyledProperties = A2(
			$elm$core$List$map,
			$rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute(styles),
			properties);
		return A3(
			$elm$virtual_dom$VirtualDom$node,
			elemType,
			unstyledProperties,
			A2(
				$elm$core$List$cons,
				styleNode,
				$elm$core$List$reverse(childNodes)));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$containsKey = F2(
	function (key, pairs) {
		containsKey:
		while (true) {
			if (!pairs.b) {
				return false;
			} else {
				var _v1 = pairs.a;
				var str = _v1.a;
				var rest = pairs.b;
				if (_Utils_eq(key, str)) {
					return true;
				} else {
					var $temp$key = key,
						$temp$pairs = rest;
					key = $temp$key;
					pairs = $temp$pairs;
					continue containsKey;
				}
			}
		}
	});
var $rtfeldman$elm_css$VirtualDom$Styled$getUnusedKey = F2(
	function (_default, pairs) {
		getUnusedKey:
		while (true) {
			if (!pairs.b) {
				return _default;
			} else {
				var _v1 = pairs.a;
				var firstKey = _v1.a;
				var rest = pairs.b;
				var newKey = '_' + firstKey;
				if (A2($rtfeldman$elm_css$VirtualDom$Styled$containsKey, newKey, rest)) {
					var $temp$default = newKey,
						$temp$pairs = rest;
					_default = $temp$default;
					pairs = $temp$pairs;
					continue getUnusedKey;
				} else {
					return newKey;
				}
			}
		}
	});
var $rtfeldman$elm_css$VirtualDom$Styled$toKeyedStyleNode = F3(
	function (maybeNonce, accumulatedStyles, keyedChildNodes) {
		var styleNodeKey = A2($rtfeldman$elm_css$VirtualDom$Styled$getUnusedKey, '_', keyedChildNodes);
		var finalNode = A2($rtfeldman$elm_css$VirtualDom$Styled$toStyleNode, maybeNonce, accumulatedStyles);
		return _Utils_Tuple2(styleNodeKey, finalNode);
	});
var $rtfeldman$elm_css$VirtualDom$Styled$unstyleKeyed = F4(
	function (maybeNonce, elemType, properties, keyedChildren) {
		var initialStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, $elm$core$Dict$empty, properties);
		var _v0 = A3(
			$elm$core$List$foldl,
			$rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
			_Utils_Tuple2(_List_Nil, initialStyles),
			keyedChildren);
		var keyedChildNodes = _v0.a;
		var styles = _v0.b;
		var keyedStyleNode = A3(
			$rtfeldman$elm_css$VirtualDom$Styled$toKeyedStyleNode,
			maybeNonce,
			$rtfeldman$elm_css$VirtualDom$Styled$UnscopedStyles(styles),
			keyedChildNodes);
		var unstyledProperties = A2(
			$elm$core$List$map,
			$rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute(styles),
			properties);
		return A3(
			$elm$virtual_dom$VirtualDom$keyedNode,
			elemType,
			unstyledProperties,
			A2(
				$elm$core$List$cons,
				keyedStyleNode,
				$elm$core$List$reverse(keyedChildNodes)));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$unstyleKeyedNS = F5(
	function (maybeNonce, ns, elemType, properties, keyedChildren) {
		var initialStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, $elm$core$Dict$empty, properties);
		var _v0 = A3(
			$elm$core$List$foldl,
			$rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
			_Utils_Tuple2(_List_Nil, initialStyles),
			keyedChildren);
		var keyedChildNodes = _v0.a;
		var styles = _v0.b;
		var keyedStyleNode = A3(
			$rtfeldman$elm_css$VirtualDom$Styled$toKeyedStyleNode,
			maybeNonce,
			$rtfeldman$elm_css$VirtualDom$Styled$UnscopedStyles(styles),
			keyedChildNodes);
		var unstyledProperties = A2(
			$elm$core$List$map,
			$rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttributeNS(styles),
			properties);
		return A4(
			$elm$virtual_dom$VirtualDom$keyedNodeNS,
			ns,
			elemType,
			unstyledProperties,
			A2(
				$elm$core$List$cons,
				keyedStyleNode,
				$elm$core$List$reverse(keyedChildNodes)));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$unstyleNS = F5(
	function (maybeNonce, ns, elemType, properties, children) {
		var initialStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, $elm$core$Dict$empty, properties);
		var _v0 = A3(
			$elm$core$List$foldl,
			$rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
			_Utils_Tuple2(_List_Nil, initialStyles),
			children);
		var childNodes = _v0.a;
		var styles = _v0.b;
		var styleNode = A2(
			$rtfeldman$elm_css$VirtualDom$Styled$toStyleNode,
			maybeNonce,
			$rtfeldman$elm_css$VirtualDom$Styled$UnscopedStyles(styles));
		var unstyledProperties = A2(
			$elm$core$List$map,
			$rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttributeNS(styles),
			properties);
		return A4(
			$elm$virtual_dom$VirtualDom$nodeNS,
			ns,
			elemType,
			unstyledProperties,
			A2(
				$elm$core$List$cons,
				styleNode,
				$elm$core$List$reverse(childNodes)));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$toUnstyled = function (vdom) {
	switch (vdom.$) {
		case 4:
			var plainNode = vdom.a;
			return plainNode;
		case 0:
			var elemType = vdom.a;
			var properties = vdom.b;
			var children = vdom.c;
			return A4($rtfeldman$elm_css$VirtualDom$Styled$unstyle, $elm$core$Maybe$Nothing, elemType, properties, children);
		case 1:
			var ns = vdom.a;
			var elemType = vdom.b;
			var properties = vdom.c;
			var children = vdom.d;
			return A5($rtfeldman$elm_css$VirtualDom$Styled$unstyleNS, $elm$core$Maybe$Nothing, ns, elemType, properties, children);
		case 2:
			var elemType = vdom.a;
			var properties = vdom.b;
			var children = vdom.c;
			return A4($rtfeldman$elm_css$VirtualDom$Styled$unstyleKeyed, $elm$core$Maybe$Nothing, elemType, properties, children);
		default:
			var ns = vdom.a;
			var elemType = vdom.b;
			var properties = vdom.c;
			var children = vdom.d;
			return A5($rtfeldman$elm_css$VirtualDom$Styled$unstyleKeyedNS, $elm$core$Maybe$Nothing, ns, elemType, properties, children);
	}
};
var $rtfeldman$elm_css$Html$Styled$toUnstyled = $rtfeldman$elm_css$VirtualDom$Styled$toUnstyled;
var $elm$core$Tuple$mapFirst = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			func(x),
			y);
	});
var $elm$core$Tuple$mapSecond = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			x,
			func(y));
	});
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Types$DateLimit = function (a) {
	return {$: 0, a: a};
};
var $elm$time$Time$Mar = 2;
var $elm$time$Time$Nov = 10;
var $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$SelectedDateRange = F2(
	function (startDate, endDate) {
		return {cL: endDate, dh: startDate};
	});
var $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Types$Single = 0;
var $elm$time$Time$Sun = 6;
var $PanagiotisGeorgiadis$elm_datetime$DateTime$Internal$DateTime = $elm$core$Basics$identity;
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$Date = $elm$core$Basics$identity;
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$Year = $elm$core$Basics$identity;
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$dayToInt = function (_v0) {
	var day = _v0;
	return day;
};
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$compareDays = F2(
	function (lhs, rhs) {
		return A2(
			$elm$core$Basics$compare,
			$PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$dayToInt(lhs),
			$PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$dayToInt(rhs));
	});
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$Day = $elm$core$Basics$identity;
var $elm$core$Basics$not = _Basics_not;
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$isLeapYear = function (_v0) {
	var _int = _v0;
	return (!A2($elm$core$Basics$modBy, 4, _int)) && ((!A2($elm$core$Basics$modBy, 400, _int)) || (!(!A2($elm$core$Basics$modBy, 100, _int))));
};
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$lastDayOf = F2(
	function (year, month) {
		switch (month) {
			case 0:
				return 31;
			case 1:
				return $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$isLeapYear(year) ? 29 : 28;
			case 2:
				return 31;
			case 3:
				return 30;
			case 4:
				return 31;
			case 5:
				return 30;
			case 6:
				return 31;
			case 7:
				return 31;
			case 8:
				return 30;
			case 9:
				return 31;
			case 10:
				return 30;
			default:
				return 31;
		}
	});
var $elm$time$Time$Apr = 3;
var $elm$time$Time$Aug = 7;
var $elm$time$Time$Dec = 11;
var $elm$time$Time$Feb = 1;
var $elm$time$Time$Jan = 0;
var $elm$time$Time$Jul = 6;
var $elm$time$Time$Jun = 5;
var $elm$time$Time$May = 4;
var $elm$time$Time$Oct = 9;
var $elm$time$Time$Sep = 8;
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$rollMonthBackwards = function (month) {
	switch (month) {
		case 0:
			return 11;
		case 1:
			return 0;
		case 2:
			return 1;
		case 3:
			return 2;
		case 4:
			return 3;
		case 5:
			return 4;
		case 6:
			return 5;
		case 7:
			return 6;
		case 8:
			return 7;
		case 9:
			return 8;
		case 10:
			return 9;
		default:
			return 10;
	}
};
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$yearToInt = function (_v0) {
	var year = _v0;
	return year;
};
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$decrementMonth = function (_v0) {
	var date = _v0;
	var updatedMonth = $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$rollMonthBackwards(date.cY);
	var updatedYear = function () {
		if (updatedMonth === 11) {
			return $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$yearToInt(date.dr) - 1;
		} else {
			return date.dr;
		}
	}();
	var lastDayOfUpdatedMonth = A2($PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$lastDayOf, updatedYear, updatedMonth);
	var updatedDay = function () {
		var _v1 = A2($PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$compareDays, date.cK, lastDayOfUpdatedMonth);
		if (_v1 === 2) {
			return lastDayOfUpdatedMonth;
		} else {
			return date.cK;
		}
	}();
	return {cK: updatedDay, cY: updatedMonth, dr: updatedYear};
};
var $PanagiotisGeorgiadis$elm_datetime$Calendar$decrementMonth = $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$decrementMonth;
var $PanagiotisGeorgiadis$elm_datetime$DateTime$Internal$decrementMonth = function (_v0) {
	var date = _v0.cJ;
	var time = _v0.d6;
	return {
		cJ: $PanagiotisGeorgiadis$elm_datetime$Calendar$decrementMonth(date),
		d6: time
	};
};
var $PanagiotisGeorgiadis$elm_datetime$DateTime$decrementMonth = $PanagiotisGeorgiadis$elm_datetime$DateTime$Internal$decrementMonth;
var $elm$time$Time$flooredDiv = F2(
	function (numerator, denominator) {
		return $elm$core$Basics$floor(numerator / denominator);
	});
var $elm$time$Time$posixToMillis = function (_v0) {
	var millis = _v0;
	return millis;
};
var $elm$time$Time$toAdjustedMinutesHelp = F3(
	function (defaultOffset, posixMinutes, eras) {
		toAdjustedMinutesHelp:
		while (true) {
			if (!eras.b) {
				return posixMinutes + defaultOffset;
			} else {
				var era = eras.a;
				var olderEras = eras.b;
				if (_Utils_cmp(era.bT, posixMinutes) < 0) {
					return posixMinutes + era.c0;
				} else {
					var $temp$defaultOffset = defaultOffset,
						$temp$posixMinutes = posixMinutes,
						$temp$eras = olderEras;
					defaultOffset = $temp$defaultOffset;
					posixMinutes = $temp$posixMinutes;
					eras = $temp$eras;
					continue toAdjustedMinutesHelp;
				}
			}
		}
	});
var $elm$time$Time$toAdjustedMinutes = F2(
	function (_v0, time) {
		var defaultOffset = _v0.a;
		var eras = _v0.b;
		return A3(
			$elm$time$Time$toAdjustedMinutesHelp,
			defaultOffset,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				60000),
			eras);
	});
var $elm$core$Basics$ge = _Utils_ge;
var $elm$time$Time$toCivil = function (minutes) {
	var rawDay = A2($elm$time$Time$flooredDiv, minutes, 60 * 24) + 719468;
	var era = (((rawDay >= 0) ? rawDay : (rawDay - 146096)) / 146097) | 0;
	var dayOfEra = rawDay - (era * 146097);
	var yearOfEra = ((((dayOfEra - ((dayOfEra / 1460) | 0)) + ((dayOfEra / 36524) | 0)) - ((dayOfEra / 146096) | 0)) / 365) | 0;
	var dayOfYear = dayOfEra - (((365 * yearOfEra) + ((yearOfEra / 4) | 0)) - ((yearOfEra / 100) | 0));
	var mp = (((5 * dayOfYear) + 2) / 153) | 0;
	var month = mp + ((mp < 10) ? 3 : (-9));
	var year = yearOfEra + (era * 400);
	return {
		cK: (dayOfYear - ((((153 * mp) + 2) / 5) | 0)) + 1,
		cY: month,
		dr: year + ((month <= 2) ? 1 : 0)
	};
};
var $elm$time$Time$toDay = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).cK;
	});
var $elm$time$Time$toMonth = F2(
	function (zone, time) {
		var _v0 = $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).cY;
		switch (_v0) {
			case 1:
				return 0;
			case 2:
				return 1;
			case 3:
				return 2;
			case 4:
				return 3;
			case 5:
				return 4;
			case 6:
				return 5;
			case 7:
				return 6;
			case 8:
				return 7;
			case 9:
				return 8;
			case 10:
				return 9;
			case 11:
				return 10;
			default:
				return 11;
		}
	});
var $elm$time$Time$toYear = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).dr;
	});
var $elm$time$Time$utc = A2($elm$time$Time$Zone, 0, _List_Nil);
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$fromPosix = function (posix) {
	return {
		cK: A2($elm$time$Time$toDay, $elm$time$Time$utc, posix),
		cY: A2($elm$time$Time$toMonth, $elm$time$Time$utc, posix),
		dr: A2($elm$time$Time$toYear, $elm$time$Time$utc, posix)
	};
};
var $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$Hour = $elm$core$Basics$identity;
var $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$Millisecond = $elm$core$Basics$identity;
var $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$Minute = $elm$core$Basics$identity;
var $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$Second = $elm$core$Basics$identity;
var $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$Time = $elm$core$Basics$identity;
var $elm$time$Time$toHour = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			24,
			A2(
				$elm$time$Time$flooredDiv,
				A2($elm$time$Time$toAdjustedMinutes, zone, time),
				60));
	});
var $elm$time$Time$toMillis = F2(
	function (_v0, time) {
		return A2(
			$elm$core$Basics$modBy,
			1000,
			$elm$time$Time$posixToMillis(time));
	});
var $elm$time$Time$toMinute = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2($elm$time$Time$toAdjustedMinutes, zone, time));
	});
var $elm$time$Time$toSecond = F2(
	function (_v0, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				1000));
	});
var $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$fromPosix = function (posix) {
	return {
		cR: A2($elm$time$Time$toHour, $elm$time$Time$utc, posix),
		cW: A2($elm$time$Time$toMillis, $elm$time$Time$utc, posix),
		cX: A2($elm$time$Time$toMinute, $elm$time$Time$utc, posix),
		de: A2($elm$time$Time$toSecond, $elm$time$Time$utc, posix)
	};
};
var $PanagiotisGeorgiadis$elm_datetime$DateTime$Internal$fromPosix = function (timePosix) {
	return {
		cJ: $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$fromPosix(timePosix),
		d6: $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$fromPosix(timePosix)
	};
};
var $PanagiotisGeorgiadis$elm_datetime$DateTime$fromPosix = $PanagiotisGeorgiadis$elm_datetime$DateTime$Internal$fromPosix;
var $PanagiotisGeorgiadis$elm_datetime$DateTime$Internal$InternalDateTime = F2(
	function (date, time) {
		return {cJ: date, d6: time};
	});
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$dayFromInt = F3(
	function (year, month, day) {
		var maxValidDay = $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$dayToInt(
			A2($PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$lastDayOf, year, month));
		return ((day > 0) && (A2($elm$core$Basics$compare, day, maxValidDay) !== 2)) ? $elm$core$Maybe$Just(day) : $elm$core$Maybe$Nothing;
	});
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$fromYearMonthDay = F3(
	function (y, m, d) {
		var maxDay = A2($PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$lastDayOf, y, m);
		var _v0 = A2($PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$compareDays, d, maxDay);
		if (_v0 === 2) {
			return $elm$core$Maybe$Nothing;
		} else {
			return $elm$core$Maybe$Just(
				{cK: d, cY: m, dr: y});
		}
	});
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$fromRawDay = F3(
	function (year, month, day) {
		return A2(
			$elm$core$Maybe$andThen,
			A2($PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$fromYearMonthDay, year, month),
			A3($PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$dayFromInt, year, month, day));
	});
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$yearFromInt = function (year) {
	return (year > 0) ? $elm$core$Maybe$Just(year) : $elm$core$Maybe$Nothing;
};
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$fromRawParts = function (_v0) {
	var year = _v0.dr;
	var month = _v0.cY;
	var day = _v0.cK;
	return A2(
		$elm$core$Maybe$andThen,
		function (y) {
			return A3($PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$fromRawDay, y, month, day);
		},
		$PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$yearFromInt(year));
};
var $PanagiotisGeorgiadis$elm_datetime$Calendar$fromRawParts = $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$fromRawParts;
var $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$InternalTime = F4(
	function (hours, minutes, seconds, milliseconds) {
		return {cR: hours, cW: milliseconds, cX: minutes, de: seconds};
	});
var $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$hoursFromInt = function (hours) {
	return ((hours >= 0) && (hours < 24)) ? $elm$core$Maybe$Just(hours) : $elm$core$Maybe$Nothing;
};
var $elm$core$Maybe$map4 = F5(
	function (func, ma, mb, mc, md) {
		if (ma.$ === 1) {
			return $elm$core$Maybe$Nothing;
		} else {
			var a = ma.a;
			if (mb.$ === 1) {
				return $elm$core$Maybe$Nothing;
			} else {
				var b = mb.a;
				if (mc.$ === 1) {
					return $elm$core$Maybe$Nothing;
				} else {
					var c = mc.a;
					if (md.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var d = md.a;
						return $elm$core$Maybe$Just(
							A4(func, a, b, c, d));
					}
				}
			}
		}
	});
var $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$millisecondsFromInt = function (millis) {
	return ((millis >= 0) && (millis < 1000)) ? $elm$core$Maybe$Just(millis) : $elm$core$Maybe$Nothing;
};
var $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$minutesFromInt = function (minutes) {
	return ((minutes >= 0) && (minutes < 60)) ? $elm$core$Maybe$Just(minutes) : $elm$core$Maybe$Nothing;
};
var $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$secondsFromInt = function (seconds) {
	return ((seconds >= 0) && (seconds < 60)) ? $elm$core$Maybe$Just(seconds) : $elm$core$Maybe$Nothing;
};
var $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$fromRawParts = function (_v0) {
	var hours = _v0.cR;
	var minutes = _v0.cX;
	var seconds = _v0.de;
	var milliseconds = _v0.cW;
	return A5(
		$elm$core$Maybe$map4,
		F4(
			function (h, m, s, mm) {
				return A4($PanagiotisGeorgiadis$elm_datetime$Clock$Internal$InternalTime, h, m, s, mm);
			}),
		$PanagiotisGeorgiadis$elm_datetime$Clock$Internal$hoursFromInt(hours),
		$PanagiotisGeorgiadis$elm_datetime$Clock$Internal$minutesFromInt(minutes),
		$PanagiotisGeorgiadis$elm_datetime$Clock$Internal$secondsFromInt(seconds),
		$PanagiotisGeorgiadis$elm_datetime$Clock$Internal$millisecondsFromInt(milliseconds));
};
var $PanagiotisGeorgiadis$elm_datetime$Clock$fromRawParts = $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$fromRawParts;
var $elm$core$Maybe$map2 = F3(
	function (func, ma, mb) {
		if (ma.$ === 1) {
			return $elm$core$Maybe$Nothing;
		} else {
			var a = ma.a;
			if (mb.$ === 1) {
				return $elm$core$Maybe$Nothing;
			} else {
				var b = mb.a;
				return $elm$core$Maybe$Just(
					A2(func, a, b));
			}
		}
	});
var $PanagiotisGeorgiadis$elm_datetime$DateTime$Internal$fromRawParts = F2(
	function (rawDate, rawTime) {
		return A3(
			$elm$core$Maybe$map2,
			F2(
				function (date, time) {
					return A2($PanagiotisGeorgiadis$elm_datetime$DateTime$Internal$InternalDateTime, date, time);
				}),
			$PanagiotisGeorgiadis$elm_datetime$Calendar$fromRawParts(rawDate),
			$PanagiotisGeorgiadis$elm_datetime$Clock$fromRawParts(rawTime));
	});
var $PanagiotisGeorgiadis$elm_datetime$DateTime$fromRawParts = F2(
	function (rawDate, rawTime) {
		return A2($PanagiotisGeorgiadis$elm_datetime$DateTime$Internal$fromRawParts, rawDate, rawTime);
	});
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $PanagiotisGeorgiadis$elm_datetime$DateTime$Internal$getDate = function (_v0) {
	var date = _v0.cJ;
	return date;
};
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$getYear = function (_v0) {
	var year = _v0.dr;
	return year;
};
var $PanagiotisGeorgiadis$elm_datetime$Calendar$getYear = A2($elm$core$Basics$composeL, $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$yearToInt, $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$getYear);
var $PanagiotisGeorgiadis$elm_datetime$DateTime$Internal$getYear = A2($elm$core$Basics$composeL, $PanagiotisGeorgiadis$elm_datetime$Calendar$getYear, $PanagiotisGeorgiadis$elm_datetime$DateTime$Internal$getDate);
var $PanagiotisGeorgiadis$elm_datetime$DateTime$getYear = $PanagiotisGeorgiadis$elm_datetime$DateTime$Internal$getYear;
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$rollMonthForward = function (month) {
	switch (month) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		case 6:
			return 7;
		case 7:
			return 8;
		case 8:
			return 9;
		case 9:
			return 10;
		case 10:
			return 11;
		default:
			return 0;
	}
};
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$incrementMonth = function (_v0) {
	var date = _v0;
	var updatedMonth = $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$rollMonthForward(date.cY);
	var updatedYear = function () {
		if (!updatedMonth) {
			return $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$yearToInt(date.dr) + 1;
		} else {
			return date.dr;
		}
	}();
	var lastDayOfUpdatedMonth = A2($PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$lastDayOf, updatedYear, updatedMonth);
	var updatedDay = function () {
		var _v1 = A2($PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$compareDays, date.cK, lastDayOfUpdatedMonth);
		if (_v1 === 2) {
			return lastDayOfUpdatedMonth;
		} else {
			return date.cK;
		}
	}();
	return {cK: updatedDay, cY: updatedMonth, dr: updatedYear};
};
var $PanagiotisGeorgiadis$elm_datetime$Calendar$incrementMonth = $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$incrementMonth;
var $PanagiotisGeorgiadis$elm_datetime$DateTime$Internal$incrementMonth = function (_v0) {
	var date = _v0.cJ;
	var time = _v0.d6;
	return {
		cJ: $PanagiotisGeorgiadis$elm_datetime$Calendar$incrementMonth(date),
		d6: time
	};
};
var $PanagiotisGeorgiadis$elm_datetime$DateTime$incrementMonth = $PanagiotisGeorgiadis$elm_datetime$DateTime$Internal$incrementMonth;
var $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$DoubleCalendar = 1;
var $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$Model = $elm$core$Basics$identity;
var $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$NoOffset = {$: 1};
var $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$NoTimePickers = {$: 0};
var $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$NoneSelected = {$: 0};
var $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$Offset = function (a) {
	return {$: 0, a: a};
};
var $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$SingleCalendar = 0;
var $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$TimePickers = function (a) {
	return {$: 1, a: a};
};
var $PanagiotisGeorgiadis$elm_datepicker$DatePicker$Internal$I18n$monthToString = F2(
	function (mode, month) {
		var monthString = function () {
			switch (month) {
				case 0:
					return 'January';
				case 1:
					return 'February';
				case 2:
					return 'March';
				case 3:
					return 'April';
				case 4:
					return 'May';
				case 5:
					return 'June';
				case 6:
					return 'July';
				case 7:
					return 'August';
				case 8:
					return 'September';
				case 9:
					return 'October';
				case 10:
					return 'November';
				default:
					return 'December';
			}
		}();
		if (!mode) {
			return A2($elm$core$String$left, 3, monthString);
		} else {
			return monthString;
		}
	});
var $PanagiotisGeorgiadis$elm_datepicker$DatePicker$Internal$I18n$weekdayToString = F2(
	function (mode, weekday) {
		var weekdayString = function () {
			switch (weekday) {
				case 0:
					return 'Monday';
				case 1:
					return 'Tuesday';
				case 2:
					return 'Wednesday';
				case 3:
					return 'Thursday';
				case 4:
					return 'Friday';
				case 5:
					return 'Saturday';
				default:
					return 'Sunday';
			}
		}();
		if (!mode) {
			return A2($elm$core$String$left, 3, weekdayString);
		} else {
			return weekdayString;
		}
	});
var $PanagiotisGeorgiadis$elm_datepicker$DatePicker$Internal$I18n$defaultI18n = {dV: $PanagiotisGeorgiadis$elm_datepicker$DatePicker$Internal$I18n$monthToString, d9: 'Today', ee: $PanagiotisGeorgiadis$elm_datepicker$DatePicker$Internal$I18n$weekdayToString};
var $PanagiotisGeorgiadis$elm_datepicker$TimePicker$Internal$Update$Hours = 0;
var $PanagiotisGeorgiadis$elm_datepicker$TimePicker$Internal$Update$Milliseconds = 3;
var $PanagiotisGeorgiadis$elm_datepicker$TimePicker$Internal$Update$Minutes = 1;
var $PanagiotisGeorgiadis$elm_datepicker$TimePicker$Internal$Update$Model = $elm$core$Basics$identity;
var $PanagiotisGeorgiadis$elm_datepicker$TimePicker$Internal$Update$Seconds = 2;
var $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$getHours = function (_v0) {
	var hours = _v0.cR;
	return hours;
};
var $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$hoursToInt = function (_v0) {
	var hours = _v0;
	return hours;
};
var $PanagiotisGeorgiadis$elm_datetime$Clock$getHours = A2($elm$core$Basics$composeL, $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$hoursToInt, $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$getHours);
var $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$getMilliseconds = function (_v0) {
	var milliseconds = _v0.cW;
	return milliseconds;
};
var $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$millisecondsToInt = function (_v0) {
	var milliseconds = _v0;
	return milliseconds;
};
var $PanagiotisGeorgiadis$elm_datetime$Clock$getMilliseconds = A2($elm$core$Basics$composeL, $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$millisecondsToInt, $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$getMilliseconds);
var $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$getMinutes = function (_v0) {
	var minutes = _v0.cX;
	return minutes;
};
var $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$minutesToInt = function (_v0) {
	var minutes = _v0;
	return minutes;
};
var $PanagiotisGeorgiadis$elm_datetime$Clock$getMinutes = A2($elm$core$Basics$composeL, $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$minutesToInt, $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$getMinutes);
var $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$getSeconds = function (_v0) {
	var seconds = _v0.de;
	return seconds;
};
var $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$secondsToInt = function (_v0) {
	var seconds = _v0;
	return seconds;
};
var $PanagiotisGeorgiadis$elm_datetime$Clock$getSeconds = A2($elm$core$Basics$composeL, $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$secondsToInt, $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$getSeconds);
var $PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$millisToString = function (millis) {
	return (millis < 10) ? ('00' + $elm$core$String$fromInt(millis)) : ((millis < 100) ? ('0' + $elm$core$String$fromInt(millis)) : $elm$core$String$fromInt(millis));
};
var $PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$timeToString = function (time) {
	return (time < 10) ? ('0' + $elm$core$String$fromInt(time)) : $elm$core$String$fromInt(time);
};
var $PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$toString = function (timePart) {
	switch (timePart) {
		case 0:
			return A2($elm$core$Basics$composeL, $PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$timeToString, $PanagiotisGeorgiadis$elm_datetime$Clock$getHours);
		case 1:
			return A2($elm$core$Basics$composeL, $PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$timeToString, $PanagiotisGeorgiadis$elm_datetime$Clock$getMinutes);
		case 2:
			return A2($elm$core$Basics$composeL, $PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$timeToString, $PanagiotisGeorgiadis$elm_datetime$Clock$getSeconds);
		default:
			return A2($elm$core$Basics$composeL, $PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$millisToString, $PanagiotisGeorgiadis$elm_datetime$Clock$getMilliseconds);
	}
};
var $PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$initialise = function (_v0) {
	var pickerType = _v0.c2;
	var time = _v0.d6;
	return {
		cR: A2($PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$toString, 0, time),
		cW: A2($PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$toString, 3, time),
		cX: A2($PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$toString, 1, time),
		c2: pickerType,
		de: A2($PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$toString, 2, time),
		d6: time
	};
};
var $PanagiotisGeorgiadis$elm_datetime$DateTime$Internal$setTime = F2(
	function (time, _v0) {
		var date = _v0.cJ;
		return {cJ: date, d6: time};
	});
var $PanagiotisGeorgiadis$elm_datetime$DateTime$setTime = $PanagiotisGeorgiadis$elm_datetime$DateTime$Internal$setTime;
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$getMonth = function (_v0) {
	var month = _v0.cY;
	return month;
};
var $PanagiotisGeorgiadis$elm_datetime$Calendar$getMonth = $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$getMonth;
var $PanagiotisGeorgiadis$elm_datetime$DateTime$Internal$getMonth = A2($elm$core$Basics$composeL, $PanagiotisGeorgiadis$elm_datetime$Calendar$getMonth, $PanagiotisGeorgiadis$elm_datetime$DateTime$Internal$getDate);
var $PanagiotisGeorgiadis$elm_datetime$DateTime$getMonth = $PanagiotisGeorgiadis$elm_datetime$DateTime$Internal$getMonth;
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$monthToInt = function (month) {
	switch (month) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		case 6:
			return 7;
		case 7:
			return 8;
		case 8:
			return 9;
		case 9:
			return 10;
		case 10:
			return 11;
		default:
			return 12;
	}
};
var $PanagiotisGeorgiadis$elm_datetime$Calendar$monthToInt = $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$monthToInt;
var $PanagiotisGeorgiadis$elm_datepicker$Utils$DateTime$getMonthInt = A2($elm$core$Basics$composeL, $PanagiotisGeorgiadis$elm_datetime$Calendar$monthToInt, $PanagiotisGeorgiadis$elm_datetime$DateTime$getMonth);
var $PanagiotisGeorgiadis$elm_datepicker$Utils$DateTime$compareYearMonth = F2(
	function (lhs, rhs) {
		var yearsComparison = A2(
			$elm$core$Basics$compare,
			$PanagiotisGeorgiadis$elm_datetime$DateTime$getYear(lhs),
			$PanagiotisGeorgiadis$elm_datetime$DateTime$getYear(rhs));
		if (yearsComparison === 1) {
			return A2(
				$elm$core$Basics$compare,
				$PanagiotisGeorgiadis$elm_datepicker$Utils$DateTime$getMonthInt(lhs),
				$PanagiotisGeorgiadis$elm_datepicker$Utils$DateTime$getMonthInt(rhs));
		} else {
			return yearsComparison;
		}
	});
var $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$midnight = {cR: 0, cW: 0, cX: 0, de: 0};
var $PanagiotisGeorgiadis$elm_datetime$Clock$midnight = $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$midnight;
var $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$validatePrimaryDate = function (_v0) {
	var today = _v0.d8;
	var primaryDate = _v0.d0;
	var dateLimit = _v0.dz;
	var date = function () {
		if (!primaryDate.$) {
			var d = primaryDate.a;
			return d;
		} else {
			return A2($PanagiotisGeorgiadis$elm_datetime$DateTime$setTime, $PanagiotisGeorgiadis$elm_datetime$Clock$midnight, today);
		}
	}();
	if (!dateLimit.$) {
		var minDate = dateLimit.a.dS;
		var maxDate = dateLimit.a.dQ;
		var isBetweenConstrains = ((!A2($PanagiotisGeorgiadis$elm_datepicker$Utils$DateTime$compareYearMonth, minDate, date)) || (A2($PanagiotisGeorgiadis$elm_datepicker$Utils$DateTime$compareYearMonth, minDate, date) === 1)) && ((A2($PanagiotisGeorgiadis$elm_datepicker$Utils$DateTime$compareYearMonth, maxDate, date) === 2) || (A2($PanagiotisGeorgiadis$elm_datepicker$Utils$DateTime$compareYearMonth, maxDate, date) === 1));
		return isBetweenConstrains ? date : minDate;
	} else {
		return date;
	}
};
var $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$initialise = F4(
	function (viewType, calendarConfig, timePickerConfig, i18n) {
		var today = calendarConfig.d8;
		var dateLimit = calendarConfig.dz;
		var dateRangeOffset = calendarConfig.dA;
		var startingWeekday = calendarConfig.d4;
		var viewType_ = function () {
			if (!viewType) {
				return 0;
			} else {
				return 1;
			}
		}();
		var dateRangeOffset_ = function () {
			if (!dateRangeOffset.$) {
				var minDateRangeLength = dateRangeOffset.a.dT;
				return $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$Offset(
					{dO: _List_Nil, dT: minDateRangeLength});
			} else {
				return $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$NoOffset;
			}
		}();
		var _v0 = function () {
			var dateTime = $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$validatePrimaryDate(calendarConfig);
			if (!timePickerConfig.$) {
				var t = timePickerConfig.a;
				var timePicker = $PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$initialise(
					{c2: t.c2, d6: t.dD});
				return _Utils_Tuple2(
					A2($PanagiotisGeorgiadis$elm_datetime$DateTime$setTime, t.dD, dateTime),
					$PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$TimePickers(
						{P: timePicker, dL: t.dL, aH: t.aH, M: timePicker}));
			} else {
				return _Utils_Tuple2(dateTime, $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$NoTimePickers);
			}
		}();
		var primaryDate_ = _v0.a;
		var timePickers = _v0.b;
		return {
			dz: dateLimit,
			dA: dateRangeOffset_,
			dL: function () {
				if (!i18n.$) {
					var i18n_ = i18n.a;
					return i18n_;
				} else {
					return $PanagiotisGeorgiadis$elm_datepicker$DatePicker$Internal$I18n$defaultI18n;
				}
			}(),
			d0: primaryDate_,
			d: $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$NoneSelected,
			d4: startingWeekday,
			u: timePickers,
			d8: today,
			ed: viewType_
		};
	});
var $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$BothSelected = function (a) {
	return {$: 2, a: a};
};
var $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$Chosen = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$compareMonths = F2(
	function (lhs, rhs) {
		return A2(
			$elm$core$Basics$compare,
			$PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$monthToInt(lhs),
			$PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$monthToInt(rhs));
	});
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$compareYears = F2(
	function (lhs, rhs) {
		return A2(
			$elm$core$Basics$compare,
			$PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$yearToInt(lhs),
			$PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$yearToInt(rhs));
	});
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$getDay = function (_v0) {
	var date = _v0;
	return date.cK;
};
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$compare = F2(
	function (lhs, rhs) {
		var _v0 = _Utils_Tuple3(
			A2(
				$PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$compareYears,
				$PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$getYear(lhs),
				$PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$getYear(rhs)),
			A2(
				$PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$compareMonths,
				$PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$getMonth(lhs),
				$PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$getMonth(rhs)),
			A2(
				$PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$compareDays,
				$PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$getDay(lhs),
				$PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$getDay(rhs)));
		var yearsComparison = _v0.a;
		var monthsComparison = _v0.b;
		var daysComparison = _v0.c;
		if (yearsComparison === 1) {
			if (monthsComparison === 1) {
				return daysComparison;
			} else {
				return monthsComparison;
			}
		} else {
			return yearsComparison;
		}
	});
var $PanagiotisGeorgiadis$elm_datetime$Calendar$compare = $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$compare;
var $PanagiotisGeorgiadis$elm_datetime$DateTime$Internal$compareDates = F2(
	function (_v0, _v1) {
		var lhs = _v0;
		var rhs = _v1;
		return A2($PanagiotisGeorgiadis$elm_datetime$Calendar$compare, lhs.cJ, rhs.cJ);
	});
var $PanagiotisGeorgiadis$elm_datetime$DateTime$compareDates = $PanagiotisGeorgiadis$elm_datetime$DateTime$Internal$compareDates;
var $elm$core$Basics$abs = function (n) {
	return (n < 0) ? (-n) : n;
};
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$millisInADay = ((1000 * 60) * 60) * 24;
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (!_v0.$) {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$millisInYear = function (year) {
	return $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$isLeapYear(year) ? ($PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$millisInADay * 366) : ($PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$millisInADay * 365);
};
var $elm$core$List$sum = function (numbers) {
	return A3($elm$core$List$foldl, $elm$core$Basics$add, 0, numbers);
};
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$millisSinceEpoch = function (_v0) {
	var year = _v0;
	var getTotalMillis = A2(
		$elm$core$Basics$composeL,
		A2(
			$elm$core$Basics$composeL,
			$elm$core$List$sum,
			$elm$core$List$map($PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$millisInYear)),
		$elm$core$List$filterMap($PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$yearFromInt));
	var epochYear = 1970;
	return (year >= 1970) ? getTotalMillis(
		A2($elm$core$List$range, epochYear, year - 1)) : (-getTotalMillis(
		A2($elm$core$List$range, year, epochYear - 1)));
};
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$millisSinceStartOfTheMonth = function (day) {
	return $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$millisInADay * ($PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$dayToInt(day) - 1);
};
var $elm$core$Array$fromListHelp = F3(
	function (list, nodeList, nodeListSize) {
		fromListHelp:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, list);
			var jsArray = _v0.a;
			var remainingItems = _v0.b;
			if (_Utils_cmp(
				$elm$core$Elm$JsArray$length(jsArray),
				$elm$core$Array$branchFactor) < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					true,
					{k: nodeList, h: nodeListSize, j: jsArray});
			} else {
				var $temp$list = remainingItems,
					$temp$nodeList = A2(
					$elm$core$List$cons,
					$elm$core$Array$Leaf(jsArray),
					nodeList),
					$temp$nodeListSize = nodeListSize + 1;
				list = $temp$list;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue fromListHelp;
			}
		}
	});
var $elm$core$Array$fromList = function (list) {
	if (!list.b) {
		return $elm$core$Array$empty;
	} else {
		return A3($elm$core$Array$fromListHelp, list, _List_Nil, 0);
	}
};
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$months = $elm$core$Array$fromList(
	_List_fromArray(
		[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]));
var $elm$core$Elm$JsArray$appendN = _JsArray_appendN;
var $elm$core$Elm$JsArray$slice = _JsArray_slice;
var $elm$core$Array$appendHelpBuilder = F2(
	function (tail, builder) {
		var tailLen = $elm$core$Elm$JsArray$length(tail);
		var notAppended = ($elm$core$Array$branchFactor - $elm$core$Elm$JsArray$length(builder.j)) - tailLen;
		var appended = A3($elm$core$Elm$JsArray$appendN, $elm$core$Array$branchFactor, builder.j, tail);
		return (notAppended < 0) ? {
			k: A2(
				$elm$core$List$cons,
				$elm$core$Array$Leaf(appended),
				builder.k),
			h: builder.h + 1,
			j: A3($elm$core$Elm$JsArray$slice, notAppended, tailLen, tail)
		} : ((!notAppended) ? {
			k: A2(
				$elm$core$List$cons,
				$elm$core$Array$Leaf(appended),
				builder.k),
			h: builder.h + 1,
			j: $elm$core$Elm$JsArray$empty
		} : {k: builder.k, h: builder.h, j: appended});
	});
var $elm$core$List$drop = F2(
	function (n, list) {
		drop:
		while (true) {
			if (n <= 0) {
				return list;
			} else {
				if (!list.b) {
					return list;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs;
					n = $temp$n;
					list = $temp$list;
					continue drop;
				}
			}
		}
	});
var $elm$core$Array$tailIndex = function (len) {
	return (len >>> 5) << 5;
};
var $elm$core$Array$sliceLeft = F2(
	function (from, array) {
		var len = array.a;
		var tree = array.c;
		var tail = array.d;
		if (!from) {
			return array;
		} else {
			if (_Utils_cmp(
				from,
				$elm$core$Array$tailIndex(len)) > -1) {
				return A4(
					$elm$core$Array$Array_elm_builtin,
					len - from,
					$elm$core$Array$shiftStep,
					$elm$core$Elm$JsArray$empty,
					A3(
						$elm$core$Elm$JsArray$slice,
						from - $elm$core$Array$tailIndex(len),
						$elm$core$Elm$JsArray$length(tail),
						tail));
			} else {
				var skipNodes = (from / $elm$core$Array$branchFactor) | 0;
				var helper = F2(
					function (node, acc) {
						if (!node.$) {
							var subTree = node.a;
							return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
						} else {
							var leaf = node.a;
							return A2($elm$core$List$cons, leaf, acc);
						}
					});
				var leafNodes = A3(
					$elm$core$Elm$JsArray$foldr,
					helper,
					_List_fromArray(
						[tail]),
					tree);
				var nodesToInsert = A2($elm$core$List$drop, skipNodes, leafNodes);
				if (!nodesToInsert.b) {
					return $elm$core$Array$empty;
				} else {
					var head = nodesToInsert.a;
					var rest = nodesToInsert.b;
					var firstSlice = from - (skipNodes * $elm$core$Array$branchFactor);
					var initialBuilder = {
						k: _List_Nil,
						h: 0,
						j: A3(
							$elm$core$Elm$JsArray$slice,
							firstSlice,
							$elm$core$Elm$JsArray$length(head),
							head)
					};
					return A2(
						$elm$core$Array$builderToArray,
						true,
						A3($elm$core$List$foldl, $elm$core$Array$appendHelpBuilder, initialBuilder, rest));
				}
			}
		}
	});
var $elm$core$Array$bitMask = 4294967295 >>> (32 - $elm$core$Array$shiftStep);
var $elm$core$Elm$JsArray$unsafeGet = _JsArray_unsafeGet;
var $elm$core$Array$fetchNewTail = F4(
	function (shift, end, treeEnd, tree) {
		fetchNewTail:
		while (true) {
			var pos = $elm$core$Array$bitMask & (treeEnd >>> shift);
			var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, pos, tree);
			if (!_v0.$) {
				var sub = _v0.a;
				var $temp$shift = shift - $elm$core$Array$shiftStep,
					$temp$end = end,
					$temp$treeEnd = treeEnd,
					$temp$tree = sub;
				shift = $temp$shift;
				end = $temp$end;
				treeEnd = $temp$treeEnd;
				tree = $temp$tree;
				continue fetchNewTail;
			} else {
				var values = _v0.a;
				return A3($elm$core$Elm$JsArray$slice, 0, $elm$core$Array$bitMask & end, values);
			}
		}
	});
var $elm$core$Array$hoistTree = F3(
	function (oldShift, newShift, tree) {
		hoistTree:
		while (true) {
			if ((_Utils_cmp(oldShift, newShift) < 1) || (!$elm$core$Elm$JsArray$length(tree))) {
				return tree;
			} else {
				var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, 0, tree);
				if (!_v0.$) {
					var sub = _v0.a;
					var $temp$oldShift = oldShift - $elm$core$Array$shiftStep,
						$temp$newShift = newShift,
						$temp$tree = sub;
					oldShift = $temp$oldShift;
					newShift = $temp$newShift;
					tree = $temp$tree;
					continue hoistTree;
				} else {
					return tree;
				}
			}
		}
	});
var $elm$core$Elm$JsArray$unsafeSet = _JsArray_unsafeSet;
var $elm$core$Array$sliceTree = F3(
	function (shift, endIdx, tree) {
		var lastPos = $elm$core$Array$bitMask & (endIdx >>> shift);
		var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, lastPos, tree);
		if (!_v0.$) {
			var sub = _v0.a;
			var newSub = A3($elm$core$Array$sliceTree, shift - $elm$core$Array$shiftStep, endIdx, sub);
			return (!$elm$core$Elm$JsArray$length(newSub)) ? A3($elm$core$Elm$JsArray$slice, 0, lastPos, tree) : A3(
				$elm$core$Elm$JsArray$unsafeSet,
				lastPos,
				$elm$core$Array$SubTree(newSub),
				A3($elm$core$Elm$JsArray$slice, 0, lastPos + 1, tree));
		} else {
			return A3($elm$core$Elm$JsArray$slice, 0, lastPos, tree);
		}
	});
var $elm$core$Array$sliceRight = F2(
	function (end, array) {
		var len = array.a;
		var startShift = array.b;
		var tree = array.c;
		var tail = array.d;
		if (_Utils_eq(end, len)) {
			return array;
		} else {
			if (_Utils_cmp(
				end,
				$elm$core$Array$tailIndex(len)) > -1) {
				return A4(
					$elm$core$Array$Array_elm_builtin,
					end,
					startShift,
					tree,
					A3($elm$core$Elm$JsArray$slice, 0, $elm$core$Array$bitMask & end, tail));
			} else {
				var endIdx = $elm$core$Array$tailIndex(end);
				var depth = $elm$core$Basics$floor(
					A2(
						$elm$core$Basics$logBase,
						$elm$core$Array$branchFactor,
						A2($elm$core$Basics$max, 1, endIdx - 1)));
				var newShift = A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep);
				return A4(
					$elm$core$Array$Array_elm_builtin,
					end,
					newShift,
					A3(
						$elm$core$Array$hoistTree,
						startShift,
						newShift,
						A3($elm$core$Array$sliceTree, startShift, endIdx, tree)),
					A4($elm$core$Array$fetchNewTail, startShift, end, endIdx, tree));
			}
		}
	});
var $elm$core$Array$translateIndex = F2(
	function (index, _v0) {
		var len = _v0.a;
		var posIndex = (index < 0) ? (len + index) : index;
		return (posIndex < 0) ? 0 : ((_Utils_cmp(posIndex, len) > 0) ? len : posIndex);
	});
var $elm$core$Array$slice = F3(
	function (from, to, array) {
		var correctTo = A2($elm$core$Array$translateIndex, to, array);
		var correctFrom = A2($elm$core$Array$translateIndex, from, array);
		return (_Utils_cmp(correctFrom, correctTo) > 0) ? $elm$core$Array$empty : A2(
			$elm$core$Array$sliceLeft,
			correctFrom,
			A2($elm$core$Array$sliceRight, correctTo, array));
	});
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$getPrecedingMonths = function (month) {
	return $elm$core$Array$toList(
		A3(
			$elm$core$Array$slice,
			0,
			$PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$monthToInt(month) - 1,
			$PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$months));
};
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$millisSinceStartOfTheYear = F2(
	function (year, month) {
		return A3(
			$elm$core$List$foldl,
			F2(
				function (m, res) {
					return res + ($PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$millisInADay * $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$dayToInt(
						A2($PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$lastDayOf, year, m)));
				}),
			0,
			$PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$getPrecedingMonths(month));
	});
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$toMillis = function (_v0) {
	var year = _v0.dr;
	var month = _v0.cY;
	var day = _v0.cK;
	return ($PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$millisSinceEpoch(year) + A2($PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$millisSinceStartOfTheYear, year, month)) + $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$millisSinceStartOfTheMonth(day);
};
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$toPosix = A2($elm$core$Basics$composeL, $elm$time$Time$millisToPosix, $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$toMillis);
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$incrementDay = function (date) {
	var millis = $elm$time$Time$posixToMillis(
		$PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$toPosix(date)) + $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$millisInADay;
	var newDate = $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$fromPosix(
		$elm$time$Time$millisToPosix(millis));
	return newDate;
};
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$getDateRange_ = F3(
	function (daysCount, prevDate, res) {
		getDateRange_:
		while (true) {
			var updatedRes = _Utils_ap(
				res,
				_List_fromArray(
					[prevDate]));
			if (daysCount > 0) {
				var _v0 = _Utils_Tuple2(
					daysCount - 1,
					$PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$incrementDay(prevDate));
				var updatedDaysCount = _v0.a;
				var updatedPrevDate = _v0.b;
				var $temp$daysCount = updatedDaysCount,
					$temp$prevDate = updatedPrevDate,
					$temp$res = updatedRes;
				daysCount = $temp$daysCount;
				prevDate = $temp$prevDate;
				res = $temp$res;
				continue getDateRange_;
			} else {
				return updatedRes;
			}
		}
	});
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$getDateRange = F2(
	function (startDate, endDate) {
		var _v0 = _Utils_Tuple2(
			$PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$toPosix(startDate),
			$PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$toPosix(endDate));
		var startPosix = _v0.a;
		var endPosix = _v0.b;
		var posixDiff = $elm$time$Time$posixToMillis(endPosix) - $elm$time$Time$posixToMillis(startPosix);
		var daysDiff = (((((((posixDiff / 1000) | 0) / 60) | 0) / 60) | 0) / 24) | 0;
		return (daysDiff > 0) ? A3($PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$getDateRange_, daysDiff, startDate, _List_Nil) : A3(
			$PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$getDateRange_,
			$elm$core$Basics$abs(daysDiff),
			endDate,
			_List_Nil);
	});
var $PanagiotisGeorgiadis$elm_datetime$Calendar$getDateRange = $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$getDateRange;
var $PanagiotisGeorgiadis$elm_datetime$DateTime$Internal$getDateRange = F3(
	function (_v0, _v1, time) {
		var start = _v0;
		var end = _v1;
		return A2(
			$elm$core$List$map,
			function (date) {
				return {cJ: date, d6: time};
			},
			A2($PanagiotisGeorgiadis$elm_datetime$Calendar$getDateRange, start.cJ, end.cJ));
	});
var $PanagiotisGeorgiadis$elm_datetime$DateTime$getDateRange = $PanagiotisGeorgiadis$elm_datetime$DateTime$Internal$getDateRange;
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$decrementDay = function (date) {
	var millis = $elm$time$Time$posixToMillis(
		$PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$toPosix(date)) - $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$millisInADay;
	var newDate = $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$fromPosix(
		$elm$time$Time$millisToPosix(millis));
	return newDate;
};
var $PanagiotisGeorgiadis$elm_datetime$Calendar$decrementDay = $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$decrementDay;
var $PanagiotisGeorgiadis$elm_datetime$DateTime$Internal$decrementDay = function (_v0) {
	var date = _v0.cJ;
	var time = _v0.d6;
	return {
		cJ: $PanagiotisGeorgiadis$elm_datetime$Calendar$decrementDay(date),
		d6: time
	};
};
var $PanagiotisGeorgiadis$elm_datetime$DateTime$decrementDay = $PanagiotisGeorgiadis$elm_datetime$DateTime$Internal$decrementDay;
var $PanagiotisGeorgiadis$elm_datepicker$Utils$DateTime$decrementDays = F2(
	function (days, date) {
		decrementDays:
		while (true) {
			if (days > 0) {
				var $temp$days = days - 1,
					$temp$date = $PanagiotisGeorgiadis$elm_datetime$DateTime$decrementDay(date);
				days = $temp$days;
				date = $temp$date;
				continue decrementDays;
			} else {
				return date;
			}
		}
	});
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $PanagiotisGeorgiadis$elm_datetime$Calendar$incrementDay = $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$incrementDay;
var $PanagiotisGeorgiadis$elm_datetime$DateTime$Internal$incrementDay = function (_v0) {
	var date = _v0.cJ;
	var time = _v0.d6;
	return {
		cJ: $PanagiotisGeorgiadis$elm_datetime$Calendar$incrementDay(date),
		d6: time
	};
};
var $PanagiotisGeorgiadis$elm_datetime$DateTime$incrementDay = $PanagiotisGeorgiadis$elm_datetime$DateTime$Internal$incrementDay;
var $PanagiotisGeorgiadis$elm_datepicker$Utils$DateTime$incrementDays = F2(
	function (days, date) {
		incrementDays:
		while (true) {
			if (days > 0) {
				var $temp$days = days - 1,
					$temp$date = $PanagiotisGeorgiadis$elm_datetime$DateTime$incrementDay(date);
				days = $temp$days;
				date = $temp$date;
				continue incrementDays;
			} else {
				return date;
			}
		}
	});
var $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$updateDateRangeOffset = function (model) {
	var range = model.d;
	var dateRangeOffset = model.dA;
	if (!dateRangeOffset.$) {
		var minDateRangeLength = dateRangeOffset.a.dT;
		var offsetConfig = function (invalidDates) {
			return {dO: invalidDates, dT: minDateRangeLength};
		};
		if (range.$ === 1) {
			var start = range.a;
			var isNotEqualToStartDate = function (d) {
				return A2($PanagiotisGeorgiadis$elm_datetime$DateTime$compareDates, start, d) !== 1;
			};
			var invalidPastDates = A2(
				$elm$core$List$filter,
				isNotEqualToStartDate,
				$elm$core$List$reverse(
					A2(
						$elm$core$List$drop,
						1,
						A3(
							$PanagiotisGeorgiadis$elm_datetime$DateTime$getDateRange,
							start,
							A2($PanagiotisGeorgiadis$elm_datepicker$Utils$DateTime$decrementDays, minDateRangeLength - 1, start),
							$PanagiotisGeorgiadis$elm_datetime$Clock$midnight))));
			var invalidFutureDates = A2(
				$elm$core$List$filter,
				isNotEqualToStartDate,
				$elm$core$List$reverse(
					A2(
						$elm$core$List$drop,
						1,
						$elm$core$List$reverse(
							A3(
								$PanagiotisGeorgiadis$elm_datetime$DateTime$getDateRange,
								start,
								A2($PanagiotisGeorgiadis$elm_datepicker$Utils$DateTime$incrementDays, minDateRangeLength - 1, start),
								$PanagiotisGeorgiadis$elm_datetime$Clock$midnight)))));
			var invalidDates = _Utils_ap(invalidFutureDates, invalidPastDates);
			return _Utils_update(
				model,
				{
					dA: $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$Offset(
						offsetConfig(invalidDates))
				});
		} else {
			return _Utils_update(
				model,
				{
					dA: $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$Offset(
						offsetConfig(_List_Nil))
				});
		}
	} else {
		return model;
	}
};
var $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$setDateRange = F2(
	function (_v0, _v1) {
		var startDate = _v0.dh;
		var endDate = _v0.cL;
		var model = _v1;
		var isOutOfBounds = function (date) {
			var _v4 = model.dz;
			if (!_v4.$) {
				var minDate = _v4.a.dS;
				var maxDate = _v4.a.dQ;
				return (!A2($PanagiotisGeorgiadis$elm_datetime$DateTime$compareDates, date, minDate)) || (A2($PanagiotisGeorgiadis$elm_datetime$DateTime$compareDates, date, maxDate) === 2);
			} else {
				return false;
			}
		};
		var isLessThanOffset = function () {
			var _v3 = model.dA;
			if (!_v3.$) {
				var minDateRangeLength = _v3.a.dT;
				var dateRange = A3($PanagiotisGeorgiadis$elm_datetime$DateTime$getDateRange, startDate, endDate, $PanagiotisGeorgiadis$elm_datetime$Clock$midnight);
				return _Utils_cmp(
					$elm$core$List$length(dateRange),
					minDateRangeLength) < 0;
			} else {
				return false;
			}
		}();
		if (isOutOfBounds(startDate) || (isOutOfBounds(endDate) || isLessThanOffset)) {
			return model;
		} else {
			var _v2 = A2($PanagiotisGeorgiadis$elm_datetime$DateTime$compareDates, startDate, endDate);
			switch (_v2) {
				case 1:
					return model;
				case 0:
					return $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$updateDateRangeOffset(
						_Utils_update(
							model,
							{
								d: $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$BothSelected(
									A2($PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$Chosen, startDate, endDate))
							}));
				default:
					return $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$updateDateRangeOffset(
						_Utils_update(
							model,
							{
								d: $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$BothSelected(
									A2($PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$Chosen, endDate, startDate))
							}));
			}
		}
	});
var $author$project$Component$DateRange$initialModel = function (todayPosix) {
	var today = $PanagiotisGeorgiadis$elm_datetime$DateTime$fromPosix(todayPosix);
	var timePickerConfig = $elm$core$Maybe$Nothing;
	var thisYear = $PanagiotisGeorgiadis$elm_datetime$DateTime$getYear(today);
	var initialStartDate = A2(
		$PanagiotisGeorgiadis$elm_datetime$DateTime$fromRawParts,
		{cK: 15, cY: 2, dr: thisYear},
		{cR: 0, cW: 0, cX: 0, de: 0});
	var initialEndDate = A2(
		$PanagiotisGeorgiadis$elm_datetime$DateTime$fromRawParts,
		{cK: 15, cY: 10, dr: thisYear},
		{cR: 0, cW: 0, cX: 0, de: 0});
	var initialDates = A3($elm$core$Maybe$map2, $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$SelectedDateRange, initialStartDate, initialEndDate);
	var _v0 = _Utils_Tuple2(
		A3(
			$elm$core$List$foldl,
			$elm$core$Basics$always($PanagiotisGeorgiadis$elm_datetime$DateTime$decrementMonth),
			today,
			A2($elm$core$List$range, 1, 12)),
		A3(
			$elm$core$List$foldl,
			$elm$core$Basics$always($PanagiotisGeorgiadis$elm_datetime$DateTime$incrementMonth),
			today,
			A2($elm$core$List$range, 1, 12)));
	var minDate = _v0.a;
	var maxDate = _v0.b;
	var calendarConfig = {
		dz: $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Types$DateLimit(
			{dQ: maxDate, dS: minDate}),
		dA: $elm$core$Maybe$Just(
			{dT: 1}),
		d0: initialStartDate,
		d4: 6,
		d8: today
	};
	var initialPicker = A4($PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$initialise, 0, calendarConfig, timePickerConfig, $elm$core$Maybe$Nothing);
	return {
		c$: todayPosix,
		aL: A2(
			$elm$core$Maybe$withDefault,
			initialPicker,
			A2(
				$elm$core$Maybe$map,
				function (ds) {
					return A2($PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$setDateRange, ds, initialPicker);
				},
				initialDates)),
		ap: initialDates
	};
};
var $author$project$Component$DateRange$PickerMsg = function (a) {
	return {$: 0, a: a};
};
var $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$DateRangeSelected = function (a) {
	return {$: 1, a: a};
};
var $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$DoubleTimePicker = 2;
var $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$None = {$: 0};
var $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$RangeEndPickerMsg = function (a) {
	return {$: 10, a: a};
};
var $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$RangeStartPickerMsg = function (a) {
	return {$: 9, a: a};
};
var $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$StartDateSelected = function (a) {
	return {$: 1, a: a};
};
var $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$SyncTimePickers = function (a) {
	return {$: 8, a: a};
};
var $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$Visually = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $PanagiotisGeorgiadis$elm_datepicker$Utils$Actions$fireAction = function (msg) {
	return A2(
		$elm$core$Task$perform,
		function (_v0) {
			return msg;
		},
		$elm$core$Task$succeed(0));
};
var $PanagiotisGeorgiadis$elm_datetime$DateTime$getDate = $PanagiotisGeorgiadis$elm_datetime$DateTime$Internal$getDate;
var $PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$getTime = function (_v0) {
	var time = _v0.d6;
	return time;
};
var $PanagiotisGeorgiadis$elm_datetime$DateTime$Internal$setDate = F2(
	function (date, _v0) {
		var time = _v0.d6;
		return {cJ: date, d6: time};
	});
var $PanagiotisGeorgiadis$elm_datetime$DateTime$setDate = $PanagiotisGeorgiadis$elm_datetime$DateTime$Internal$setDate;
var $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$ShowClockView = {$: 5};
var $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$showClockView = function (_v0) {
	var viewType = _v0.ed;
	var timePickers = _v0.u;
	var _v1 = _Utils_Tuple2(viewType, timePickers);
	if ((_v1.a === 1) && (_v1.b.$ === 1)) {
		var _v2 = _v1.a;
		return $PanagiotisGeorgiadis$elm_datepicker$Utils$Actions$fireAction($PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$ShowClockView);
	} else {
		return $elm$core$Platform$Cmd$none;
	}
};
var $PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$None = {$: 0};
var $PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$UpdatedTime = function (a) {
	return {$: 1, a: a};
};
var $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$decrementHours = function (_v0) {
	var time = _v0;
	var newHours = $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$hoursToInt(time.cR) - 1;
	return (newHours < 0) ? _Utils_Tuple2(
		_Utils_update(
			time,
			{cR: 23}),
		true) : _Utils_Tuple2(
		_Utils_update(
			time,
			{cR: newHours}),
		false);
};
var $PanagiotisGeorgiadis$elm_datetime$Clock$decrementHours = $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$decrementHours;
var $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$decrementMinutes = function (_v0) {
	var time = _v0;
	var newMinutes = $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$minutesToInt(time.cX) - 1;
	return (newMinutes < 0) ? $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$decrementHours(
		_Utils_update(
			time,
			{cX: 59})) : _Utils_Tuple2(
		_Utils_update(
			time,
			{cX: newMinutes}),
		false);
};
var $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$decrementSeconds = function (_v0) {
	var time = _v0;
	var newSeconds = $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$secondsToInt(time.de) - 1;
	return (newSeconds < 0) ? $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$decrementMinutes(
		_Utils_update(
			time,
			{de: 59})) : _Utils_Tuple2(
		_Utils_update(
			time,
			{de: newSeconds}),
		false);
};
var $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$decrementMilliseconds = function (_v0) {
	var time = _v0;
	var newMillis = $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$millisecondsToInt(time.cW) - 1;
	return (newMillis < 0) ? $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$decrementSeconds(
		_Utils_update(
			time,
			{cW: 999})) : _Utils_Tuple2(
		_Utils_update(
			time,
			{cW: newMillis}),
		false);
};
var $PanagiotisGeorgiadis$elm_datetime$Clock$decrementMilliseconds = $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$decrementMilliseconds;
var $PanagiotisGeorgiadis$elm_datetime$Clock$decrementMinutes = $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$decrementMinutes;
var $PanagiotisGeorgiadis$elm_datetime$Clock$decrementSeconds = $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$decrementSeconds;
var $PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$getHoursStep = function (_v0) {
	var pickerType = _v0.c2;
	switch (pickerType.$) {
		case 0:
			var hoursStep = pickerType.a.bk;
			return hoursStep;
		case 1:
			var hoursStep = pickerType.a.bk;
			return hoursStep;
		case 2:
			var hoursStep = pickerType.a.bk;
			return hoursStep;
		default:
			var hoursStep = pickerType.a.bk;
			return hoursStep;
	}
};
var $PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$getMillisecondsStep = function (_v0) {
	var pickerType = _v0.c2;
	switch (pickerType.$) {
		case 0:
			return 1;
		case 1:
			return 1;
		case 2:
			return 1;
		default:
			var millisecondsStep = pickerType.a.dR;
			return millisecondsStep;
	}
};
var $PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$getMinutesStep = function (_v0) {
	var pickerType = _v0.c2;
	switch (pickerType.$) {
		case 0:
			return 1;
		case 1:
			var minutesStep = pickerType.a.cj;
			return minutesStep;
		case 2:
			var minutesStep = pickerType.a.cj;
			return minutesStep;
		default:
			var minutesStep = pickerType.a.cj;
			return minutesStep;
	}
};
var $PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$getSecondsStep = function (_v0) {
	var pickerType = _v0.c2;
	switch (pickerType.$) {
		case 0:
			return 1;
		case 1:
			return 1;
		case 2:
			var secondsStep = pickerType.a.df;
			return secondsStep;
		default:
			var secondsStep = pickerType.a.df;
			return secondsStep;
	}
};
var $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$incrementHours = function (_v0) {
	var time = _v0;
	var newHours = $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$hoursToInt(time.cR) + 1;
	return (newHours >= 24) ? _Utils_Tuple2(
		_Utils_update(
			time,
			{cR: 0}),
		true) : _Utils_Tuple2(
		_Utils_update(
			time,
			{cR: newHours}),
		false);
};
var $PanagiotisGeorgiadis$elm_datetime$Clock$incrementHours = $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$incrementHours;
var $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$incrementMinutes = function (_v0) {
	var time = _v0;
	var newMinutes = $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$minutesToInt(time.cX) + 1;
	return (newMinutes >= 60) ? $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$incrementHours(
		_Utils_update(
			time,
			{cX: 0})) : _Utils_Tuple2(
		_Utils_update(
			time,
			{cX: newMinutes}),
		false);
};
var $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$incrementSeconds = function (_v0) {
	var time = _v0;
	var newSeconds = $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$secondsToInt(time.de) + 1;
	return (newSeconds >= 60) ? $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$incrementMinutes(
		_Utils_update(
			time,
			{de: 0})) : _Utils_Tuple2(
		_Utils_update(
			time,
			{de: newSeconds}),
		false);
};
var $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$incrementMilliseconds = function (_v0) {
	var time = _v0;
	var newMillis = $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$millisecondsToInt(time.cW) + 1;
	return (newMillis >= 1000) ? $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$incrementSeconds(
		_Utils_update(
			time,
			{cW: 0})) : _Utils_Tuple2(
		_Utils_update(
			time,
			{cW: newMillis}),
		false);
};
var $PanagiotisGeorgiadis$elm_datetime$Clock$incrementMilliseconds = $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$incrementMilliseconds;
var $PanagiotisGeorgiadis$elm_datetime$Clock$incrementMinutes = $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$incrementMinutes;
var $PanagiotisGeorgiadis$elm_datetime$Clock$incrementSeconds = $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$incrementSeconds;
var $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$setHours = F2(
	function (hours, time) {
		return $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$fromRawParts(
			{
				cR: hours,
				cW: $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$millisecondsToInt(
					$PanagiotisGeorgiadis$elm_datetime$Clock$Internal$getMilliseconds(time)),
				cX: $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$minutesToInt(
					$PanagiotisGeorgiadis$elm_datetime$Clock$Internal$getMinutes(time)),
				de: $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$secondsToInt(
					$PanagiotisGeorgiadis$elm_datetime$Clock$Internal$getSeconds(time))
			});
	});
var $PanagiotisGeorgiadis$elm_datetime$Clock$setHours = $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$setHours;
var $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$setMilliseconds = F2(
	function (milliseconds, time) {
		return $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$fromRawParts(
			{
				cR: $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$hoursToInt(
					$PanagiotisGeorgiadis$elm_datetime$Clock$Internal$getHours(time)),
				cW: milliseconds,
				cX: $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$minutesToInt(
					$PanagiotisGeorgiadis$elm_datetime$Clock$Internal$getMinutes(time)),
				de: $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$secondsToInt(
					$PanagiotisGeorgiadis$elm_datetime$Clock$Internal$getSeconds(time))
			});
	});
var $PanagiotisGeorgiadis$elm_datetime$Clock$setMilliseconds = $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$setMilliseconds;
var $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$setMinutes = F2(
	function (minutes, time) {
		return $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$fromRawParts(
			{
				cR: $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$hoursToInt(
					$PanagiotisGeorgiadis$elm_datetime$Clock$Internal$getHours(time)),
				cW: $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$millisecondsToInt(
					$PanagiotisGeorgiadis$elm_datetime$Clock$Internal$getMilliseconds(time)),
				cX: minutes,
				de: $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$secondsToInt(
					$PanagiotisGeorgiadis$elm_datetime$Clock$Internal$getSeconds(time))
			});
	});
var $PanagiotisGeorgiadis$elm_datetime$Clock$setMinutes = $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$setMinutes;
var $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$setSeconds = F2(
	function (seconds, time) {
		return $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$fromRawParts(
			{
				cR: $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$hoursToInt(
					$PanagiotisGeorgiadis$elm_datetime$Clock$Internal$getHours(time)),
				cW: $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$millisecondsToInt(
					$PanagiotisGeorgiadis$elm_datetime$Clock$Internal$getMilliseconds(time)),
				cX: $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$minutesToInt(
					$PanagiotisGeorgiadis$elm_datetime$Clock$Internal$getMinutes(time)),
				de: seconds
			});
	});
var $PanagiotisGeorgiadis$elm_datetime$Clock$setSeconds = $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$setSeconds;
var $PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$stepThrough = function (_v0) {
	stepThrough:
	while (true) {
		var n = _v0.aI;
		var updateFn = _v0.aU;
		var time = _v0.d6;
		var _v1 = _Utils_Tuple2(
			updateFn(time),
			n - 1);
		var time_ = _v1.a;
		var n_ = _v1.b;
		if (n_ <= 0) {
			return time_;
		} else {
			var $temp$_v0 = {aI: n_, d6: time_, aU: updateFn};
			_v0 = $temp$_v0;
			continue stepThrough;
		}
	}
};
var $PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$updateDisplayTime = F2(
	function (time, _v0) {
		var model = _v0;
		return _Utils_update(
			model,
			{
				cR: A2($PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$toString, 0, time),
				cW: A2($PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$toString, 3, time),
				cX: A2($PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$toString, 1, time),
				de: A2($PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$toString, 2, time),
				d6: time
			});
	});
var $elm$core$String$foldr = _String_foldr;
var $elm$core$String$toList = function (string) {
	return A3($elm$core$String$foldr, $elm$core$List$cons, _List_Nil, string);
};
var $PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$filterNonDigits = A2(
	$elm$core$Basics$composeL,
	A2(
		$elm$core$Basics$composeL,
		$elm$core$String$fromList,
		$elm$core$List$filter($elm$core$Char$isDigit)),
	$elm$core$String$toList);
var $PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$validateTimeSegment = function (_v0) {
	var _default = _v0.ce;
	var _new = _v0.ck;
	var ceil = _v0.cc;
	var _v1 = $elm$core$String$toInt(_new);
	if (!_v1.$) {
		var v = _v1.a;
		return ((v >= 0) && (_Utils_cmp(v, ceil) < 0)) ? _new : _default;
	} else {
		return $elm$core$String$isEmpty(_new) ? _new : _default;
	}
};
var $PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$validate = F3(
	function (_v0, timePart, newValue) {
		var hours = _v0.cR;
		var minutes = _v0.cX;
		var seconds = _v0.de;
		var milliseconds = _v0.cW;
		var sanitisedValue = $PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$filterNonDigits(newValue);
		var validationParams = F2(
			function (_default, ceil) {
				return {cc: ceil, ce: _default, ck: sanitisedValue};
			});
		switch (timePart) {
			case 0:
				return $PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$validateTimeSegment(
					A2(validationParams, hours, 24));
			case 1:
				return $PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$validateTimeSegment(
					A2(validationParams, minutes, 60));
			case 2:
				return $PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$validateTimeSegment(
					A2(validationParams, seconds, 60));
			default:
				return $PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$validateTimeSegment(
					A2(validationParams, milliseconds, 1000));
		}
	});
var $PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$update = F2(
	function (msg, _v0) {
		var model = _v0;
		switch (msg.$) {
			case 0:
				var timePart = msg.a;
				var value = msg.b;
				var validatedValue = A3($PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$validate, model, timePart, value);
				var updatedModel = function () {
					switch (timePart) {
						case 0:
							return _Utils_update(
								model,
								{cR: validatedValue});
						case 1:
							return _Utils_update(
								model,
								{cX: validatedValue});
						case 2:
							return _Utils_update(
								model,
								{de: validatedValue});
						default:
							return _Utils_update(
								model,
								{cW: validatedValue});
					}
				}();
				return _Utils_Tuple3(updatedModel, $elm$core$Platform$Cmd$none, $PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$None);
			case 1:
				var timePart = msg.a;
				var value = msg.b;
				var updatedTime = function () {
					switch (timePart) {
						case 0:
							return (value === '') ? A2($PanagiotisGeorgiadis$elm_datetime$Clock$setHours, 0, model.d6) : A2(
								$elm$core$Maybe$andThen,
								function (h) {
									return A2($PanagiotisGeorgiadis$elm_datetime$Clock$setHours, h, model.d6);
								},
								$elm$core$String$toInt(value));
						case 1:
							return (value === '') ? A2($PanagiotisGeorgiadis$elm_datetime$Clock$setMinutes, 0, model.d6) : A2(
								$elm$core$Maybe$andThen,
								function (m) {
									return A2($PanagiotisGeorgiadis$elm_datetime$Clock$setMinutes, m, model.d6);
								},
								$elm$core$String$toInt(value));
						case 2:
							return (value === '') ? A2($PanagiotisGeorgiadis$elm_datetime$Clock$setSeconds, 0, model.d6) : A2(
								$elm$core$Maybe$andThen,
								function (s) {
									return A2($PanagiotisGeorgiadis$elm_datetime$Clock$setSeconds, s, model.d6);
								},
								$elm$core$String$toInt(value));
						default:
							return (value === '') ? A2($PanagiotisGeorgiadis$elm_datetime$Clock$setMilliseconds, 0, model.d6) : A2(
								$elm$core$Maybe$andThen,
								function (m) {
									return A2($PanagiotisGeorgiadis$elm_datetime$Clock$setMilliseconds, m, model.d6);
								},
								$elm$core$String$toInt(value));
					}
				}();
				if (!updatedTime.$) {
					var time = updatedTime.a;
					var updatedModel = function () {
						switch (timePart) {
							case 0:
								return _Utils_update(
									model,
									{
										cR: A2($PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$toString, 0, time),
										d6: time
									});
							case 1:
								return _Utils_update(
									model,
									{
										cX: A2($PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$toString, 1, time),
										d6: time
									});
							case 2:
								return _Utils_update(
									model,
									{
										de: A2($PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$toString, 2, time),
										d6: time
									});
							default:
								return _Utils_update(
									model,
									{
										cW: A2($PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$toString, 3, time),
										d6: time
									});
						}
					}();
					return _Utils_Tuple3(
						updatedModel,
						$elm$core$Platform$Cmd$none,
						$PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$UpdatedTime(time));
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$None);
				}
			case 2:
				var timePart = msg.a;
				var _v6 = function () {
					switch (timePart) {
						case 0:
							return _Utils_Tuple2(
								A2($elm$core$Basics$composeL, $elm$core$Tuple$first, $PanagiotisGeorgiadis$elm_datetime$Clock$incrementHours),
								$PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$getHoursStep(model));
						case 1:
							return _Utils_Tuple2(
								A2($elm$core$Basics$composeL, $elm$core$Tuple$first, $PanagiotisGeorgiadis$elm_datetime$Clock$incrementMinutes),
								$PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$getMinutesStep(model));
						case 2:
							return _Utils_Tuple2(
								A2($elm$core$Basics$composeL, $elm$core$Tuple$first, $PanagiotisGeorgiadis$elm_datetime$Clock$incrementSeconds),
								$PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$getSecondsStep(model));
						default:
							return _Utils_Tuple2(
								A2($elm$core$Basics$composeL, $elm$core$Tuple$first, $PanagiotisGeorgiadis$elm_datetime$Clock$incrementMilliseconds),
								$PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$getMillisecondsStep(model));
					}
				}();
				var updateFn = _v6.a;
				var step = _v6.b;
				var time = $PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$stepThrough(
					{aI: step, d6: model.d6, aU: updateFn});
				return _Utils_Tuple3(
					A2($PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$updateDisplayTime, time, model),
					$elm$core$Platform$Cmd$none,
					$PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$UpdatedTime(time));
			default:
				var timePart = msg.a;
				var _v8 = function () {
					switch (timePart) {
						case 0:
							return _Utils_Tuple2(
								A2($elm$core$Basics$composeL, $elm$core$Tuple$first, $PanagiotisGeorgiadis$elm_datetime$Clock$decrementHours),
								$PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$getHoursStep(model));
						case 1:
							return _Utils_Tuple2(
								A2($elm$core$Basics$composeL, $elm$core$Tuple$first, $PanagiotisGeorgiadis$elm_datetime$Clock$decrementMinutes),
								$PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$getMinutesStep(model));
						case 2:
							return _Utils_Tuple2(
								A2($elm$core$Basics$composeL, $elm$core$Tuple$first, $PanagiotisGeorgiadis$elm_datetime$Clock$decrementSeconds),
								$PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$getSecondsStep(model));
						default:
							return _Utils_Tuple2(
								A2($elm$core$Basics$composeL, $elm$core$Tuple$first, $PanagiotisGeorgiadis$elm_datetime$Clock$decrementMilliseconds),
								$PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$getMillisecondsStep(model));
					}
				}();
				var updateFn = _v8.a;
				var step = _v8.b;
				var time = $PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$stepThrough(
					{aI: step, d6: model.d6, aU: updateFn});
				return _Utils_Tuple3(
					A2($PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$updateDisplayTime, time, model),
					$elm$core$Platform$Cmd$none,
					$PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$UpdatedTime(time));
		}
	});
var $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$update = F2(
	function (msg, _v0) {
		var model = _v0;
		switch (msg.$) {
			case 0:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							d0: $PanagiotisGeorgiadis$elm_datetime$DateTime$decrementMonth(model.d0)
						}),
					$elm$core$Platform$Cmd$none,
					$PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$None);
			case 1:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							d0: $PanagiotisGeorgiadis$elm_datetime$DateTime$incrementMonth(model.d0)
						}),
					$elm$core$Platform$Cmd$none,
					$PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$None);
			case 2:
				var date = msg.a;
				var updateModel = function (start) {
					var _v6 = A2($PanagiotisGeorgiadis$elm_datetime$DateTime$compareDates, start, date);
					switch (_v6) {
						case 1:
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{d: $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$NoneSelected}),
								$elm$core$Platform$Cmd$none,
								$PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$DateRangeSelected($elm$core$Maybe$Nothing));
						case 0:
							var _v7 = function () {
								var _v8 = model.u;
								if (_v8.$ === 1) {
									var startPicker = _v8.a.M;
									var endPicker = _v8.a.P;
									return _Utils_Tuple2(
										A2(
											$PanagiotisGeorgiadis$elm_datetime$DateTime$setTime,
											$PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$getTime(startPicker),
											start),
										A2(
											$PanagiotisGeorgiadis$elm_datetime$DateTime$setTime,
											$PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$getTime(endPicker),
											date));
								} else {
									return _Utils_Tuple2(start, date);
								}
							}();
							var start_ = _v7.a;
							var end_ = _v7.b;
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{
										d: $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$BothSelected(
											A2($PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$Chosen, start_, end_))
									}),
								$PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$showClockView(model),
								$PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$DateRangeSelected(
									$elm$core$Maybe$Just(
										{cL: end_, dh: start_})));
						default:
							var _v9 = function () {
								var _v10 = model.u;
								if (_v10.$ === 1) {
									var startPicker = _v10.a.M;
									var endPicker = _v10.a.P;
									return _Utils_Tuple2(
										A2(
											$PanagiotisGeorgiadis$elm_datetime$DateTime$setTime,
											$PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$getTime(startPicker),
											date),
										A2(
											$PanagiotisGeorgiadis$elm_datetime$DateTime$setTime,
											$PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$getTime(endPicker),
											start));
								} else {
									return _Utils_Tuple2(date, start);
								}
							}();
							var start_ = _v9.a;
							var end_ = _v9.b;
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{
										d: $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$BothSelected(
											A2($PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$Chosen, start_, end_))
									}),
								$PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$showClockView(model),
								$PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$DateRangeSelected(
									$elm$core$Maybe$Just(
										{cL: end_, dh: start_})));
					}
				};
				var _v2 = function () {
					var _v3 = model.d;
					switch (_v3.$) {
						case 1:
							var start = _v3.a;
							return updateModel(start);
						case 2:
							if (!_v3.a.$) {
								var _v4 = _v3.a;
								var start = _v4.a;
								return updateModel(start);
							} else {
								var _v5 = _v3.a;
								return _Utils_Tuple3(
									_Utils_update(
										model,
										{
											d: $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$StartDateSelected(date)
										}),
									$elm$core$Platform$Cmd$none,
									$PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$DateRangeSelected($elm$core$Maybe$Nothing));
							}
						default:
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{
										d: $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$StartDateSelected(date)
									}),
								$elm$core$Platform$Cmd$none,
								$PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$None);
					}
				}();
				var model_ = _v2.a;
				var cmd = _v2.b;
				var extMsg = _v2.c;
				return _Utils_Tuple3(
					$PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$updateDateRangeOffset(model_),
					cmd,
					extMsg);
			case 3:
				var date = msg.a;
				var updateModel = function (start) {
					var _v13 = A2($PanagiotisGeorgiadis$elm_datetime$DateTime$compareDates, start, date);
					if (_v13 === 1) {
						return _Utils_update(
							model,
							{
								d: $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$StartDateSelected(start)
							});
					} else {
						return _Utils_update(
							model,
							{
								d: $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$BothSelected(
									A2($PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$Visually, start, date))
							});
					}
				};
				var updatedModel = function () {
					var _v11 = model.d;
					_v11$2:
					while (true) {
						switch (_v11.$) {
							case 1:
								var start = _v11.a;
								return updateModel(start);
							case 2:
								if (!_v11.a.$) {
									var _v12 = _v11.a;
									var start = _v12.a;
									return updateModel(start);
								} else {
									break _v11$2;
								}
							default:
								break _v11$2;
						}
					}
					return model;
				}();
				return _Utils_Tuple3(updatedModel, $elm$core$Platform$Cmd$none, $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$None);
			case 4:
				var _v14 = model.d;
				if ((_v14.$ === 2) && (!_v14.a.$)) {
					var _v15 = _v14.a;
					var start = _v15.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								d: $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$StartDateSelected(start)
							}),
						$elm$core$Platform$Cmd$none,
						$PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$None);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$None);
				}
			case 5:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{ed: 2}),
					$elm$core$Platform$Cmd$none,
					$PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$None);
			case 6:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{ed: 1}),
					$elm$core$Platform$Cmd$none,
					$PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$None);
			case 7:
				var _v16 = model.u;
				if (_v16.$ === 1) {
					var pickers = _v16.a;
					var startPicker = pickers.M;
					var mirrorTimes = pickers.aH;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								u: $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$TimePickers(
									_Utils_update(
										pickers,
										{aH: !mirrorTimes}))
							}),
						$PanagiotisGeorgiadis$elm_datepicker$Utils$Actions$fireAction(
							$PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$SyncTimePickers(
								$PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$getTime(startPicker))),
						$PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$None);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$None);
				}
			case 8:
				var time = msg.a;
				var _v17 = model.u;
				if (_v17.$ === 1) {
					var pickers = _v17.a;
					var startPicker = pickers.M;
					var endPicker = pickers.P;
					var mirrorTimes = pickers.aH;
					if (mirrorTimes) {
						var updateFn = $PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$updateDisplayTime(time);
						var _v18 = function () {
							var _v19 = model.d;
							if ((_v19.$ === 2) && (_v19.a.$ === 1)) {
								var _v20 = _v19.a;
								var start = _v20.a;
								var end = _v20.b;
								var _v21 = _Utils_Tuple2(
									A2($PanagiotisGeorgiadis$elm_datetime$DateTime$setTime, time, start),
									A2($PanagiotisGeorgiadis$elm_datetime$DateTime$setTime, time, end));
								var updatedStartDate = _v21.a;
								var updatedEndDate = _v21.b;
								return _Utils_Tuple2(
									$PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$BothSelected(
										A2($PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$Chosen, updatedStartDate, updatedEndDate)),
									$PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$DateRangeSelected(
										$elm$core$Maybe$Just(
											{cL: updatedEndDate, dh: updatedStartDate})));
							} else {
								return _Utils_Tuple2(model.d, $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$None);
							}
						}();
						var range = _v18.a;
						var extMsg = _v18.b;
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									d: range,
									u: $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$TimePickers(
										_Utils_update(
											pickers,
											{
												P: updateFn(endPicker),
												M: updateFn(startPicker)
											}))
								}),
							$elm$core$Platform$Cmd$none,
							extMsg);
					} else {
						return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$None);
					}
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$None);
				}
			case 9:
				var subMsg = msg.a;
				var _v22 = model.u;
				if (_v22.$ === 1) {
					var pickers = _v22.a;
					var startPicker = pickers.M;
					var _v23 = A2($PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$update, subMsg, startPicker);
					var subModel = _v23.a;
					var subCmd = _v23.b;
					var extMsg = _v23.c;
					var _v24 = function () {
						var _v25 = _Utils_Tuple2(extMsg, model.d);
						if (_v25.a.$ === 1) {
							if ((_v25.b.$ === 2) && (_v25.b.a.$ === 1)) {
								var time = _v25.a.a;
								var _v26 = _v25.b.a;
								var start = _v26.a;
								var end = _v26.b;
								var updatedStart = A2($PanagiotisGeorgiadis$elm_datetime$DateTime$setTime, time, start);
								return _Utils_Tuple3(
									$PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$BothSelected(
										A2($PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$Chosen, updatedStart, end)),
									$PanagiotisGeorgiadis$elm_datepicker$Utils$Actions$fireAction(
										$PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$SyncTimePickers(time)),
									$PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$DateRangeSelected(
										$elm$core$Maybe$Just(
											{cL: end, dh: updatedStart})));
							} else {
								var time = _v25.a.a;
								return _Utils_Tuple3(
									model.d,
									$PanagiotisGeorgiadis$elm_datepicker$Utils$Actions$fireAction(
										$PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$SyncTimePickers(time)),
									$PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$None);
							}
						} else {
							return _Utils_Tuple3(model.d, $elm$core$Platform$Cmd$none, $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$None);
						}
					}();
					var range = _v24.a;
					var cmd = _v24.b;
					var externalMsg = _v24.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								d: range,
								u: $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$TimePickers(
									_Utils_update(
										pickers,
										{M: subModel}))
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2($elm$core$Platform$Cmd$map, $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$RangeStartPickerMsg, subCmd),
									cmd
								])),
						externalMsg);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$None);
				}
			case 10:
				var subMsg = msg.a;
				var _v27 = model.u;
				if (_v27.$ === 1) {
					var pickers = _v27.a;
					var endPicker = pickers.P;
					var _v28 = A2($PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$update, subMsg, endPicker);
					var subModel = _v28.a;
					var subCmd = _v28.b;
					var extMsg = _v28.c;
					var _v29 = function () {
						var _v30 = _Utils_Tuple2(extMsg, model.d);
						if (_v30.a.$ === 1) {
							if ((_v30.b.$ === 2) && (_v30.b.a.$ === 1)) {
								var time = _v30.a.a;
								var _v31 = _v30.b.a;
								var start = _v31.a;
								var end = _v31.b;
								var updatedEnd = A2($PanagiotisGeorgiadis$elm_datetime$DateTime$setTime, time, end);
								return _Utils_Tuple3(
									$PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$BothSelected(
										A2($PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$Chosen, start, updatedEnd)),
									$PanagiotisGeorgiadis$elm_datepicker$Utils$Actions$fireAction(
										$PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$SyncTimePickers(time)),
									$PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$DateRangeSelected(
										$elm$core$Maybe$Just(
											{cL: updatedEnd, dh: start})));
							} else {
								var time = _v30.a.a;
								return _Utils_Tuple3(
									model.d,
									$PanagiotisGeorgiadis$elm_datepicker$Utils$Actions$fireAction(
										$PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$SyncTimePickers(time)),
									$PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$None);
							}
						} else {
							return _Utils_Tuple3(model.d, $elm$core$Platform$Cmd$none, $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$None);
						}
					}();
					var range = _v29.a;
					var cmd = _v29.b;
					var externalMsg = _v29.c;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								d: range,
								u: $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$TimePickers(
									_Utils_update(
										pickers,
										{P: subModel}))
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2($elm$core$Platform$Cmd$map, $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$RangeEndPickerMsg, subCmd),
									cmd
								])),
						externalMsg);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$None);
				}
			default:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							d0: A2(
								$PanagiotisGeorgiadis$elm_datetime$DateTime$setDate,
								$PanagiotisGeorgiadis$elm_datetime$DateTime$getDate(model.d8),
								model.d0)
						}),
					$elm$core$Platform$Cmd$none,
					$PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$None);
		}
	});
var $author$project$Component$DateRange$updateWhenReady = F2(
	function (msg, model) {
		if (!msg.$) {
			var subMsg = msg.a;
			var _v1 = A2($PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$update, subMsg, model.aL);
			var updated = _v1.a;
			var subCmd = _v1.b;
			var extMsg = _v1.c;
			var selectedRange = function () {
				if (!extMsg.$) {
					return model.ap;
				} else {
					var range = extMsg.a;
					return range;
				}
			}();
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{aL: updated, ap: selectedRange}),
				A2($elm$core$Platform$Cmd$map, $author$project$Component$DateRange$PickerMsg, subCmd));
		} else {
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Component$DateRange$update = F2(
	function (msg, model) {
		if (!model.$) {
			var m = model.a;
			return A2(
				$elm$core$Tuple$mapFirst,
				$elm$core$Maybe$Just,
				A2($author$project$Component$DateRange$updateWhenReady, msg, m));
		} else {
			if (msg.$ === 1) {
				var now = msg.a;
				return _Utils_Tuple2(
					$elm$core$Maybe$Just(
						$author$project$Component$DateRange$initialModel(now)),
					$elm$core$Platform$Cmd$none);
			} else {
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			}
		}
	});
var $author$project$ROI$Update$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cd: str}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cn: str}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{b6: str}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cq: str}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cp: str}),
					$elm$core$Platform$Cmd$none);
			case 5:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cg: str}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cv: str}),
					$elm$core$Platform$Cmd$none);
			default:
				var subMsg = msg.a;
				return A2(
					$elm$core$Tuple$mapSecond,
					$elm$core$Platform$Cmd$map($author$project$ROI$Update$DateRangeMsg),
					A2(
						$elm$core$Tuple$mapFirst,
						function (m) {
							return _Utils_update(
								model,
								{a5: m});
						},
						A2($author$project$Component$DateRange$update, subMsg, model.a5)));
		}
	});
var $author$project$Main$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var subMsg = msg.a;
				return A2(
					$elm$core$Tuple$mapSecond,
					$elm$core$Platform$Cmd$map($author$project$Main$ROIMsg),
					A2(
						$elm$core$Tuple$mapFirst,
						function (r) {
							return _Utils_update(
								model,
								{aO: r});
						},
						A2($author$project$ROI$Update$update, subMsg, model.aO)));
			case 1:
				var now = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							c$: $elm$core$Maybe$Just(now)
						}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var zone = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							cu: $elm$core$Maybe$Just(zone)
						}),
					$elm$core$Platform$Cmd$none);
			default:
				var language = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aj: language}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $rtfeldman$elm_css$Css$Preprocess$AppendProperty = function (a) {
	return {$: 0, a: a};
};
var $rtfeldman$elm_css$Css$Structure$Property = $elm$core$Basics$identity;
var $rtfeldman$elm_css$Css$property = F2(
	function (key, value) {
		return $rtfeldman$elm_css$Css$Preprocess$AppendProperty(key + (':' + value));
	});
var $rtfeldman$elm_css$Css$prop1 = F2(
	function (key, arg) {
		return A2($rtfeldman$elm_css$Css$property, key, arg.D);
	});
var $rtfeldman$elm_css$Css$backgroundImage = $rtfeldman$elm_css$Css$prop1('background-image');
var $rtfeldman$elm_css$Css$backgroundSize = $rtfeldman$elm_css$Css$prop1('background-size');
var $rtfeldman$elm_css$Css$center = $rtfeldman$elm_css$Css$prop1('center');
var $rtfeldman$elm_css$Css$Structure$Compatible = 0;
var $rtfeldman$elm_css$Css$cover = {I: 0, D: 'cover'};
var $rtfeldman$elm_css$VirtualDom$Styled$Attribute = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $elm$core$List$all = F2(
	function (isOkay, list) {
		return !A2(
			$elm$core$List$any,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, isOkay),
			list);
	});
var $elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var $rtfeldman$elm_css$Css$Structure$compactHelp = F2(
	function (declaration, _v0) {
		var keyframesByName = _v0.a;
		var declarations = _v0.b;
		switch (declaration.$) {
			case 0:
				var _v2 = declaration.a;
				var properties = _v2.c;
				return $elm$core$List$isEmpty(properties) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 1:
				var styleBlocks = declaration.b;
				return A2(
					$elm$core$List$all,
					function (_v3) {
						var properties = _v3.c;
						return $elm$core$List$isEmpty(properties);
					},
					styleBlocks) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 2:
				var otherDeclarations = declaration.b;
				return $elm$core$List$isEmpty(otherDeclarations) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 3:
				return _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 4:
				var properties = declaration.a;
				return $elm$core$List$isEmpty(properties) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 5:
				var properties = declaration.a;
				return $elm$core$List$isEmpty(properties) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 6:
				var record = declaration.a;
				return $elm$core$String$isEmpty(record.dB) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					A3($elm$core$Dict$insert, record.dW, record.dB, keyframesByName),
					declarations);
			case 7:
				var properties = declaration.a;
				return $elm$core$List$isEmpty(properties) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 8:
				var properties = declaration.a;
				return $elm$core$List$isEmpty(properties) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			default:
				var tuples = declaration.a;
				return A2(
					$elm$core$List$all,
					function (_v4) {
						var properties = _v4.b;
						return $elm$core$List$isEmpty(properties);
					},
					tuples) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
		}
	});
var $rtfeldman$elm_css$Css$Structure$Keyframes = function (a) {
	return {$: 6, a: a};
};
var $rtfeldman$elm_css$Css$Structure$withKeyframeDeclarations = F2(
	function (keyframesByName, compactedDeclarations) {
		return A2(
			$elm$core$List$append,
			A2(
				$elm$core$List$map,
				function (_v0) {
					var name = _v0.a;
					var decl = _v0.b;
					return $rtfeldman$elm_css$Css$Structure$Keyframes(
						{dB: decl, dW: name});
				},
				$elm$core$Dict$toList(keyframesByName)),
			compactedDeclarations);
	});
var $rtfeldman$elm_css$Css$Structure$compactDeclarations = function (declarations) {
	var _v0 = A3(
		$elm$core$List$foldr,
		$rtfeldman$elm_css$Css$Structure$compactHelp,
		_Utils_Tuple2($elm$core$Dict$empty, _List_Nil),
		declarations);
	var keyframesByName = _v0.a;
	var compactedDeclarations = _v0.b;
	return A2($rtfeldman$elm_css$Css$Structure$withKeyframeDeclarations, keyframesByName, compactedDeclarations);
};
var $rtfeldman$elm_css$Css$Structure$compactStylesheet = function (_v0) {
	var charset = _v0.cI;
	var imports = _v0.cS;
	var namespaces = _v0.cZ;
	var declarations = _v0.dC;
	return {
		cI: charset,
		dC: $rtfeldman$elm_css$Css$Structure$compactDeclarations(declarations),
		cS: imports,
		cZ: namespaces
	};
};
var $rtfeldman$elm_css$Css$Structure$Output$charsetToString = function (charset) {
	return A2(
		$elm$core$Maybe$withDefault,
		'',
		A2(
			$elm$core$Maybe$map,
			function (str) {
				return '@charset \"' + (str + '\"');
			},
			charset));
};
var $rtfeldman$elm_css$Css$String$mapJoinHelp = F4(
	function (map, sep, strs, result) {
		mapJoinHelp:
		while (true) {
			if (!strs.b) {
				return result;
			} else {
				if (!strs.b.b) {
					var first = strs.a;
					return result + (map(first) + '');
				} else {
					var first = strs.a;
					var rest = strs.b;
					var $temp$map = map,
						$temp$sep = sep,
						$temp$strs = rest,
						$temp$result = result + (map(first) + (sep + ''));
					map = $temp$map;
					sep = $temp$sep;
					strs = $temp$strs;
					result = $temp$result;
					continue mapJoinHelp;
				}
			}
		}
	});
var $rtfeldman$elm_css$Css$String$mapJoin = F3(
	function (map, sep, strs) {
		return A4($rtfeldman$elm_css$Css$String$mapJoinHelp, map, sep, strs, '');
	});
var $rtfeldman$elm_css$Css$Structure$Output$mediaExpressionToString = function (expression) {
	return '(' + (expression.cN + (A2(
		$elm$core$Maybe$withDefault,
		'',
		A2(
			$elm$core$Maybe$map,
			$elm$core$Basics$append(': '),
			expression.D)) + ')'));
};
var $rtfeldman$elm_css$Css$Structure$Output$mediaTypeToString = function (mediaType) {
	switch (mediaType) {
		case 0:
			return 'print';
		case 1:
			return 'screen';
		default:
			return 'speech';
	}
};
var $rtfeldman$elm_css$Css$Structure$Output$mediaQueryToString = function (mediaQuery) {
	var prefixWith = F3(
		function (str, mediaType, expressions) {
			return str + (' ' + A2(
				$elm$core$String$join,
				' and ',
				A2(
					$elm$core$List$cons,
					$rtfeldman$elm_css$Css$Structure$Output$mediaTypeToString(mediaType),
					A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$mediaExpressionToString, expressions))));
		});
	switch (mediaQuery.$) {
		case 0:
			var expressions = mediaQuery.a;
			return A3($rtfeldman$elm_css$Css$String$mapJoin, $rtfeldman$elm_css$Css$Structure$Output$mediaExpressionToString, ' and ', expressions);
		case 1:
			var mediaType = mediaQuery.a;
			var expressions = mediaQuery.b;
			return A3(prefixWith, 'only', mediaType, expressions);
		case 2:
			var mediaType = mediaQuery.a;
			var expressions = mediaQuery.b;
			return A3(prefixWith, 'not', mediaType, expressions);
		default:
			var str = mediaQuery.a;
			return str;
	}
};
var $rtfeldman$elm_css$Css$Structure$Output$importMediaQueryToString = F2(
	function (name, mediaQuery) {
		return '@import \"' + (name + ($rtfeldman$elm_css$Css$Structure$Output$mediaQueryToString(mediaQuery) + '\"'));
	});
var $rtfeldman$elm_css$Css$Structure$Output$importToString = function (_v0) {
	var name = _v0.a;
	var mediaQueries = _v0.b;
	return A3(
		$rtfeldman$elm_css$Css$String$mapJoin,
		$rtfeldman$elm_css$Css$Structure$Output$importMediaQueryToString(name),
		'\n',
		mediaQueries);
};
var $rtfeldman$elm_css$Css$Structure$Output$namespaceToString = function (_v0) {
	var prefix = _v0.a;
	var str = _v0.b;
	return '@namespace ' + (prefix + ('\"' + (str + '\"')));
};
var $rtfeldman$elm_css$Css$Structure$Output$emitProperties = function (properties) {
	return A3(
		$rtfeldman$elm_css$Css$String$mapJoin,
		function (_v0) {
			var prop = _v0;
			return prop + ';';
		},
		'',
		properties);
};
var $elm$core$String$append = _String_append;
var $rtfeldman$elm_css$Css$Structure$Output$pseudoElementToString = function (_v0) {
	var str = _v0;
	return '::' + str;
};
var $rtfeldman$elm_css$Css$Structure$Output$combinatorToString = function (combinator) {
	switch (combinator) {
		case 0:
			return '+';
		case 1:
			return '~';
		case 2:
			return '>';
		default:
			return '';
	}
};
var $rtfeldman$elm_css$Css$Structure$Output$repeatableSimpleSelectorToString = function (repeatableSimpleSelector) {
	switch (repeatableSimpleSelector.$) {
		case 0:
			var str = repeatableSimpleSelector.a;
			return '.' + str;
		case 1:
			var str = repeatableSimpleSelector.a;
			return '#' + str;
		case 2:
			var str = repeatableSimpleSelector.a;
			return ':' + str;
		default:
			var str = repeatableSimpleSelector.a;
			return '[' + (str + ']');
	}
};
var $rtfeldman$elm_css$Css$Structure$Output$simpleSelectorSequenceToString = function (simpleSelectorSequence) {
	switch (simpleSelectorSequence.$) {
		case 0:
			var str = simpleSelectorSequence.a;
			var repeatableSimpleSelectors = simpleSelectorSequence.b;
			return _Utils_ap(
				str,
				A3($rtfeldman$elm_css$Css$String$mapJoin, $rtfeldman$elm_css$Css$Structure$Output$repeatableSimpleSelectorToString, '', repeatableSimpleSelectors));
		case 1:
			var repeatableSimpleSelectors = simpleSelectorSequence.a;
			return $elm$core$List$isEmpty(repeatableSimpleSelectors) ? '*' : A3($rtfeldman$elm_css$Css$String$mapJoin, $rtfeldman$elm_css$Css$Structure$Output$repeatableSimpleSelectorToString, '', repeatableSimpleSelectors);
		default:
			var str = simpleSelectorSequence.a;
			var repeatableSimpleSelectors = simpleSelectorSequence.b;
			return _Utils_ap(
				str,
				A3($rtfeldman$elm_css$Css$String$mapJoin, $rtfeldman$elm_css$Css$Structure$Output$repeatableSimpleSelectorToString, '', repeatableSimpleSelectors));
	}
};
var $rtfeldman$elm_css$Css$Structure$Output$selectorChainToString = function (_v0) {
	var combinator = _v0.a;
	var sequence = _v0.b;
	return $rtfeldman$elm_css$Css$Structure$Output$combinatorToString(combinator) + (' ' + $rtfeldman$elm_css$Css$Structure$Output$simpleSelectorSequenceToString(sequence));
};
var $rtfeldman$elm_css$Css$Structure$Output$selectorToString = function (_v0) {
	var simpleSelectorSequence = _v0.a;
	var chain = _v0.b;
	var pseudoElement = _v0.c;
	var segments = A2(
		$elm$core$List$cons,
		$rtfeldman$elm_css$Css$Structure$Output$simpleSelectorSequenceToString(simpleSelectorSequence),
		A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$selectorChainToString, chain));
	var pseudoElementsString = A2(
		$elm$core$Maybe$withDefault,
		'',
		A2($elm$core$Maybe$map, $rtfeldman$elm_css$Css$Structure$Output$pseudoElementToString, pseudoElement));
	return A2(
		$elm$core$String$append,
		A2($elm$core$String$join, ' ', segments),
		pseudoElementsString);
};
var $rtfeldman$elm_css$Css$Structure$Output$prettyPrintStyleBlock = function (_v0) {
	var firstSelector = _v0.a;
	var otherSelectors = _v0.b;
	var properties = _v0.c;
	var selectorStr = A3(
		$rtfeldman$elm_css$Css$String$mapJoin,
		$rtfeldman$elm_css$Css$Structure$Output$selectorToString,
		',',
		A2($elm$core$List$cons, firstSelector, otherSelectors));
	return selectorStr + ('{' + ($rtfeldman$elm_css$Css$Structure$Output$emitProperties(properties) + '}'));
};
var $rtfeldman$elm_css$Css$Structure$Output$prettyPrintDeclaration = function (decl) {
	switch (decl.$) {
		case 0:
			var styleBlock = decl.a;
			return $rtfeldman$elm_css$Css$Structure$Output$prettyPrintStyleBlock(styleBlock);
		case 1:
			var mediaQueries = decl.a;
			var styleBlocks = decl.b;
			var query = A3($rtfeldman$elm_css$Css$String$mapJoin, $rtfeldman$elm_css$Css$Structure$Output$mediaQueryToString, ', ', mediaQueries);
			var blocks = A3($rtfeldman$elm_css$Css$String$mapJoin, $rtfeldman$elm_css$Css$Structure$Output$prettyPrintStyleBlock, '\n', styleBlocks);
			return '@media ' + (query + ('{' + (blocks + '}')));
		case 2:
			return 'TODO';
		case 3:
			return 'TODO';
		case 4:
			return 'TODO';
		case 5:
			return 'TODO';
		case 6:
			var name = decl.a.dW;
			var declaration = decl.a.dB;
			return '@keyframes ' + (name + ('{' + (declaration + '}')));
		case 7:
			return 'TODO';
		case 8:
			return 'TODO';
		default:
			return 'TODO';
	}
};
var $rtfeldman$elm_css$Css$Structure$Output$prettyPrint = function (_v0) {
	var charset = _v0.cI;
	var imports = _v0.cS;
	var namespaces = _v0.cZ;
	var declarations = _v0.dC;
	return $rtfeldman$elm_css$Css$Structure$Output$charsetToString(charset) + (A3($rtfeldman$elm_css$Css$String$mapJoin, $rtfeldman$elm_css$Css$Structure$Output$importToString, '\n', imports) + (A3($rtfeldman$elm_css$Css$String$mapJoin, $rtfeldman$elm_css$Css$Structure$Output$namespaceToString, '\n', namespaces) + (A3($rtfeldman$elm_css$Css$String$mapJoin, $rtfeldman$elm_css$Css$Structure$Output$prettyPrintDeclaration, '\n', declarations) + '')));
};
var $rtfeldman$elm_css$Css$Structure$CounterStyle = function (a) {
	return {$: 8, a: a};
};
var $rtfeldman$elm_css$Css$Structure$FontFace = function (a) {
	return {$: 5, a: a};
};
var $rtfeldman$elm_css$Css$Structure$PageRule = function (a) {
	return {$: 4, a: a};
};
var $rtfeldman$elm_css$Css$Structure$Selector = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $rtfeldman$elm_css$Css$Structure$StyleBlock = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration = function (a) {
	return {$: 0, a: a};
};
var $rtfeldman$elm_css$Css$Structure$SupportsRule = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$Structure$Viewport = function (a) {
	return {$: 7, a: a};
};
var $rtfeldman$elm_css$Css$Structure$MediaRule = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$Structure$mapLast = F2(
	function (update, list) {
		if (!list.b) {
			return list;
		} else {
			if (!list.b.b) {
				var only = list.a;
				return _List_fromArray(
					[
						update(only)
					]);
			} else {
				var first = list.a;
				var rest = list.b;
				return A2(
					$elm$core$List$cons,
					first,
					A2($rtfeldman$elm_css$Css$Structure$mapLast, update, rest));
			}
		}
	});
var $rtfeldman$elm_css$Css$Structure$withPropertyAppended = F2(
	function (property, _v0) {
		var firstSelector = _v0.a;
		var otherSelectors = _v0.b;
		var properties = _v0.c;
		return A3(
			$rtfeldman$elm_css$Css$Structure$StyleBlock,
			firstSelector,
			otherSelectors,
			_Utils_ap(
				properties,
				_List_fromArray(
					[property])));
	});
var $rtfeldman$elm_css$Css$Structure$appendProperty = F2(
	function (property, declarations) {
		if (!declarations.b) {
			return declarations;
		} else {
			if (!declarations.b.b) {
				switch (declarations.a.$) {
					case 0:
						var styleBlock = declarations.a.a;
						return _List_fromArray(
							[
								$rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration(
								A2($rtfeldman$elm_css$Css$Structure$withPropertyAppended, property, styleBlock))
							]);
					case 1:
						var _v1 = declarations.a;
						var mediaQueries = _v1.a;
						var styleBlocks = _v1.b;
						return _List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Css$Structure$MediaRule,
								mediaQueries,
								A2(
									$rtfeldman$elm_css$Css$Structure$mapLast,
									$rtfeldman$elm_css$Css$Structure$withPropertyAppended(property),
									styleBlocks))
							]);
					default:
						return declarations;
				}
			} else {
				var first = declarations.a;
				var rest = declarations.b;
				return A2(
					$elm$core$List$cons,
					first,
					A2($rtfeldman$elm_css$Css$Structure$appendProperty, property, rest));
			}
		}
	});
var $rtfeldman$elm_css$Css$Structure$appendToLastSelector = F2(
	function (f, styleBlock) {
		if (!styleBlock.b.b) {
			var only = styleBlock.a;
			var properties = styleBlock.c;
			return _List_fromArray(
				[
					A3($rtfeldman$elm_css$Css$Structure$StyleBlock, only, _List_Nil, properties),
					A3(
					$rtfeldman$elm_css$Css$Structure$StyleBlock,
					f(only),
					_List_Nil,
					_List_Nil)
				]);
		} else {
			var first = styleBlock.a;
			var rest = styleBlock.b;
			var properties = styleBlock.c;
			var newRest = A2($elm$core$List$map, f, rest);
			var newFirst = f(first);
			return _List_fromArray(
				[
					A3($rtfeldman$elm_css$Css$Structure$StyleBlock, first, rest, properties),
					A3($rtfeldman$elm_css$Css$Structure$StyleBlock, newFirst, newRest, _List_Nil)
				]);
		}
	});
var $rtfeldman$elm_css$Css$Structure$applyPseudoElement = F2(
	function (pseudo, _v0) {
		var sequence = _v0.a;
		var selectors = _v0.b;
		return A3(
			$rtfeldman$elm_css$Css$Structure$Selector,
			sequence,
			selectors,
			$elm$core$Maybe$Just(pseudo));
	});
var $rtfeldman$elm_css$Css$Structure$appendPseudoElementToLastSelector = F2(
	function (pseudo, styleBlock) {
		return A2(
			$rtfeldman$elm_css$Css$Structure$appendToLastSelector,
			$rtfeldman$elm_css$Css$Structure$applyPseudoElement(pseudo),
			styleBlock);
	});
var $rtfeldman$elm_css$Css$Structure$CustomSelector = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$Structure$TypeSelectorSequence = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$Structure$UniversalSelectorSequence = function (a) {
	return {$: 1, a: a};
};
var $rtfeldman$elm_css$Css$Structure$appendRepeatable = F2(
	function (selector, sequence) {
		switch (sequence.$) {
			case 0:
				var typeSelector = sequence.a;
				var list = sequence.b;
				return A2(
					$rtfeldman$elm_css$Css$Structure$TypeSelectorSequence,
					typeSelector,
					_Utils_ap(
						list,
						_List_fromArray(
							[selector])));
			case 1:
				var list = sequence.a;
				return $rtfeldman$elm_css$Css$Structure$UniversalSelectorSequence(
					_Utils_ap(
						list,
						_List_fromArray(
							[selector])));
			default:
				var str = sequence.a;
				var list = sequence.b;
				return A2(
					$rtfeldman$elm_css$Css$Structure$CustomSelector,
					str,
					_Utils_ap(
						list,
						_List_fromArray(
							[selector])));
		}
	});
var $rtfeldman$elm_css$Css$Structure$appendRepeatableWithCombinator = F2(
	function (selector, list) {
		if (!list.b) {
			return _List_Nil;
		} else {
			if (!list.b.b) {
				var _v1 = list.a;
				var combinator = _v1.a;
				var sequence = _v1.b;
				return _List_fromArray(
					[
						_Utils_Tuple2(
						combinator,
						A2($rtfeldman$elm_css$Css$Structure$appendRepeatable, selector, sequence))
					]);
			} else {
				var first = list.a;
				var rest = list.b;
				return A2(
					$elm$core$List$cons,
					first,
					A2($rtfeldman$elm_css$Css$Structure$appendRepeatableWithCombinator, selector, rest));
			}
		}
	});
var $rtfeldman$elm_css$Css$Structure$appendRepeatableSelector = F2(
	function (repeatableSimpleSelector, selector) {
		if (!selector.b.b) {
			var sequence = selector.a;
			var pseudoElement = selector.c;
			return A3(
				$rtfeldman$elm_css$Css$Structure$Selector,
				A2($rtfeldman$elm_css$Css$Structure$appendRepeatable, repeatableSimpleSelector, sequence),
				_List_Nil,
				pseudoElement);
		} else {
			var firstSelector = selector.a;
			var tuples = selector.b;
			var pseudoElement = selector.c;
			return A3(
				$rtfeldman$elm_css$Css$Structure$Selector,
				firstSelector,
				A2($rtfeldman$elm_css$Css$Structure$appendRepeatableWithCombinator, repeatableSimpleSelector, tuples),
				pseudoElement);
		}
	});
var $rtfeldman$elm_css$Css$Structure$appendRepeatableToLastSelector = F2(
	function (selector, styleBlock) {
		return A2(
			$rtfeldman$elm_css$Css$Structure$appendToLastSelector,
			$rtfeldman$elm_css$Css$Structure$appendRepeatableSelector(selector),
			styleBlock);
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$collectSelectors = function (declarations) {
	collectSelectors:
	while (true) {
		if (!declarations.b) {
			return _List_Nil;
		} else {
			if (!declarations.a.$) {
				var _v1 = declarations.a.a;
				var firstSelector = _v1.a;
				var otherSelectors = _v1.b;
				var rest = declarations.b;
				return _Utils_ap(
					A2($elm$core$List$cons, firstSelector, otherSelectors),
					$rtfeldman$elm_css$Css$Preprocess$Resolve$collectSelectors(rest));
			} else {
				var rest = declarations.b;
				var $temp$declarations = rest;
				declarations = $temp$declarations;
				continue collectSelectors;
			}
		}
	}
};
var $rtfeldman$elm_css$Css$Structure$DocumentRule = F5(
	function (a, b, c, d, e) {
		return {$: 3, a: a, b: b, c: c, d: d, e: e};
	});
var $rtfeldman$elm_css$Css$Structure$concatMapLastStyleBlock = F2(
	function (update, declarations) {
		_v0$12:
		while (true) {
			if (!declarations.b) {
				return declarations;
			} else {
				if (!declarations.b.b) {
					switch (declarations.a.$) {
						case 0:
							var styleBlock = declarations.a.a;
							return A2(
								$elm$core$List$map,
								$rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration,
								update(styleBlock));
						case 1:
							if (declarations.a.b.b) {
								if (!declarations.a.b.b.b) {
									var _v1 = declarations.a;
									var mediaQueries = _v1.a;
									var _v2 = _v1.b;
									var styleBlock = _v2.a;
									return _List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Css$Structure$MediaRule,
											mediaQueries,
											update(styleBlock))
										]);
								} else {
									var _v3 = declarations.a;
									var mediaQueries = _v3.a;
									var _v4 = _v3.b;
									var first = _v4.a;
									var rest = _v4.b;
									var _v5 = A2(
										$rtfeldman$elm_css$Css$Structure$concatMapLastStyleBlock,
										update,
										_List_fromArray(
											[
												A2($rtfeldman$elm_css$Css$Structure$MediaRule, mediaQueries, rest)
											]));
									if ((_v5.b && (_v5.a.$ === 1)) && (!_v5.b.b)) {
										var _v6 = _v5.a;
										var newMediaQueries = _v6.a;
										var newStyleBlocks = _v6.b;
										return _List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Css$Structure$MediaRule,
												newMediaQueries,
												A2($elm$core$List$cons, first, newStyleBlocks))
											]);
									} else {
										var newDeclarations = _v5;
										return newDeclarations;
									}
								}
							} else {
								break _v0$12;
							}
						case 2:
							var _v7 = declarations.a;
							var str = _v7.a;
							var nestedDeclarations = _v7.b;
							return _List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Css$Structure$SupportsRule,
									str,
									A2($rtfeldman$elm_css$Css$Structure$concatMapLastStyleBlock, update, nestedDeclarations))
								]);
						case 3:
							var _v8 = declarations.a;
							var str1 = _v8.a;
							var str2 = _v8.b;
							var str3 = _v8.c;
							var str4 = _v8.d;
							var styleBlock = _v8.e;
							return A2(
								$elm$core$List$map,
								A4($rtfeldman$elm_css$Css$Structure$DocumentRule, str1, str2, str3, str4),
								update(styleBlock));
						case 4:
							return declarations;
						case 5:
							return declarations;
						case 6:
							return declarations;
						case 7:
							return declarations;
						case 8:
							return declarations;
						default:
							return declarations;
					}
				} else {
					break _v0$12;
				}
			}
		}
		var first = declarations.a;
		var rest = declarations.b;
		return A2(
			$elm$core$List$cons,
			first,
			A2($rtfeldman$elm_css$Css$Structure$concatMapLastStyleBlock, update, rest));
	});
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$last = function (list) {
	last:
	while (true) {
		if (!list.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			if (!list.b.b) {
				var singleton = list.a;
				return $elm$core$Maybe$Just(singleton);
			} else {
				var rest = list.b;
				var $temp$list = rest;
				list = $temp$list;
				continue last;
			}
		}
	}
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$lastDeclaration = function (declarations) {
	lastDeclaration:
	while (true) {
		if (!declarations.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			if (!declarations.b.b) {
				var x = declarations.a;
				return $elm$core$Maybe$Just(
					_List_fromArray(
						[x]));
			} else {
				var xs = declarations.b;
				var $temp$declarations = xs;
				declarations = $temp$declarations;
				continue lastDeclaration;
			}
		}
	}
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$oneOf = function (maybes) {
	oneOf:
	while (true) {
		if (!maybes.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			var maybe = maybes.a;
			var rest = maybes.b;
			if (maybe.$ === 1) {
				var $temp$maybes = rest;
				maybes = $temp$maybes;
				continue oneOf;
			} else {
				return maybe;
			}
		}
	}
};
var $rtfeldman$elm_css$Css$Structure$FontFeatureValues = function (a) {
	return {$: 9, a: a};
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$resolveFontFeatureValues = function (tuples) {
	var expandTuples = function (tuplesToExpand) {
		if (!tuplesToExpand.b) {
			return _List_Nil;
		} else {
			var properties = tuplesToExpand.a;
			var rest = tuplesToExpand.b;
			return A2(
				$elm$core$List$cons,
				properties,
				expandTuples(rest));
		}
	};
	var newTuples = expandTuples(tuples);
	return _List_fromArray(
		[
			$rtfeldman$elm_css$Css$Structure$FontFeatureValues(newTuples)
		]);
};
var $rtfeldman$elm_css$Css$Structure$styleBlockToMediaRule = F2(
	function (mediaQueries, declaration) {
		if (!declaration.$) {
			var styleBlock = declaration.a;
			return A2(
				$rtfeldman$elm_css$Css$Structure$MediaRule,
				mediaQueries,
				_List_fromArray(
					[styleBlock]));
		} else {
			return declaration;
		}
	});
var $elm$core$List$tail = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(xs);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$List$takeReverse = F3(
	function (n, list, kept) {
		takeReverse:
		while (true) {
			if (n <= 0) {
				return kept;
			} else {
				if (!list.b) {
					return kept;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs,
						$temp$kept = A2($elm$core$List$cons, x, kept);
					n = $temp$n;
					list = $temp$list;
					kept = $temp$kept;
					continue takeReverse;
				}
			}
		}
	});
var $elm$core$List$takeTailRec = F2(
	function (n, list) {
		return $elm$core$List$reverse(
			A3($elm$core$List$takeReverse, n, list, _List_Nil));
	});
var $elm$core$List$takeFast = F3(
	function (ctr, n, list) {
		if (n <= 0) {
			return _List_Nil;
		} else {
			var _v0 = _Utils_Tuple2(n, list);
			_v0$1:
			while (true) {
				_v0$5:
				while (true) {
					if (!_v0.b.b) {
						return list;
					} else {
						if (_v0.b.b.b) {
							switch (_v0.a) {
								case 1:
									break _v0$1;
								case 2:
									var _v2 = _v0.b;
									var x = _v2.a;
									var _v3 = _v2.b;
									var y = _v3.a;
									return _List_fromArray(
										[x, y]);
								case 3:
									if (_v0.b.b.b.b) {
										var _v4 = _v0.b;
										var x = _v4.a;
										var _v5 = _v4.b;
										var y = _v5.a;
										var _v6 = _v5.b;
										var z = _v6.a;
										return _List_fromArray(
											[x, y, z]);
									} else {
										break _v0$5;
									}
								default:
									if (_v0.b.b.b.b && _v0.b.b.b.b.b) {
										var _v7 = _v0.b;
										var x = _v7.a;
										var _v8 = _v7.b;
										var y = _v8.a;
										var _v9 = _v8.b;
										var z = _v9.a;
										var _v10 = _v9.b;
										var w = _v10.a;
										var tl = _v10.b;
										return (ctr > 1000) ? A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A2($elm$core$List$takeTailRec, n - 4, tl))))) : A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A3($elm$core$List$takeFast, ctr + 1, n - 4, tl)))));
									} else {
										break _v0$5;
									}
							}
						} else {
							if (_v0.a === 1) {
								break _v0$1;
							} else {
								break _v0$5;
							}
						}
					}
				}
				return list;
			}
			var _v1 = _v0.b;
			var x = _v1.a;
			return _List_fromArray(
				[x]);
		}
	});
var $elm$core$List$take = F2(
	function (n, list) {
		return A3($elm$core$List$takeFast, 0, n, list);
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$toDocumentRule = F5(
	function (str1, str2, str3, str4, declaration) {
		if (!declaration.$) {
			var structureStyleBlock = declaration.a;
			return A5($rtfeldman$elm_css$Css$Structure$DocumentRule, str1, str2, str3, str4, structureStyleBlock);
		} else {
			return declaration;
		}
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$toMediaRule = F2(
	function (mediaQueries, declaration) {
		switch (declaration.$) {
			case 0:
				var structureStyleBlock = declaration.a;
				return A2(
					$rtfeldman$elm_css$Css$Structure$MediaRule,
					mediaQueries,
					_List_fromArray(
						[structureStyleBlock]));
			case 1:
				var newMediaQueries = declaration.a;
				var structureStyleBlocks = declaration.b;
				return A2(
					$rtfeldman$elm_css$Css$Structure$MediaRule,
					_Utils_ap(mediaQueries, newMediaQueries),
					structureStyleBlocks);
			case 2:
				var str = declaration.a;
				var declarations = declaration.b;
				return A2(
					$rtfeldman$elm_css$Css$Structure$SupportsRule,
					str,
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$Css$Preprocess$Resolve$toMediaRule(mediaQueries),
						declarations));
			case 3:
				var str1 = declaration.a;
				var str2 = declaration.b;
				var str3 = declaration.c;
				var str4 = declaration.d;
				var structureStyleBlock = declaration.e;
				return A5($rtfeldman$elm_css$Css$Structure$DocumentRule, str1, str2, str3, str4, structureStyleBlock);
			case 4:
				return declaration;
			case 5:
				return declaration;
			case 6:
				return declaration;
			case 7:
				return declaration;
			case 8:
				return declaration;
			default:
				return declaration;
		}
	});
var $rtfeldman$elm_css$Css$Preprocess$unwrapSnippet = function (_v0) {
	var declarations = _v0;
	return declarations;
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$applyNestedStylesToLast = F4(
	function (nestedStyles, rest, f, declarations) {
		var withoutParent = function (decls) {
			return A2(
				$elm$core$Maybe$withDefault,
				_List_Nil,
				$elm$core$List$tail(decls));
		};
		var nextResult = A2(
			$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
			rest,
			A2(
				$elm$core$Maybe$withDefault,
				_List_Nil,
				$rtfeldman$elm_css$Css$Preprocess$Resolve$lastDeclaration(declarations)));
		var newDeclarations = function () {
			var _v14 = _Utils_Tuple2(
				$elm$core$List$head(nextResult),
				$rtfeldman$elm_css$Css$Preprocess$Resolve$last(declarations));
			if ((!_v14.a.$) && (!_v14.b.$)) {
				var nextResultParent = _v14.a.a;
				var originalParent = _v14.b.a;
				return _Utils_ap(
					A2(
						$elm$core$List$take,
						$elm$core$List$length(declarations) - 1,
						declarations),
					_List_fromArray(
						[
							(!_Utils_eq(originalParent, nextResultParent)) ? nextResultParent : originalParent
						]));
			} else {
				return declarations;
			}
		}();
		var insertStylesToNestedDecl = function (lastDecl) {
			return $elm$core$List$concat(
				A2(
					$rtfeldman$elm_css$Css$Structure$mapLast,
					$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles(nestedStyles),
					A2(
						$elm$core$List$map,
						$elm$core$List$singleton,
						A2($rtfeldman$elm_css$Css$Structure$concatMapLastStyleBlock, f, lastDecl))));
		};
		var initialResult = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				$elm$core$Maybe$map,
				insertStylesToNestedDecl,
				$rtfeldman$elm_css$Css$Preprocess$Resolve$lastDeclaration(declarations)));
		return _Utils_ap(
			newDeclarations,
			_Utils_ap(
				withoutParent(initialResult),
				withoutParent(nextResult)));
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles = F2(
	function (styles, declarations) {
		if (!styles.b) {
			return declarations;
		} else {
			switch (styles.a.$) {
				case 0:
					var property = styles.a.a;
					var rest = styles.b;
					return A2(
						$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
						rest,
						A2($rtfeldman$elm_css$Css$Structure$appendProperty, property, declarations));
				case 1:
					var _v4 = styles.a;
					var selector = _v4.a;
					var nestedStyles = _v4.b;
					var rest = styles.b;
					return A4(
						$rtfeldman$elm_css$Css$Preprocess$Resolve$applyNestedStylesToLast,
						nestedStyles,
						rest,
						$rtfeldman$elm_css$Css$Structure$appendRepeatableToLastSelector(selector),
						declarations);
				case 2:
					var _v5 = styles.a;
					var selectorCombinator = _v5.a;
					var snippets = _v5.b;
					var rest = styles.b;
					var chain = F2(
						function (_v9, _v10) {
							var originalSequence = _v9.a;
							var originalTuples = _v9.b;
							var originalPseudoElement = _v9.c;
							var newSequence = _v10.a;
							var newTuples = _v10.b;
							var newPseudoElement = _v10.c;
							return A3(
								$rtfeldman$elm_css$Css$Structure$Selector,
								originalSequence,
								_Utils_ap(
									originalTuples,
									A2(
										$elm$core$List$cons,
										_Utils_Tuple2(selectorCombinator, newSequence),
										newTuples)),
								$rtfeldman$elm_css$Css$Preprocess$Resolve$oneOf(
									_List_fromArray(
										[newPseudoElement, originalPseudoElement])));
						});
					var expandDeclaration = function (declaration) {
						switch (declaration.$) {
							case 0:
								var _v7 = declaration.a;
								var firstSelector = _v7.a;
								var otherSelectors = _v7.b;
								var nestedStyles = _v7.c;
								var newSelectors = A2(
									$elm$core$List$concatMap,
									function (originalSelector) {
										return A2(
											$elm$core$List$map,
											chain(originalSelector),
											A2($elm$core$List$cons, firstSelector, otherSelectors));
									},
									$rtfeldman$elm_css$Css$Preprocess$Resolve$collectSelectors(declarations));
								var newDeclarations = function () {
									if (!newSelectors.b) {
										return _List_Nil;
									} else {
										var first = newSelectors.a;
										var remainder = newSelectors.b;
										return _List_fromArray(
											[
												$rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration(
												A3($rtfeldman$elm_css$Css$Structure$StyleBlock, first, remainder, _List_Nil))
											]);
									}
								}();
								return A2($rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles, nestedStyles, newDeclarations);
							case 1:
								var mediaQueries = declaration.a;
								var styleBlocks = declaration.b;
								return A2($rtfeldman$elm_css$Css$Preprocess$Resolve$resolveMediaRule, mediaQueries, styleBlocks);
							case 2:
								var str = declaration.a;
								var otherSnippets = declaration.b;
								return A2($rtfeldman$elm_css$Css$Preprocess$Resolve$resolveSupportsRule, str, otherSnippets);
							case 3:
								var str1 = declaration.a;
								var str2 = declaration.b;
								var str3 = declaration.c;
								var str4 = declaration.d;
								var styleBlock = declaration.e;
								return A2(
									$elm$core$List$map,
									A4($rtfeldman$elm_css$Css$Preprocess$Resolve$toDocumentRule, str1, str2, str3, str4),
									$rtfeldman$elm_css$Css$Preprocess$Resolve$expandStyleBlock(styleBlock));
							case 4:
								var properties = declaration.a;
								return _List_fromArray(
									[
										$rtfeldman$elm_css$Css$Structure$PageRule(properties)
									]);
							case 5:
								var properties = declaration.a;
								return _List_fromArray(
									[
										$rtfeldman$elm_css$Css$Structure$FontFace(properties)
									]);
							case 6:
								var properties = declaration.a;
								return _List_fromArray(
									[
										$rtfeldman$elm_css$Css$Structure$Viewport(properties)
									]);
							case 7:
								var properties = declaration.a;
								return _List_fromArray(
									[
										$rtfeldman$elm_css$Css$Structure$CounterStyle(properties)
									]);
							default:
								var tuples = declaration.a;
								return $rtfeldman$elm_css$Css$Preprocess$Resolve$resolveFontFeatureValues(tuples);
						}
					};
					return $elm$core$List$concat(
						_Utils_ap(
							_List_fromArray(
								[
									A2($rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles, rest, declarations)
								]),
							A2(
								$elm$core$List$map,
								expandDeclaration,
								A2($elm$core$List$concatMap, $rtfeldman$elm_css$Css$Preprocess$unwrapSnippet, snippets))));
				case 3:
					var _v11 = styles.a;
					var pseudoElement = _v11.a;
					var nestedStyles = _v11.b;
					var rest = styles.b;
					return A4(
						$rtfeldman$elm_css$Css$Preprocess$Resolve$applyNestedStylesToLast,
						nestedStyles,
						rest,
						$rtfeldman$elm_css$Css$Structure$appendPseudoElementToLastSelector(pseudoElement),
						declarations);
				case 5:
					var str = styles.a.a;
					var rest = styles.b;
					var name = $rtfeldman$elm_css$Hash$fromString(str);
					var newProperty = 'animation-name:' + name;
					var newDeclarations = A2(
						$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
						rest,
						A2($rtfeldman$elm_css$Css$Structure$appendProperty, newProperty, declarations));
					return A2(
						$elm$core$List$append,
						newDeclarations,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$Structure$Keyframes(
								{dB: str, dW: name})
							]));
				case 4:
					var _v12 = styles.a;
					var mediaQueries = _v12.a;
					var nestedStyles = _v12.b;
					var rest = styles.b;
					var extraDeclarations = function () {
						var _v13 = $rtfeldman$elm_css$Css$Preprocess$Resolve$collectSelectors(declarations);
						if (!_v13.b) {
							return _List_Nil;
						} else {
							var firstSelector = _v13.a;
							var otherSelectors = _v13.b;
							return A2(
								$elm$core$List$map,
								$rtfeldman$elm_css$Css$Structure$styleBlockToMediaRule(mediaQueries),
								A2(
									$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
									nestedStyles,
									$elm$core$List$singleton(
										$rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration(
											A3($rtfeldman$elm_css$Css$Structure$StyleBlock, firstSelector, otherSelectors, _List_Nil)))));
						}
					}();
					return _Utils_ap(
						A2($rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles, rest, declarations),
						extraDeclarations);
				default:
					var otherStyles = styles.a.a;
					var rest = styles.b;
					return A2(
						$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
						_Utils_ap(otherStyles, rest),
						declarations);
			}
		}
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$expandStyleBlock = function (_v2) {
	var firstSelector = _v2.a;
	var otherSelectors = _v2.b;
	var styles = _v2.c;
	return A2(
		$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
		styles,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration(
				A3($rtfeldman$elm_css$Css$Structure$StyleBlock, firstSelector, otherSelectors, _List_Nil))
			]));
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$extract = function (snippetDeclarations) {
	if (!snippetDeclarations.b) {
		return _List_Nil;
	} else {
		var first = snippetDeclarations.a;
		var rest = snippetDeclarations.b;
		return _Utils_ap(
			$rtfeldman$elm_css$Css$Preprocess$Resolve$toDeclarations(first),
			$rtfeldman$elm_css$Css$Preprocess$Resolve$extract(rest));
	}
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$resolveMediaRule = F2(
	function (mediaQueries, styleBlocks) {
		var handleStyleBlock = function (styleBlock) {
			return A2(
				$elm$core$List$map,
				$rtfeldman$elm_css$Css$Preprocess$Resolve$toMediaRule(mediaQueries),
				$rtfeldman$elm_css$Css$Preprocess$Resolve$expandStyleBlock(styleBlock));
		};
		return A2($elm$core$List$concatMap, handleStyleBlock, styleBlocks);
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$resolveSupportsRule = F2(
	function (str, snippets) {
		var declarations = $rtfeldman$elm_css$Css$Preprocess$Resolve$extract(
			A2($elm$core$List$concatMap, $rtfeldman$elm_css$Css$Preprocess$unwrapSnippet, snippets));
		return _List_fromArray(
			[
				A2($rtfeldman$elm_css$Css$Structure$SupportsRule, str, declarations)
			]);
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$toDeclarations = function (snippetDeclaration) {
	switch (snippetDeclaration.$) {
		case 0:
			var styleBlock = snippetDeclaration.a;
			return $rtfeldman$elm_css$Css$Preprocess$Resolve$expandStyleBlock(styleBlock);
		case 1:
			var mediaQueries = snippetDeclaration.a;
			var styleBlocks = snippetDeclaration.b;
			return A2($rtfeldman$elm_css$Css$Preprocess$Resolve$resolveMediaRule, mediaQueries, styleBlocks);
		case 2:
			var str = snippetDeclaration.a;
			var snippets = snippetDeclaration.b;
			return A2($rtfeldman$elm_css$Css$Preprocess$Resolve$resolveSupportsRule, str, snippets);
		case 3:
			var str1 = snippetDeclaration.a;
			var str2 = snippetDeclaration.b;
			var str3 = snippetDeclaration.c;
			var str4 = snippetDeclaration.d;
			var styleBlock = snippetDeclaration.e;
			return A2(
				$elm$core$List$map,
				A4($rtfeldman$elm_css$Css$Preprocess$Resolve$toDocumentRule, str1, str2, str3, str4),
				$rtfeldman$elm_css$Css$Preprocess$Resolve$expandStyleBlock(styleBlock));
		case 4:
			var properties = snippetDeclaration.a;
			return _List_fromArray(
				[
					$rtfeldman$elm_css$Css$Structure$PageRule(properties)
				]);
		case 5:
			var properties = snippetDeclaration.a;
			return _List_fromArray(
				[
					$rtfeldman$elm_css$Css$Structure$FontFace(properties)
				]);
		case 6:
			var properties = snippetDeclaration.a;
			return _List_fromArray(
				[
					$rtfeldman$elm_css$Css$Structure$Viewport(properties)
				]);
		case 7:
			var properties = snippetDeclaration.a;
			return _List_fromArray(
				[
					$rtfeldman$elm_css$Css$Structure$CounterStyle(properties)
				]);
		default:
			var tuples = snippetDeclaration.a;
			return $rtfeldman$elm_css$Css$Preprocess$Resolve$resolveFontFeatureValues(tuples);
	}
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$toStructure = function (_v0) {
	var charset = _v0.cI;
	var imports = _v0.cS;
	var namespaces = _v0.cZ;
	var snippets = _v0.dg;
	var declarations = $rtfeldman$elm_css$Css$Preprocess$Resolve$extract(
		A2($elm$core$List$concatMap, $rtfeldman$elm_css$Css$Preprocess$unwrapSnippet, snippets));
	return {cI: charset, dC: declarations, cS: imports, cZ: namespaces};
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$compile = function (sheet) {
	return $rtfeldman$elm_css$Css$Structure$Output$prettyPrint(
		$rtfeldman$elm_css$Css$Structure$compactStylesheet(
			$rtfeldman$elm_css$Css$Preprocess$Resolve$toStructure(sheet)));
};
var $rtfeldman$elm_css$Css$Preprocess$Snippet = $elm$core$Basics$identity;
var $rtfeldman$elm_css$Css$Preprocess$StyleBlock = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $rtfeldman$elm_css$Css$Preprocess$StyleBlockDeclaration = function (a) {
	return {$: 0, a: a};
};
var $rtfeldman$elm_css$VirtualDom$Styled$makeSnippet = F2(
	function (styles, sequence) {
		var selector = A3($rtfeldman$elm_css$Css$Structure$Selector, sequence, _List_Nil, $elm$core$Maybe$Nothing);
		return _List_fromArray(
			[
				$rtfeldman$elm_css$Css$Preprocess$StyleBlockDeclaration(
				A3($rtfeldman$elm_css$Css$Preprocess$StyleBlock, selector, _List_Nil, styles))
			]);
	});
var $rtfeldman$elm_css$Css$Preprocess$stylesheet = function (snippets) {
	return {cI: $elm$core$Maybe$Nothing, cS: _List_Nil, cZ: _List_Nil, dg: snippets};
};
var $rtfeldman$elm_css$Css$Structure$ClassSelector = function (a) {
	return {$: 0, a: a};
};
var $rtfeldman$elm_css$VirtualDom$Styled$templateSelector = $rtfeldman$elm_css$Css$Structure$UniversalSelectorSequence(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$Structure$ClassSelector($rtfeldman$elm_css$VirtualDom$Styled$classnameStandin)
		]));
var $rtfeldman$elm_css$VirtualDom$Styled$getCssTemplate = function (styles) {
	if (!styles.b) {
		return '';
	} else {
		var otherwise = styles;
		return $rtfeldman$elm_css$Css$Preprocess$Resolve$compile(
			$rtfeldman$elm_css$Css$Preprocess$stylesheet(
				_List_fromArray(
					[
						A2($rtfeldman$elm_css$VirtualDom$Styled$makeSnippet, styles, $rtfeldman$elm_css$VirtualDom$Styled$templateSelector)
					])));
	}
};
var $rtfeldman$elm_css$Html$Styled$Internal$css = function (styles) {
	var cssTemplate = $rtfeldman$elm_css$VirtualDom$Styled$getCssTemplate(styles);
	var classProperty = A2($elm$virtual_dom$VirtualDom$attribute, '', '');
	return A3($rtfeldman$elm_css$VirtualDom$Styled$Attribute, classProperty, true, cssTemplate);
};
var $rtfeldman$elm_css$Html$Styled$Attributes$css = $rtfeldman$elm_css$Html$Styled$Internal$css;
var $rtfeldman$elm_css$Css$displayFlex = A2($rtfeldman$elm_css$Css$property, 'display', 'flex');
var $rtfeldman$elm_css$VirtualDom$Styled$Node = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $rtfeldman$elm_css$VirtualDom$Styled$node = $rtfeldman$elm_css$VirtualDom$Styled$Node;
var $rtfeldman$elm_css$Html$Styled$node = $rtfeldman$elm_css$VirtualDom$Styled$node;
var $rtfeldman$elm_css$Html$Styled$div = $rtfeldman$elm_css$Html$Styled$node('div');
var $rtfeldman$elm_css$Css$fixed = {az: 0, aM: 0, bW: 0, D: 'fixed'};
var $rtfeldman$elm_css$Css$height = $rtfeldman$elm_css$Css$prop1('height');
var $rtfeldman$elm_css$Css$UnitlessInteger = 0;
var $rtfeldman$elm_css$Css$int = function (val) {
	return {
		R: 0,
		bo: 0,
		U: 0,
		B: 0,
		aJ: 0,
		bA: 0,
		K: val,
		av: '',
		aT: 0,
		D: $elm$core$String$fromInt(val)
	};
};
var $rtfeldman$elm_css$Css$Preprocess$ApplyStyles = function (a) {
	return {$: 6, a: a};
};
var $rtfeldman$elm_css$Css$Internal$property = F2(
	function (key, value) {
		return $rtfeldman$elm_css$Css$Preprocess$AppendProperty(key + (':' + value));
	});
var $rtfeldman$elm_css$Css$Internal$getOverloadedProperty = F3(
	function (functionName, desiredKey, style) {
		getOverloadedProperty:
		while (true) {
			switch (style.$) {
				case 0:
					var str = style.a;
					var key = A2(
						$elm$core$Maybe$withDefault,
						'',
						$elm$core$List$head(
							A2($elm$core$String$split, ':', str)));
					return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, key);
				case 1:
					var selector = style.a;
					return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-inapplicable-Style-for-selector'));
				case 2:
					var combinator = style.a;
					return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-inapplicable-Style-for-combinator'));
				case 3:
					var pseudoElement = style.a;
					return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-inapplicable-Style-for-pseudo-element setter'));
				case 4:
					return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-inapplicable-Style-for-media-query'));
				case 5:
					return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-inapplicable-Style-for-keyframes'));
				default:
					if (!style.a.b) {
						return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-empty-Style'));
					} else {
						if (!style.a.b.b) {
							var _v1 = style.a;
							var only = _v1.a;
							var $temp$functionName = functionName,
								$temp$desiredKey = desiredKey,
								$temp$style = only;
							functionName = $temp$functionName;
							desiredKey = $temp$desiredKey;
							style = $temp$style;
							continue getOverloadedProperty;
						} else {
							var _v2 = style.a;
							var first = _v2.a;
							var rest = _v2.b;
							var $temp$functionName = functionName,
								$temp$desiredKey = desiredKey,
								$temp$style = $rtfeldman$elm_css$Css$Preprocess$ApplyStyles(rest);
							functionName = $temp$functionName;
							desiredKey = $temp$desiredKey;
							style = $temp$style;
							continue getOverloadedProperty;
						}
					}
			}
		}
	});
var $rtfeldman$elm_css$Css$Internal$IncompatibleUnits = 0;
var $elm$core$String$fromFloat = _String_fromNumber;
var $rtfeldman$elm_css$Css$Internal$lengthConverter = F3(
	function (units, unitLabel, numericValue) {
		return {
			cw: 0,
			cG: 0,
			ag: 0,
			m: 0,
			aE: 0,
			ak: 0,
			I: 0,
			al: 0,
			am: 0,
			T: 0,
			U: 0,
			B: 0,
			an: 0,
			K: numericValue,
			at: 0,
			av: unitLabel,
			aT: units,
			D: _Utils_ap(
				$elm$core$String$fromFloat(numericValue),
				unitLabel)
		};
	});
var $rtfeldman$elm_css$Css$Internal$lengthForOverloadedProperty = A3($rtfeldman$elm_css$Css$Internal$lengthConverter, 0, '', 0);
var $rtfeldman$elm_css$Css$justifyContent = function (fn) {
	return A3(
		$rtfeldman$elm_css$Css$Internal$getOverloadedProperty,
		'justifyContent',
		'justify-content',
		fn($rtfeldman$elm_css$Css$Internal$lengthForOverloadedProperty));
};
var $rtfeldman$elm_css$Css$PercentageUnits = 0;
var $rtfeldman$elm_css$Css$pct = A2($rtfeldman$elm_css$Css$Internal$lengthConverter, 0, '%');
var $rtfeldman$elm_css$Css$position = $rtfeldman$elm_css$Css$prop1('position');
var $author$project$Style$Bulma$box = 'box';
var $rtfeldman$elm_css$VirtualDom$Styled$property = F2(
	function (key, value) {
		return A3(
			$rtfeldman$elm_css$VirtualDom$Styled$Attribute,
			A2($elm$virtual_dom$VirtualDom$property, key, value),
			false,
			'');
	});
var $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			$rtfeldman$elm_css$VirtualDom$Styled$property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $rtfeldman$elm_css$Html$Styled$Attributes$class = $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('className');
var $rtfeldman$elm_css$Css$flexEnd = $rtfeldman$elm_css$Css$prop1('flex-end');
var $author$project$Main$LanguageSelection = function (a) {
	return {$: 3, a: a};
};
var $rtfeldman$elm_css$Css$backgroundColor = function (c) {
	return A2($rtfeldman$elm_css$Css$property, 'background-color', c.D);
};
var $rtfeldman$elm_css$Css$borderStyle = $rtfeldman$elm_css$Css$prop1('border-style');
var $rtfeldman$elm_css$Html$Styled$button = $rtfeldman$elm_css$Html$Styled$node('button');
var $rtfeldman$elm_css$Css$cursor = $rtfeldman$elm_css$Css$prop1('cursor');
var $rtfeldman$elm_css$Css$none = {ab: 0, cE: 0, o: 0, a: 0, g: 0, dK: 0, cT: 0, ch: 0, am: 0, T: 0, B: 0, c: 0, b: 0, cl: 0, bD: 0, d$: 0, x: 0, bJ: 0, d2: 0, as: 0, Z: 0, r: 0, f: 0, eb: 0, D: 'none'};
var $rtfeldman$elm_css$Css$pointer = {a: 0, D: 'pointer'};
var $rtfeldman$elm_css$Css$transparent = {z: 0, D: 'transparent'};
var $rtfeldman$elm_css$Html$Styled$Attributes$type_ = $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('type');
var $author$project$Style$Buttons$cleanButton = F2(
	function (attrs, body) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$button,
			A2(
				$elm$core$List$append,
				attrs,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$type_('submit'),
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$borderStyle($rtfeldman$elm_css$Css$none),
								$rtfeldman$elm_css$Css$backgroundColor($rtfeldman$elm_css$Css$transparent),
								$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer)
							]))
					])),
			body);
	});
var $author$project$S$cleanButton = $author$project$Style$Buttons$cleanButton;
var $rtfeldman$elm_css$Css$color = function (c) {
	return A2($rtfeldman$elm_css$Css$property, 'color', c.D);
};
var $rtfeldman$elm_css$Css$EmUnits = 0;
var $rtfeldman$elm_css$Css$em = A2($rtfeldman$elm_css$Css$Internal$lengthConverter, 0, 'em');
var $rtfeldman$elm_css$Css$fontSize = $rtfeldman$elm_css$Css$prop1('font-size');
var $elm$core$String$endsWith = _String_endsWith;
var $elm$core$String$toLower = _String_toLower;
var $rtfeldman$elm_css$Css$makeImportant = function (_v0) {
	var str = _v0;
	return A2(
		$elm$core$String$endsWith,
		' !important',
		$elm$core$String$toLower(str)) ? str : (str + ' !important');
};
var $rtfeldman$elm_css$Css$Preprocess$ExtendSelector = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$Preprocess$mapAllProperties = F2(
	function (update, styles) {
		if (!styles.b) {
			return styles;
		} else {
			if (!styles.b.b) {
				var only = styles.a;
				return _List_fromArray(
					[
						A2($rtfeldman$elm_css$Css$Preprocess$mapProperties, update, only)
					]);
			} else {
				var first = styles.a;
				var rest = styles.b;
				return A2(
					$elm$core$List$cons,
					first,
					A2($rtfeldman$elm_css$Css$Preprocess$mapAllProperties, update, rest));
			}
		}
	});
var $rtfeldman$elm_css$Css$Preprocess$mapProperties = F2(
	function (update, style) {
		switch (style.$) {
			case 0:
				var property = style.a;
				return $rtfeldman$elm_css$Css$Preprocess$AppendProperty(
					update(property));
			case 1:
				var selector = style.a;
				var styles = style.b;
				return A2(
					$rtfeldman$elm_css$Css$Preprocess$ExtendSelector,
					selector,
					A2($rtfeldman$elm_css$Css$Preprocess$mapAllProperties, update, styles));
			case 2:
				return style;
			case 3:
				return style;
			case 4:
				return style;
			case 5:
				return style;
			default:
				var otherStyles = style.a;
				return $rtfeldman$elm_css$Css$Preprocess$ApplyStyles(
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$Css$Preprocess$mapProperties(update),
						otherStyles));
		}
	});
var $rtfeldman$elm_css$Css$important = $rtfeldman$elm_css$Css$Preprocess$mapProperties($rtfeldman$elm_css$Css$makeImportant);
var $rtfeldman$elm_css$Css$withPrecedingHash = function (str) {
	return A2($elm$core$String$startsWith, '#', str) ? str : A2($elm$core$String$cons, '#', str);
};
var $rtfeldman$elm_css$Css$erroneousHex = function (str) {
	return {
		ay: 1,
		aA: 0,
		z: 0,
		aD: 0,
		aN: 0,
		D: $rtfeldman$elm_css$Css$withPrecedingHash(str)
	};
};
var $elm$core$String$fromChar = function (_char) {
	return A2($elm$core$String$cons, _char, '');
};
var $elm$core$Basics$pow = _Basics_pow;
var $rtfeldman$elm_hex$Hex$fromStringHelp = F3(
	function (position, chars, accumulated) {
		fromStringHelp:
		while (true) {
			if (!chars.b) {
				return $elm$core$Result$Ok(accumulated);
			} else {
				var _char = chars.a;
				var rest = chars.b;
				switch (_char) {
					case '0':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated;
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '1':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + A2($elm$core$Basics$pow, 16, position);
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '2':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (2 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '3':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (3 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '4':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (4 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '5':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (5 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '6':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (6 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '7':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (7 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '8':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (8 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '9':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (9 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'a':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (10 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'b':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (11 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'c':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (12 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'd':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (13 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'e':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (14 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'f':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (15 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					default:
						var nonHex = _char;
						return $elm$core$Result$Err(
							$elm$core$String$fromChar(nonHex) + ' is not a valid hexadecimal character.');
				}
			}
		}
	});
var $elm$core$Result$map = F2(
	function (func, ra) {
		if (!ra.$) {
			var a = ra.a;
			return $elm$core$Result$Ok(
				func(a));
		} else {
			var e = ra.a;
			return $elm$core$Result$Err(e);
		}
	});
var $elm$core$Result$mapError = F2(
	function (f, result) {
		if (!result.$) {
			var v = result.a;
			return $elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return $elm$core$Result$Err(
				f(e));
		}
	});
var $rtfeldman$elm_hex$Hex$fromString = function (str) {
	if ($elm$core$String$isEmpty(str)) {
		return $elm$core$Result$Err('Empty strings are not valid hexadecimal strings.');
	} else {
		var result = function () {
			if (A2($elm$core$String$startsWith, '-', str)) {
				var list = A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					$elm$core$List$tail(
						$elm$core$String$toList(str)));
				return A2(
					$elm$core$Result$map,
					$elm$core$Basics$negate,
					A3(
						$rtfeldman$elm_hex$Hex$fromStringHelp,
						$elm$core$List$length(list) - 1,
						list,
						0));
			} else {
				return A3(
					$rtfeldman$elm_hex$Hex$fromStringHelp,
					$elm$core$String$length(str) - 1,
					$elm$core$String$toList(str),
					0);
			}
		}();
		var formatError = function (err) {
			return A2(
				$elm$core$String$join,
				' ',
				_List_fromArray(
					['\"' + (str + '\"'), 'is not a valid hexadecimal string because', err]));
		};
		return A2($elm$core$Result$mapError, formatError, result);
	}
};
var $rtfeldman$elm_css$Css$validHex = F5(
	function (str, _v0, _v1, _v2, _v3) {
		var r1 = _v0.a;
		var r2 = _v0.b;
		var g1 = _v1.a;
		var g2 = _v1.b;
		var b1 = _v2.a;
		var b2 = _v2.b;
		var a1 = _v3.a;
		var a2 = _v3.b;
		var toResult = A2(
			$elm$core$Basics$composeR,
			$elm$core$String$fromList,
			A2($elm$core$Basics$composeR, $elm$core$String$toLower, $rtfeldman$elm_hex$Hex$fromString));
		var results = _Utils_Tuple2(
			_Utils_Tuple2(
				toResult(
					_List_fromArray(
						[r1, r2])),
				toResult(
					_List_fromArray(
						[g1, g2]))),
			_Utils_Tuple2(
				toResult(
					_List_fromArray(
						[b1, b2])),
				toResult(
					_List_fromArray(
						[a1, a2]))));
		if ((((!results.a.a.$) && (!results.a.b.$)) && (!results.b.a.$)) && (!results.b.b.$)) {
			var _v5 = results.a;
			var red = _v5.a.a;
			var green = _v5.b.a;
			var _v6 = results.b;
			var blue = _v6.a.a;
			var alpha = _v6.b.a;
			return {
				ay: alpha / 255,
				aA: blue,
				z: 0,
				aD: green,
				aN: red,
				D: $rtfeldman$elm_css$Css$withPrecedingHash(str)
			};
		} else {
			return $rtfeldman$elm_css$Css$erroneousHex(str);
		}
	});
var $rtfeldman$elm_css$Css$hex = function (str) {
	var withoutHash = A2($elm$core$String$startsWith, '#', str) ? A2($elm$core$String$dropLeft, 1, str) : str;
	var _v0 = $elm$core$String$toList(withoutHash);
	_v0$4:
	while (true) {
		if ((_v0.b && _v0.b.b) && _v0.b.b.b) {
			if (!_v0.b.b.b.b) {
				var r = _v0.a;
				var _v1 = _v0.b;
				var g = _v1.a;
				var _v2 = _v1.b;
				var b = _v2.a;
				return A5(
					$rtfeldman$elm_css$Css$validHex,
					str,
					_Utils_Tuple2(r, r),
					_Utils_Tuple2(g, g),
					_Utils_Tuple2(b, b),
					_Utils_Tuple2('f', 'f'));
			} else {
				if (!_v0.b.b.b.b.b) {
					var r = _v0.a;
					var _v3 = _v0.b;
					var g = _v3.a;
					var _v4 = _v3.b;
					var b = _v4.a;
					var _v5 = _v4.b;
					var a = _v5.a;
					return A5(
						$rtfeldman$elm_css$Css$validHex,
						str,
						_Utils_Tuple2(r, r),
						_Utils_Tuple2(g, g),
						_Utils_Tuple2(b, b),
						_Utils_Tuple2(a, a));
				} else {
					if (_v0.b.b.b.b.b.b) {
						if (!_v0.b.b.b.b.b.b.b) {
							var r1 = _v0.a;
							var _v6 = _v0.b;
							var r2 = _v6.a;
							var _v7 = _v6.b;
							var g1 = _v7.a;
							var _v8 = _v7.b;
							var g2 = _v8.a;
							var _v9 = _v8.b;
							var b1 = _v9.a;
							var _v10 = _v9.b;
							var b2 = _v10.a;
							return A5(
								$rtfeldman$elm_css$Css$validHex,
								str,
								_Utils_Tuple2(r1, r2),
								_Utils_Tuple2(g1, g2),
								_Utils_Tuple2(b1, b2),
								_Utils_Tuple2('f', 'f'));
						} else {
							if (_v0.b.b.b.b.b.b.b.b && (!_v0.b.b.b.b.b.b.b.b.b)) {
								var r1 = _v0.a;
								var _v11 = _v0.b;
								var r2 = _v11.a;
								var _v12 = _v11.b;
								var g1 = _v12.a;
								var _v13 = _v12.b;
								var g2 = _v13.a;
								var _v14 = _v13.b;
								var b1 = _v14.a;
								var _v15 = _v14.b;
								var b2 = _v15.a;
								var _v16 = _v15.b;
								var a1 = _v16.a;
								var _v17 = _v16.b;
								var a2 = _v17.a;
								return A5(
									$rtfeldman$elm_css$Css$validHex,
									str,
									_Utils_Tuple2(r1, r2),
									_Utils_Tuple2(g1, g2),
									_Utils_Tuple2(b1, b2),
									_Utils_Tuple2(a1, a2));
							} else {
								break _v0$4;
							}
						}
					} else {
						break _v0$4;
					}
				}
			}
		} else {
			break _v0$4;
		}
	}
	return $rtfeldman$elm_css$Css$erroneousHex(str);
};
var $author$project$Theme$Color$linkBlue = $rtfeldman$elm_css$Css$hex('#3273dc');
var $rtfeldman$elm_css$Css$marginLeft = $rtfeldman$elm_css$Css$prop1('margin-left');
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $rtfeldman$elm_css$VirtualDom$Styled$on = F2(
	function (eventName, handler) {
		return A3(
			$rtfeldman$elm_css$VirtualDom$Styled$Attribute,
			A2($elm$virtual_dom$VirtualDom$on, eventName, handler),
			false,
			'');
	});
var $rtfeldman$elm_css$Html$Styled$Events$on = F2(
	function (event, decoder) {
		return A2(
			$rtfeldman$elm_css$VirtualDom$Styled$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $rtfeldman$elm_css$Html$Styled$Events$onClick = function (msg) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $rtfeldman$elm_css$VirtualDom$Styled$Unstyled = function (a) {
	return {$: 4, a: a};
};
var $rtfeldman$elm_css$VirtualDom$Styled$text = function (str) {
	return $rtfeldman$elm_css$VirtualDom$Styled$Unstyled(
		$elm$virtual_dom$VirtualDom$text(str));
};
var $rtfeldman$elm_css$Html$Styled$text = $rtfeldman$elm_css$VirtualDom$Styled$text;
var $rtfeldman$elm_css$Css$zIndex = $rtfeldman$elm_css$Css$prop1('z-index');
var $author$project$Main$languageSelectionView = function (language) {
	var _v0 = function () {
		if (!language) {
			return _Utils_Tuple2('Français', 1);
		} else {
			return _Utils_Tuple2('English', 0);
		}
	}();
	var languageOption = _v0.a;
	var selectedLanguage = _v0.b;
	return A2(
		$author$project$S$cleanButton,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$important(
						$rtfeldman$elm_css$Css$fontSize(
							$rtfeldman$elm_css$Css$em(1))),
						$rtfeldman$elm_css$Css$color($author$project$Theme$Color$linkBlue),
						$rtfeldman$elm_css$Css$zIndex(
						$rtfeldman$elm_css$Css$int(10)),
						$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$fixed),
						$rtfeldman$elm_css$Css$marginLeft(
						$rtfeldman$elm_css$Css$em(2))
					])),
				$rtfeldman$elm_css$Html$Styled$Events$onClick(
				$author$project$Main$LanguageSelection(selectedLanguage))
			]),
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$text(languageOption)
			]));
};
var $rtfeldman$elm_css$VirtualDom$Styled$KeyedNode = F3(
	function (a, b, c) {
		return {$: 2, a: a, b: b, c: c};
	});
var $rtfeldman$elm_css$VirtualDom$Styled$KeyedNodeNS = F4(
	function (a, b, c, d) {
		return {$: 3, a: a, b: b, c: c, d: d};
	});
var $rtfeldman$elm_css$VirtualDom$Styled$NodeNS = F4(
	function (a, b, c, d) {
		return {$: 1, a: a, b: b, c: c, d: d};
	});
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $elm$virtual_dom$VirtualDom$mapAttribute = _VirtualDom_mapAttribute;
var $rtfeldman$elm_css$VirtualDom$Styled$mapAttribute = F2(
	function (transform, _v0) {
		var prop = _v0.a;
		var isCssStyle = _v0.b;
		var cssTemplate = _v0.c;
		return A3(
			$rtfeldman$elm_css$VirtualDom$Styled$Attribute,
			A2($elm$virtual_dom$VirtualDom$mapAttribute, transform, prop),
			isCssStyle,
			cssTemplate);
	});
var $rtfeldman$elm_css$VirtualDom$Styled$map = F2(
	function (transform, vdomNode) {
		switch (vdomNode.$) {
			case 0:
				var elemType = vdomNode.a;
				var properties = vdomNode.b;
				var children = vdomNode.c;
				return A3(
					$rtfeldman$elm_css$VirtualDom$Styled$Node,
					elemType,
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$VirtualDom$Styled$mapAttribute(transform),
						properties),
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$VirtualDom$Styled$map(transform),
						children));
			case 1:
				var ns = vdomNode.a;
				var elemType = vdomNode.b;
				var properties = vdomNode.c;
				var children = vdomNode.d;
				return A4(
					$rtfeldman$elm_css$VirtualDom$Styled$NodeNS,
					ns,
					elemType,
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$VirtualDom$Styled$mapAttribute(transform),
						properties),
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$VirtualDom$Styled$map(transform),
						children));
			case 2:
				var elemType = vdomNode.a;
				var properties = vdomNode.b;
				var children = vdomNode.c;
				return A3(
					$rtfeldman$elm_css$VirtualDom$Styled$KeyedNode,
					elemType,
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$VirtualDom$Styled$mapAttribute(transform),
						properties),
					A2(
						$elm$core$List$map,
						function (_v1) {
							var key = _v1.a;
							var child = _v1.b;
							return _Utils_Tuple2(
								key,
								A2($rtfeldman$elm_css$VirtualDom$Styled$map, transform, child));
						},
						children));
			case 3:
				var ns = vdomNode.a;
				var elemType = vdomNode.b;
				var properties = vdomNode.c;
				var children = vdomNode.d;
				return A4(
					$rtfeldman$elm_css$VirtualDom$Styled$KeyedNodeNS,
					ns,
					elemType,
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$VirtualDom$Styled$mapAttribute(transform),
						properties),
					A2(
						$elm$core$List$map,
						function (_v2) {
							var key = _v2.a;
							var child = _v2.b;
							return _Utils_Tuple2(
								key,
								A2($rtfeldman$elm_css$VirtualDom$Styled$map, transform, child));
						},
						children));
			default:
				var vdom = vdomNode.a;
				return $rtfeldman$elm_css$VirtualDom$Styled$Unstyled(
					A2($elm$virtual_dom$VirtualDom$map, transform, vdom));
		}
	});
var $rtfeldman$elm_css$Html$Styled$map = $rtfeldman$elm_css$VirtualDom$Styled$map;
var $rtfeldman$elm_css$Css$marginBottom = $rtfeldman$elm_css$Css$prop1('margin-bottom');
var $rtfeldman$elm_css$Css$Media$feature = F2(
	function (key, _v0) {
		var value = _v0.D;
		return {
			cN: key,
			D: $elm$core$Maybe$Just(value)
		};
	});
var $rtfeldman$elm_css$Css$Media$maxWidth = function (value) {
	return A2($rtfeldman$elm_css$Css$Media$feature, 'max-width', value);
};
var $rtfeldman$elm_css$Css$Structure$OnlyQuery = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$Media$only = $rtfeldman$elm_css$Css$Structure$OnlyQuery;
var $rtfeldman$elm_css$Css$PxUnits = 0;
var $rtfeldman$elm_css$Css$px = A2($rtfeldman$elm_css$Css$Internal$lengthConverter, 0, 'px');
var $rtfeldman$elm_css$Css$Structure$Screen = 1;
var $rtfeldman$elm_css$Css$Media$screen = 1;
var $rtfeldman$elm_css$Css$Preprocess$WithMedia = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$Media$withMedia = $rtfeldman$elm_css$Css$Preprocess$WithMedia;
var $author$project$Style$Media$onMobile = $rtfeldman$elm_css$Css$Media$withMedia(
	_List_fromArray(
		[
			A2(
			$rtfeldman$elm_css$Css$Media$only,
			$rtfeldman$elm_css$Css$Media$screen,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$Media$maxWidth(
					$rtfeldman$elm_css$Css$px(450))
				]))
		]));
var $rtfeldman$elm_css$Css$prop2 = F3(
	function (key, argA, argB) {
		return A2($rtfeldman$elm_css$Css$property, key, argA.D + (' ' + argB.D));
	});
var $rtfeldman$elm_css$Css$padding2 = $rtfeldman$elm_css$Css$prop2('padding');
var $rtfeldman$elm_css$Css$RemUnits = 0;
var $rtfeldman$elm_css$Css$rem = A2($rtfeldman$elm_css$Css$Internal$lengthConverter, 0, 'rem');
var $author$project$Style$Grid$displayGrid = A2($rtfeldman$elm_css$Css$property, 'display', 'grid');
var $rtfeldman$elm_css$Html$Styled$li = $rtfeldman$elm_css$Html$Styled$node('li');
var $rtfeldman$elm_css$Css$paddingLeft = $rtfeldman$elm_css$Css$prop1('padding-left');
var $author$project$Style$List$li = function (attrs) {
	return $rtfeldman$elm_css$Html$Styled$li(
		A2(
			$elm$core$List$cons,
			$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$paddingLeft(
						$rtfeldman$elm_css$Css$em(0.75))
					])),
			attrs));
};
var $author$project$S$li = $author$project$Style$List$li;
var $rtfeldman$elm_css$Html$Styled$p = $rtfeldman$elm_css$Html$Styled$node('p');
var $author$project$ROI$View$en = {
	aW: _List_fromArray(
		[
			A2(
			$rtfeldman$elm_css$Html$Styled$p,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('With an additional 15% for wiring, installation, etc.')
				])),
			A2(
			$rtfeldman$elm_css$Html$Styled$p,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Does not include mechanization')
				]))
		]),
	aX: 'Securing your production with alerts',
	aY: 'Let\'s analyze the monetary aspect with this following tool.',
	b6: 'Total crop area in greenhouses',
	a0: 'Automation cost',
	a1: 'Available funding',
	a2: 'Boost your profits of ',
	a4: 'For any questions, please contact us!',
	cd: 'Current yield',
	A: '$/year',
	bc: 'Estimate your yield while taking into account:',
	be: '1st',
	bg: 'Further considerations',
	bh: 'Taking into account that 40% of gains become profits',
	bi: 'Gives you back ',
	bl: 'Saved time, cost and stress thanks to humidity control allowing : ',
	bm: _List_fromArray(
		[
			A2(
			$author$project$S$li,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Decrease in the disease risk')
				])),
			A2(
			$author$project$S$li,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Better efficiency in controlling predator insects')
				])),
			A2(
			$author$project$S$li,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Better yield')
				]))
		]),
	bn: '$ in 5 years',
	cg: 'Time spent on irrigation',
	bp: 'Is it a good purchase?',
	aG: 'min/greenhouse/day',
	bx: 'Net gains when equipment is payed',
	cn: 'Estimated yield with Orisha',
	bE: 'Pays for itself in the ',
	bF: '% of its cost in 5 years',
	bH: 'Potential gains',
	bI: 'Realistic gains',
	bL: 'Results',
	bM: 'Time spent on management of roofs and side curtains',
	bN: 'Savings',
	bP: 'Season duration',
	bQ: '2nd',
	cq: 'Selling price',
	bS: 'ft²',
	bU: 'Subscription',
	bX: 'th',
	bY: '3rd',
	bZ: 'Time savings',
	b_: 'Time savings + realistic gains',
	cv: 'Hourly wage',
	b0: 'Do not forget to take into account walking and disruption time',
	b2: 'With these numbers, Orisha...',
	b3: 'Enter the average working time in minutes per greenhouse per day',
	dr: ' year',
	b4: _List_fromArray(
		[
			A2(
			$author$project$S$li,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Optimized temperature and humidity')
				])),
			A2(
			$author$project$S$li,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Lower risks of disease')
				]))
		]),
	b5: 'Yield gains'
};
var $author$project$ROI$View$fr = {
	aW: _List_fromArray(
		[
			A2(
			$rtfeldman$elm_css$Html$Styled$p,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Incluant un 15% supplémentaire pour le filage, l\'installation, etc.')
				])),
			A2(
			$rtfeldman$elm_css$Html$Styled$p,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('N\'inclut pas la mécanisation')
				]))
		]),
	aX: 'Sécurisation de votre production grâce aux alertes',
	aY: 'Analysons l\'aspect monétaire avec l\'outil ci-dessous.',
	b6: 'Surface de culture totale dans les serres à automatiser',
	a0: 'Coût pour l\'automatisation',
	a1: 'Les subventions disponibles',
	a2: 'Augmente vos profits de ',
	a4: 'Pour toutes questions, n\'hésitez pas à nous contacter!',
	cd: 'Rendement actuel',
	A: '$/année',
	bc: 'Estimez quel sera votre rendement considérant :',
	be: '1ère',
	bg: 'Autres points à considérer',
	bh: 'Considérant que 40% des gains se convertissent en profits',
	bi: 'Vous rapporte ',
	bl: 'Le temps, les dépenses et le stress épargnés grâce à la gestion de l\'humidité qui permet : ',
	bm: _List_fromArray(
		[
			A2(
			$author$project$S$li,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('La diminution des risques de maladies')
				])),
			A2(
			$author$project$S$li,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('L\'augmentation de l\'efficacité des insectes prédateurs')
				])),
			A2(
			$author$project$S$li,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Un meilleur rendement')
				]))
		]),
	bn: '$ en 5 ans',
	cg: 'Temps passé à irriguer',
	bp: 'Est-ce une bonne acquisition?',
	aG: 'min/serre/jour',
	bx: 'Gains nets une fois le matériel payé',
	cn: 'Rendement estimé avec Orisha',
	bE: 'Se paie dans la ',
	bF: '% de son coût en 5 ans',
	bH: 'Gains potentiels',
	bI: 'Gains réalistes',
	bL: 'Conclusion',
	bM: 'Temps passé à contrôler les ouvrants',
	bN: 'Économies',
	bP: 'Durée de la saison',
	bQ: '2e',
	cq: 'Prix de vente',
	bS: 'pied²',
	bU: 'Abonnement',
	bX: 'e',
	bY: '3e',
	bZ: 'Économies de temps',
	b_: 'Économies de temps + gains réalistes',
	cv: 'Salaire horaire',
	b0: 'Pensez à compter les temps de déplacement et d\'interruption pour chacun des cas',
	b2: 'Avec ces chiffres, Orisha...',
	b3: 'Entrez le temps de travail moyen en minutes par serre par jour',
	dr: ' année',
	b4: _List_fromArray(
		[
			A2(
			$author$project$S$li,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Un climat plus optimal')
				])),
			A2(
			$author$project$S$li,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Une diminution des risques de maladies')
				]))
		]),
	b5: 'Gains de rendement'
};
var $author$project$ROI$Update$Area = function (a) {
	return {$: 2, a: a};
};
var $author$project$ROI$Update$CurrentYield = function (a) {
	return {$: 0, a: a};
};
var $author$project$ROI$Update$OrishaYield = function (a) {
	return {$: 1, a: a};
};
var $author$project$ROI$Update$SellingPrice = function (a) {
	return {$: 3, a: a};
};
var $elm$core$Basics$round = _Basics_round;
var $elm$core$String$toFloat = _String_toFloat;
var $author$project$ROI$View$calculateGains = F2(
	function (model, numberOfEquipments) {
		var sellingPrice = A2(
			$elm$core$Maybe$withDefault,
			0,
			$elm$core$String$toFloat(model.cq));
		var orishaYieldCorrected = function (y) {
			return y + (numberOfEquipments / 20);
		}(
			A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toFloat(model.cn)));
		var feetToMeter = 1 / 3.28084;
		var currentYield = A2(
			$elm$core$Maybe$withDefault,
			0,
			$elm$core$String$toFloat(model.cd));
		var yieldDifference = orishaYieldCorrected - currentYield;
		var area = A2(
			$elm$core$Maybe$withDefault,
			0,
			$elm$core$String$toFloat(model.b6));
		return $elm$core$Basics$round(
			((yieldDifference * area) * sellingPrice) * A2($elm$core$Basics$pow, feetToMeter, 2));
	});
var $rtfeldman$elm_css$Html$Styled$a = $rtfeldman$elm_css$Html$Styled$node('a');
var $author$project$Style$Bulma$button = 'button';
var $author$project$Style$Bulma$field = 'field';
var $author$project$Style$Bulma$hasAddonsRight = 'has-addons-right';
var $author$project$Style$Bulma$control = 'control';
var $rtfeldman$elm_css$Css$initial = {cy: 0, cz: 0, az: 0, cB: 0, ab: 0, cC: 0, ac: 0, F: 0, o: 0, a3: 0, z: 0, a: 0, g: 0, ag: 0, cf: 0, aB: 0, bf: 0, Q: 0, m: 0, aC: 0, e: 0, R: 0, bo: 0, cU: 0, ch: 0, aE: 0, ak: 0, I: 0, al: 0, am: 0, T: 0, U: 0, B: 0, an: 0, bt: 0, c: 0, b: 0, cl: 0, aJ: 0, K: 0, bD: 0, ao: 0, x: 0, bW: 0, as: 0, Y: 0, at: 0, au: 0, Z: 0, r: 0, av: '', aT: 0, D: 'initial', aV: 0, aa: 0};
var $rtfeldman$elm_css$Css$inherit = _Utils_update(
	$rtfeldman$elm_css$Css$initial,
	{D: 'inherit'});
var $author$project$Style$Form$control = $rtfeldman$elm_css$Html$Styled$div(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Html$Styled$Attributes$class($author$project$Style$Bulma$control),
			$rtfeldman$elm_css$Html$Styled$Attributes$css(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$fontSize($rtfeldman$elm_css$Css$inherit)
				]))
		]));
var $rtfeldman$elm_css$Html$Styled$input = $rtfeldman$elm_css$Html$Styled$node('input');
var $author$project$Style$Bulma$input = 'input';
var $author$project$Style$Form$input = F2(
	function (attrs, children) {
		return $author$project$Style$Form$control(
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$input,
					$elm$core$List$concat(
						_List_fromArray(
							[
								_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class($author$project$Style$Bulma$input),
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$fontSize($rtfeldman$elm_css$Css$inherit)
										]))
								]),
								attrs
							])),
					children)
				]));
	});
var $author$project$Style$Bulma$isStatic = 'is-static';
var $author$project$Style$Form$inputWithUnit = F2(
	function (unit, attrs) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class($author$project$Style$Bulma$field),
					$rtfeldman$elm_css$Html$Styled$Attributes$class($author$project$Style$Bulma$hasAddonsRight),
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$displayFlex,
							$rtfeldman$elm_css$Css$important(
							$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center))
						]))
				]),
			_List_fromArray(
				[
					A2(
					$author$project$Style$Form$input,
					A2(
						$elm$core$List$append,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$zIndex(
										$rtfeldman$elm_css$Css$int(2))
									]))
							]),
						attrs),
					_List_Nil),
					A2(
					$rtfeldman$elm_css$Html$Styled$a,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class($author$project$Style$Bulma$button),
							$rtfeldman$elm_css$Html$Styled$Attributes$class($author$project$Style$Bulma$isStatic),
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$important(
									$rtfeldman$elm_css$Css$fontSize(
										$rtfeldman$elm_css$Css$em(1)))
								]))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(unit)
						]))
				]));
	});
var $author$project$S$inputWithUnit = $author$project$Style$Form$inputWithUnit;
var $rtfeldman$elm_css$Html$Styled$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var $rtfeldman$elm_css$Html$Styled$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$rtfeldman$elm_css$VirtualDom$Styled$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $elm$json$Json$Decode$field = _Json_decodeField;
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $rtfeldman$elm_css$Html$Styled$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $rtfeldman$elm_css$Html$Styled$Events$onInput = function (tagger) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$rtfeldman$elm_css$Html$Styled$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $rtfeldman$elm_css$Html$Styled$Events$targetValue)));
};
var $rtfeldman$elm_css$Css$spaceBetween = $rtfeldman$elm_css$Css$prop1('space-between');
var $rtfeldman$elm_css$Css$textAlign = function (fn) {
	return A3(
		$rtfeldman$elm_css$Css$Internal$getOverloadedProperty,
		'textAlign',
		'text-align',
		fn($rtfeldman$elm_css$Css$Internal$lengthForOverloadedProperty));
};
var $rtfeldman$elm_css$Html$Styled$Attributes$value = $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('value');
var $rtfeldman$elm_css$Css$width = $rtfeldman$elm_css$Css$prop1('width');
var $author$project$ROI$View$field = F4(
	function (name, unit, value, msg) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$displayFlex,
							$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$spaceBetween)
						]))
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text(name),
					A2(
					$author$project$S$inputWithUnit,
					unit,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$value(value),
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$width(
									$rtfeldman$elm_css$Css$em(4)),
									$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center)
								])),
							$rtfeldman$elm_css$Html$Styled$Events$onInput(msg)
						]))
				]));
	});
var $author$project$Style$Grid$gridRowGap = $rtfeldman$elm_css$Css$property('grid-row-gap');
var $rtfeldman$elm_css$Html$Styled$h4 = $rtfeldman$elm_css$Html$Styled$node('h4');
var $author$project$Style$Title$h4 = function (attrs) {
	return $rtfeldman$elm_css$Html$Styled$h4(
		A2(
			$elm$core$List$append,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('title is-4')
				]),
			attrs));
};
var $author$project$S$h4 = $author$project$Style$Title$h4;
var $author$project$ROI$View$realisticGains = F2(
	function (model, numberOfEquipments) {
		return $elm$core$Basics$round(
			0.4 * A2($author$project$ROI$View$calculateGains, model, numberOfEquipments));
	});
var $elm$core$Bitwise$shiftRightBy = _Bitwise_shiftRightBy;
var $elm$core$String$repeatHelp = F3(
	function (n, chunk, result) {
		return (n <= 0) ? result : A3(
			$elm$core$String$repeatHelp,
			n >> 1,
			_Utils_ap(chunk, chunk),
			(!(n & 1)) ? result : _Utils_ap(result, chunk));
	});
var $elm$core$String$repeat = F2(
	function (n, chunk) {
		return A3($elm$core$String$repeatHelp, n, chunk, '');
	});
var $elm$core$String$padLeft = F3(
	function (n, _char, string) {
		return _Utils_ap(
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)),
			string);
	});
var $author$project$Formatter$Number$formatPrice = function (price) {
	return (price > 999) ? ($elm$core$String$fromInt((price / 1000) | 0) + (' ' + A3(
		$elm$core$String$padLeft,
		3,
		'0',
		$elm$core$String$fromInt(price % 1000)))) : $elm$core$String$fromInt(price);
};
var $rtfeldman$elm_css$Html$Styled$strong = $rtfeldman$elm_css$Html$Styled$node('strong');
var $author$project$ROI$View$resultLine = F3(
	function (name, total, unit) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$displayFlex,
							$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$spaceBetween)
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$strong,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(name)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$strong,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$author$project$Formatter$Number$formatPrice(total) + (' ' + unit))
						]))
				]));
	});
var $rtfeldman$elm_css$Css$disc = {c: 0, b: 0, D: 'disc'};
var $rtfeldman$elm_css$Css$listStyle = $rtfeldman$elm_css$Css$prop1('list-style');
var $rtfeldman$elm_css$Html$Styled$ul = $rtfeldman$elm_css$Html$Styled$node('ul');
var $author$project$Style$List$ul = function (attrs) {
	return $rtfeldman$elm_css$Html$Styled$ul(
		A2(
			$elm$core$List$cons,
			$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						$author$project$Style$Grid$displayGrid,
						$author$project$Style$Grid$gridRowGap('0.75em'),
						$rtfeldman$elm_css$Css$marginLeft(
						$rtfeldman$elm_css$Css$em(2)),
						$rtfeldman$elm_css$Css$marginBottom(
						$rtfeldman$elm_css$Css$em(1)),
						$rtfeldman$elm_css$Css$listStyle($rtfeldman$elm_css$Css$disc)
					])),
			attrs));
};
var $author$project$S$ul = $author$project$Style$List$ul;
var $author$project$ROI$View$gains = F3(
	function (translation, model, numberOfEquipments) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$author$project$Style$Grid$displayGrid,
							$author$project$Style$Grid$gridRowGap('1rem')
						]))
				]),
			_List_fromArray(
				[
					A2(
					$author$project$S$h4,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(translation.b5)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$p,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(translation.bc)
						])),
					A2($author$project$S$ul, _List_Nil, translation.b4),
					A4($author$project$ROI$View$field, translation.cd, 'kg/m²', model.cd, $author$project$ROI$Update$CurrentYield),
					A4($author$project$ROI$View$field, translation.cn, 'kg/m²', model.cn, $author$project$ROI$Update$OrishaYield),
					A4($author$project$ROI$View$field, translation.b6, translation.bS, model.b6, $author$project$ROI$Update$Area),
					A4($author$project$ROI$View$field, translation.cq, '$/kg', model.cq, $author$project$ROI$Update$SellingPrice),
					A3(
					$author$project$ROI$View$resultLine,
					translation.bH,
					A2($author$project$ROI$View$calculateGains, model, numberOfEquipments),
					translation.A),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$fontSize(
											$rtfeldman$elm_css$Css$rem(1.2))
										]))
								]),
							_List_fromArray(
								[
									A3(
									$author$project$ROI$View$resultLine,
									translation.bI,
									A2($author$project$ROI$View$realisticGains, model, numberOfEquipments),
									translation.A)
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$p,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$marginLeft(
											$rtfeldman$elm_css$Css$rem(1))
										]))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(translation.bh)
								]))
						]))
				]));
	});
var $author$project$Language$getTranslation = F2(
	function (language, translations) {
		if (!language) {
			return translations.dG;
		} else {
			return translations.dI;
		}
	});
var $rtfeldman$elm_css$Html$Styled$h2 = $rtfeldman$elm_css$Html$Styled$node('h2');
var $author$project$Style$Title$h2 = function (attrs) {
	return $rtfeldman$elm_css$Html$Styled$h2(
		A2(
			$elm$core$List$append,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('title is-2')
				]),
			attrs));
};
var $author$project$S$h2 = $author$project$Style$Title$h2;
var $rtfeldman$elm_css$Html$Styled$hr = $rtfeldman$elm_css$Html$Styled$node('hr');
var $rtfeldman$elm_css$Css$marginTop = $rtfeldman$elm_css$Css$prop1('margin-top');
var $author$project$Info$phoneNumber = '1-888-267-4742';
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$getDayDiff = F2(
	function (startDate, endDate) {
		var _v0 = _Utils_Tuple2(
			$PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$toPosix(startDate),
			$PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$toPosix(endDate));
		var startPosix = _v0.a;
		var endPosix = _v0.b;
		var posixDiff = $elm$time$Time$posixToMillis(endPosix) - $elm$time$Time$posixToMillis(startPosix);
		return (posixDiff / $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$millisInADay) | 0;
	});
var $PanagiotisGeorgiadis$elm_datetime$Calendar$getDayDiff = $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$getDayDiff;
var $PanagiotisGeorgiadis$elm_datetime$DateTime$Internal$getDayDiff = F2(
	function (_v0, _v1) {
		var lhs = _v0;
		var rhs = _v1;
		return A2($PanagiotisGeorgiadis$elm_datetime$Calendar$getDayDiff, lhs.cJ, rhs.cJ);
	});
var $PanagiotisGeorgiadis$elm_datetime$DateTime$getDayDiff = $PanagiotisGeorgiadis$elm_datetime$DateTime$Internal$getDayDiff;
var $author$project$Component$DateRange$durationInDays = function (model) {
	return A2(
		$elm$core$Maybe$map,
		function (r) {
			return A2($PanagiotisGeorgiadis$elm_datetime$DateTime$getDayDiff, r.dh, r.cL);
		},
		A2(
			$elm$core$Maybe$andThen,
			function ($) {
				return $.ap;
			},
			model));
};
var $author$project$ROI$View$calculateTimeSaving = F2(
	function (numberOfGreenhouses, model) {
		var wage = A2(
			$elm$core$Maybe$withDefault,
			0,
			$elm$core$String$toFloat(model.cv));
		var seasonLength = A2(
			$elm$core$Maybe$withDefault,
			0,
			A2(
				$elm$core$Maybe$map,
				$elm$core$Basics$toFloat,
				$author$project$Component$DateRange$durationInDays(model.a5)));
		var rollups = A2(
			$elm$core$Maybe$withDefault,
			0,
			$elm$core$String$toFloat(model.cp));
		var minToHour = 1 / 60;
		var irrigation = A2(
			$elm$core$Maybe$withDefault,
			0,
			$elm$core$String$toFloat(model.cg));
		var greenhouses = numberOfGreenhouses;
		return $elm$core$Basics$round(((((rollups + irrigation) * greenhouses) * wage) * seasonLength) * minToHour);
	});
var $author$project$Theme$Color$blue = $rtfeldman$elm_css$Css$hex('#00a2ea');
var $author$project$ROI$View$conclusion = F2(
	function (textColor, str) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$p,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$fontSize(
							$rtfeldman$elm_css$Css$rem(1.5)),
							$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center),
							$rtfeldman$elm_css$Css$color(textColor),
							$rtfeldman$elm_css$Css$marginBottom(
							$rtfeldman$elm_css$Css$rem(1.5))
						]))
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text(str)
				]));
	});
var $author$project$S$empty = $rtfeldman$elm_css$Html$Styled$text('');
var $author$project$Theme$Color$green = $rtfeldman$elm_css$Css$hex('#149614');
var $rtfeldman$elm_css$Css$fontWeight = function (_v0) {
	var value = _v0.D;
	return A2($rtfeldman$elm_css$Css$property, 'font-weight', value);
};
var $author$project$Style$Text$talk1 = function (str) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$h4,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('title is-4'),
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$important(
						$rtfeldman$elm_css$Css$marginBottom(
							$rtfeldman$elm_css$Css$rem(3))),
						$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center),
						$rtfeldman$elm_css$Css$fontWeight(
						$rtfeldman$elm_css$Css$int(200))
					]))
			]),
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$text(str)
			]));
};
var $author$project$S$talk1 = $author$project$Style$Text$talk1;
var $author$project$ROI$View$conclusions = F4(
	function (translation, annualProfit, monthlySubscription, totalAutomationCosts) {
		var yearOfPayBack = ((totalAutomationCosts / (annualProfit - (12 * monthlySubscription))) | 0) + 1;
		var formattedYearOfPayBack = function () {
			switch (yearOfPayBack) {
				case 1:
					return translation.be;
				case 2:
					return translation.bQ;
				case 3:
					return translation.bY;
				default:
					var x = yearOfPayBack;
					return _Utils_ap(
						$elm$core$String$fromInt(x),
						translation.bX);
			}
		}();
		var fiveYearRoi = $elm$core$String$fromInt(
			$elm$core$Basics$round(((annualProfit * 5) / (totalAutomationCosts + (60 * monthlySubscription))) * 100));
		var fiveYearProfit = $author$project$Formatter$Number$formatPrice(
			function (x) {
				return x * 1000;
			}(
				function (x) {
					return (x / 1000) | 0;
				}(((annualProfit * 5) - totalAutomationCosts) - (monthlySubscription * 60))));
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$marginTop(
							$rtfeldman$elm_css$Css$rem(3))
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$marginBottom(
									$rtfeldman$elm_css$Css$rem(-1))
								]))
						]),
					_List_fromArray(
						[
							$author$project$S$talk1(translation.b2)
						])),
					A2(
					$author$project$ROI$View$conclusion,
					$author$project$Theme$Color$blue,
					_Utils_ap(
						translation.bE,
						_Utils_ap(formattedYearOfPayBack, translation.dr))),
					A2(
					$author$project$ROI$View$conclusion,
					$author$project$Theme$Color$green,
					_Utils_ap(
						translation.a2,
						_Utils_ap(fiveYearProfit, translation.bn))),
					(!(!monthlySubscription)) ? A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$author$project$ROI$View$conclusion,
							$author$project$Theme$Color$blue,
							_Utils_ap(
								translation.bi,
								_Utils_ap(fiveYearRoi, translation.bF)))
						])) : $author$project$S$empty
				]));
	});
var $author$project$ROI$View$results = F6(
	function (translation, numberOfGreenhouses, systemPrice, monthlySubscription, numberOfEquipments, model) {
		var totalAutomationCosts = $elm$core$Basics$round(systemPrice * 1.15);
		var annualSubscription = monthlySubscription * 12;
		var annualProfit = A2($author$project$ROI$View$calculateTimeSaving, numberOfGreenhouses, model) + A2($author$project$ROI$View$realisticGains, model, numberOfEquipments);
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$author$project$Style$Grid$displayGrid,
							$author$project$Style$Grid$gridRowGap('1rem')
						]))
				]),
			_List_fromArray(
				[
					A2(
					$author$project$S$h4,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(translation.bL)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							A3($author$project$ROI$View$resultLine, translation.a0, totalAutomationCosts, '$'),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$marginLeft(
											$rtfeldman$elm_css$Css$rem(1)),
											$rtfeldman$elm_css$Css$marginBottom(
											$rtfeldman$elm_css$Css$rem(1))
										]))
								]),
							translation.aW),
							A3($author$project$ROI$View$resultLine, translation.bU, annualSubscription, translation.A)
						])),
					A3($author$project$ROI$View$resultLine, translation.b_, annualProfit, translation.A),
					A3($author$project$ROI$View$resultLine, translation.bx, annualProfit - annualSubscription, translation.A),
					A4($author$project$ROI$View$conclusions, translation, annualProfit, monthlySubscription, totalAutomationCosts)
				]));
	});
var $author$project$ROI$Update$IrrigationTime = function (a) {
	return {$: 5, a: a};
};
var $author$project$ROI$Update$RollupTime = function (a) {
	return {$: 4, a: a};
};
var $author$project$ROI$Update$Wage = function (a) {
	return {$: 6, a: a};
};
var $rtfeldman$elm_css$Html$Styled$label = $rtfeldman$elm_css$Html$Styled$node('label');
var $author$project$Style$Form$label = F2(
	function (attrs, body) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$label,
			_Utils_ap(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('label'),
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$fontSize(
								$rtfeldman$elm_css$Css$em(1)),
								$rtfeldman$elm_css$Css$important(
								$rtfeldman$elm_css$Css$marginBottom(
									$rtfeldman$elm_css$Css$px(0)))
							]))
					]),
				attrs),
			body);
	});
var $author$project$S$label = $author$project$Style$Form$label;
var $rtfeldman$elm_css$Css$alignItems = function (fn) {
	return A3(
		$rtfeldman$elm_css$Css$Internal$getOverloadedProperty,
		'alignItems',
		'align-items',
		fn($rtfeldman$elm_css$Css$Internal$lengthForOverloadedProperty));
};
var $rtfeldman$elm_css$Css$row = {cf: 0, aB: 0, D: 'row'};
var $rtfeldman$elm_css$Css$column = _Utils_update(
	$rtfeldman$elm_css$Css$row,
	{D: 'column'});
var $PanagiotisGeorgiadis$elm_datetime$Calendar$getDay = A2($elm$core$Basics$composeL, $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$dayToInt, $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$getDay);
var $PanagiotisGeorgiadis$elm_datetime$DateTime$Internal$getDay = A2($elm$core$Basics$composeL, $PanagiotisGeorgiadis$elm_datetime$Calendar$getDay, $PanagiotisGeorgiadis$elm_datetime$DateTime$Internal$getDate);
var $PanagiotisGeorgiadis$elm_datetime$DateTime$getDay = $PanagiotisGeorgiadis$elm_datetime$DateTime$Internal$getDay;
var $author$project$Component$DateRange$month = F2(
	function (translation, m) {
		switch (m) {
			case 0:
				return translation.bq;
			case 1:
				return translation.bd;
			case 2:
				return translation.bu;
			case 3:
				return translation.a_;
			case 4:
				return translation.bv;
			case 5:
				return translation.bs;
			case 6:
				return translation.br;
			case 7:
				return translation.a$;
			case 8:
				return translation.bR;
			case 9:
				return translation.bB;
			case 10:
				return translation.by;
			default:
				return translation.a8;
		}
	});
var $author$project$Component$DateRange$dayMonthYear = F3(
	function (language, translation, date) {
		var formattedYear = $elm$core$String$fromInt(
			$PanagiotisGeorgiadis$elm_datetime$DateTime$getYear(date));
		var formattedMonth = A2(
			$author$project$Component$DateRange$month,
			translation,
			$PanagiotisGeorgiadis$elm_datetime$DateTime$getMonth(date));
		var formattedDay = $elm$core$String$fromInt(
			$PanagiotisGeorgiadis$elm_datetime$DateTime$getDay(date));
		if (!language) {
			return ' ' + (formattedMonth + (' ' + (formattedDay + (', ' + formattedYear))));
		} else {
			return ' ' + (formattedDay + (' ' + (formattedMonth + (' ' + formattedYear))));
		}
	});
var $author$project$Component$DateRange$en = {a_: 'April', a$: 'August', a6: 'Dates to choose', a7: ' days', a8: 'December', ba: 'Duration', bb: 'End', bd: 'February', bq: 'January', br: 'July', bs: 'June', bu: 'March', bv: 'May', by: 'November', bB: 'October', bR: 'September', bT: 'Start'};
var $rtfeldman$elm_css$Css$flex = $rtfeldman$elm_css$Css$prop1('flex');
var $rtfeldman$elm_css$Css$flexDirection = $rtfeldman$elm_css$Css$prop1('flex-direction');
var $author$project$Component$DateRange$fr = {a_: 'avril', a$: 'août', a6: 'Dates à choisir', a7: ' jours', a8: 'décembre', ba: 'Durée', bb: 'Fin', bd: 'février', bq: 'janvier', br: 'juillet', bs: 'juin', bu: 'mars', bv: 'mai', by: 'novembre', bB: 'octobre', bR: 'septembre', bT: 'Début'};
var $rtfeldman$elm_css$VirtualDom$Styled$unstyledNode = $rtfeldman$elm_css$VirtualDom$Styled$Unstyled;
var $rtfeldman$elm_css$Html$Styled$fromUnstyled = $rtfeldman$elm_css$VirtualDom$Styled$unstyledNode;
var $rtfeldman$elm_css$Css$left = $rtfeldman$elm_css$Css$prop1('left');
var $rtfeldman$elm_css$Html$Styled$span = $rtfeldman$elm_css$Html$Styled$node('span');
var $author$project$Component$DateRange$label = F2(
	function (name, value) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$displayFlex,
							$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$left)
						]))
				]),
			_List_fromArray(
				[
					A2(
					$author$project$S$label,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(name + ' : ')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$span,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$left),
									$rtfeldman$elm_css$Css$marginLeft(
									$rtfeldman$elm_css$Css$rem(1))
								]))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(value)
						]))
				]));
	});
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $elm$html$Html$div = _VirtualDom_node('div');
var $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$ResetVisualSelection = {$: 4};
var $PanagiotisGeorgiadis$elm_datepicker$Icons$Right = 3;
var $PanagiotisGeorgiadis$elm_datepicker$Icons$Size = F2(
	function (width, height) {
		return {bj: height, b1: width};
	});
var $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$SelectDate = function (a) {
	return {$: 2, a: a};
};
var $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$UpdateVisualSelection = function (a) {
	return {$: 3, a: a};
};
var $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$View$checkIfDisabled = F2(
	function (_v0, date) {
		var dateLimit = _v0.dz;
		var isLesserThanDate = function (date_) {
			return !A2($PanagiotisGeorgiadis$elm_datetime$DateTime$compareDates, date, date_);
		};
		var isGreaterThanDate = function (date_) {
			return A2($PanagiotisGeorgiadis$elm_datetime$DateTime$compareDates, date, date_) === 2;
		};
		if (dateLimit.$ === 1) {
			return false;
		} else {
			var minDate = dateLimit.a.dS;
			var maxDate = dateLimit.a.dQ;
			return isLesserThanDate(minDate) || isGreaterThanDate(maxDate);
		}
	});
var $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$View$checkIfInvalid = F2(
	function (_v0, date) {
		var dateRangeOffset = _v0.dA;
		if (!dateRangeOffset.$) {
			var invalidDates = dateRangeOffset.a.dO;
			return A2(
				$elm$core$List$any,
				function (d) {
					return A2($PanagiotisGeorgiadis$elm_datetime$DateTime$compareDates, date, d) === 1;
				},
				invalidDates);
		} else {
			return false;
		}
	});
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $elm$html$Html$Attributes$classList = function (classes) {
	return $elm$html$Html$Attributes$class(
		A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$first,
				A2($elm$core$List$filter, $elm$core$Tuple$second, classes))));
};
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$html$Html$Events$onMouseOver = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'mouseover',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$html$Html$span = _VirtualDom_node('span');
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $elm$html$Html$Attributes$title = $elm$html$Html$Attributes$stringProperty('title');
var $PanagiotisGeorgiadis$elm_datepicker$DatePicker$I18n$Condensed = 0;
var $elm$time$Time$Fri = 4;
var $elm$time$Time$Mon = 0;
var $elm$time$Time$Sat = 5;
var $elm$time$Time$Thu = 3;
var $elm$time$Time$Tue = 1;
var $elm$time$Time$Wed = 2;
var $elm$time$Time$toWeekday = F2(
	function (zone, time) {
		var _v0 = A2(
			$elm$core$Basics$modBy,
			7,
			A2(
				$elm$time$Time$flooredDiv,
				A2($elm$time$Time$toAdjustedMinutes, zone, time),
				60 * 24));
		switch (_v0) {
			case 0:
				return 3;
			case 1:
				return 4;
			case 2:
				return 5;
			case 3:
				return 6;
			case 4:
				return 0;
			case 5:
				return 1;
			default:
				return 2;
		}
	});
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$getWeekday = function (date) {
	return A2(
		$elm$time$Time$toWeekday,
		$elm$time$Time$utc,
		$PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$toPosix(date));
};
var $PanagiotisGeorgiadis$elm_datetime$Calendar$getWeekday = $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$getWeekday;
var $PanagiotisGeorgiadis$elm_datetime$DateTime$Internal$getWeekday = function (_v0) {
	var dateTime = _v0;
	return $PanagiotisGeorgiadis$elm_datetime$Calendar$getWeekday(dateTime.cJ);
};
var $PanagiotisGeorgiadis$elm_datetime$DateTime$getWeekday = $PanagiotisGeorgiadis$elm_datetime$DateTime$Internal$getWeekday;
var $PanagiotisGeorgiadis$elm_datepicker$Utils$Time$toHumanReadableDate = F2(
	function (i18n, dateTime) {
		return A2(
			$elm$core$String$join,
			' ',
			_List_fromArray(
				[
					A2(
					i18n.ee,
					0,
					$PanagiotisGeorgiadis$elm_datetime$DateTime$getWeekday(dateTime)),
					$elm$core$String$fromInt(
					$PanagiotisGeorgiadis$elm_datetime$DateTime$getDay(dateTime)),
					A2(
					i18n.dV,
					0,
					$PanagiotisGeorgiadis$elm_datetime$DateTime$getMonth(dateTime)),
					$elm$core$String$fromInt(
					$PanagiotisGeorgiadis$elm_datetime$DateTime$getYear(dateTime))
				]));
	});
var $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$View$dateHtml = F2(
	function (model, date) {
		var today = model.d8;
		var range = model.d;
		var i18n = model.dL;
		var isLesserThanDate = function (date_) {
			return !A2($PanagiotisGeorgiadis$elm_datetime$DateTime$compareDates, date, date_);
		};
		var isInvalid = A2($PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$View$checkIfInvalid, model, date);
		var isGreaterThanDate = function (date_) {
			return A2($PanagiotisGeorgiadis$elm_datetime$DateTime$compareDates, date, date_) === 2;
		};
		var isPartOfTheDateRange = function () {
			var isDateBetween = F2(
				function (start, end) {
					return (isGreaterThanDate(start) && isLesserThanDate(end)) || (isLesserThanDate(start) && isGreaterThanDate(end));
				});
			if (range.$ === 2) {
				if (!range.a.$) {
					var _v8 = range.a;
					var start = _v8.a;
					var shadowEnd = _v8.b;
					return A2(isDateBetween, start, shadowEnd);
				} else {
					var _v9 = range.a;
					var start = _v9.a;
					var end = _v9.b;
					return A2(isDateBetween, start, end);
				}
			} else {
				return false;
			}
		}();
		var isEqualToDate = function (date_) {
			return A2($PanagiotisGeorgiadis$elm_datetime$DateTime$compareDates, date, date_) === 1;
		};
		var isToday = isEqualToDate(today);
		var isDisabled = A2($PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$View$checkIfDisabled, model, date);
		if (isDisabled || isInvalid) {
			return A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('date', true),
								_Utils_Tuple2('today', isToday),
								_Utils_Tuple2('disabled', isDisabled),
								_Utils_Tuple2('invalid', isInvalid),
								_Utils_Tuple2('date-range', isPartOfTheDateRange)
							])),
						$elm$html$Html$Attributes$title(
						A2($PanagiotisGeorgiadis$elm_datepicker$Utils$Time$toHumanReadableDate, i18n, date))
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('date-inner')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								$elm$core$String$fromInt(
									$PanagiotisGeorgiadis$elm_datetime$DateTime$getDay(date)))
							]))
					]));
		} else {
			var isSelected = function () {
				if (range.$ === 1) {
					var start = range.a;
					return isEqualToDate(start);
				} else {
					return false;
				}
			}();
			var _v0 = function () {
				if (range.$ === 2) {
					if (!range.a.$) {
						var _v2 = range.a;
						var start = _v2.a;
						var end = _v2.b;
						var _v3 = function () {
							var _v4 = A2($PanagiotisGeorgiadis$elm_datetime$DateTime$compareDates, start, end);
							if (!_v4) {
								return _Utils_Tuple2(start, end);
							} else {
								return _Utils_Tuple2(end, start);
							}
						}();
						var start_ = _v3.a;
						var end_ = _v3.b;
						return isEqualToDate(start_) ? _Utils_Tuple2(true, false) : (isEqualToDate(end_) ? _Utils_Tuple2(false, true) : _Utils_Tuple2(false, false));
					} else {
						var _v5 = range.a;
						var start = _v5.a;
						var end = _v5.b;
						return _Utils_Tuple2(
							isEqualToDate(start),
							isEqualToDate(end));
					}
				} else {
					return _Utils_Tuple2(false, false);
				}
			}();
			var isStart = _v0.a;
			var isEnd = _v0.b;
			var dateClassList = _List_fromArray(
				[
					_Utils_Tuple2('date', true),
					_Utils_Tuple2('today', isToday),
					_Utils_Tuple2('selected', isSelected || (isStart || isEnd)),
					_Utils_Tuple2('date-range', isPartOfTheDateRange),
					_Utils_Tuple2('date-range-start', isStart),
					_Utils_Tuple2('date-range-end', isEnd)
				]);
			return A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$classList(dateClassList),
						$elm$html$Html$Attributes$title(
						A2($PanagiotisGeorgiadis$elm_datepicker$Utils$Time$toHumanReadableDate, i18n, date)),
						$elm$html$Html$Events$onClick(
						$PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$SelectDate(date)),
						$elm$html$Html$Events$onMouseOver(
						$PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$UpdateVisualSelection(date))
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('date-inner')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								$elm$core$String$fromInt(
									$PanagiotisGeorgiadis$elm_datetime$DateTime$getDay(date)))
							]))
					]));
		}
	});
var $PanagiotisGeorgiadis$elm_datepicker$Common$emptyDateHtml = A2(
	$elm$html$Html$span,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('empty-date')
		]),
	_List_Nil);
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$getDatesInMonth = function (_v0) {
	var year = _v0.dr;
	var month = _v0.cY;
	var lastDayOfTheMonth = $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$dayToInt(
		A2($PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$lastDayOf, year, month));
	return A2(
		$elm$core$List$map,
		function (day) {
			return {cK: day, cY: month, dr: year};
		},
		A2($elm$core$List$range, 1, lastDayOfTheMonth));
};
var $PanagiotisGeorgiadis$elm_datetime$Calendar$getDatesInMonth = $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$getDatesInMonth;
var $PanagiotisGeorgiadis$elm_datetime$DateTime$Internal$getDatesInMonth = function (_v0) {
	var date = _v0.cJ;
	var time = _v0.d6;
	return A2(
		$elm$core$List$map,
		function (date_) {
			return {cJ: date_, d6: time};
		},
		$PanagiotisGeorgiadis$elm_datetime$Calendar$getDatesInMonth(date));
};
var $PanagiotisGeorgiadis$elm_datetime$DateTime$getDatesInMonth = $PanagiotisGeorgiadis$elm_datetime$DateTime$Internal$getDatesInMonth;
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$setDay = F2(
	function (day, date) {
		return $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$fromRawParts(
			{
				cK: day,
				cY: $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$getMonth(date),
				dr: $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$yearToInt(
					$PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$getYear(date))
			});
	});
var $PanagiotisGeorgiadis$elm_datetime$Calendar$setDay = $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$setDay;
var $PanagiotisGeorgiadis$elm_datetime$DateTime$Internal$setDay = F2(
	function (day, dateTime) {
		return A2(
			$elm$core$Maybe$map,
			function (d) {
				return A2($PanagiotisGeorgiadis$elm_datetime$DateTime$Internal$setDate, d, dateTime);
			},
			A2(
				$PanagiotisGeorgiadis$elm_datetime$Calendar$setDay,
				day,
				$PanagiotisGeorgiadis$elm_datetime$DateTime$Internal$getDate(dateTime)));
	});
var $PanagiotisGeorgiadis$elm_datetime$DateTime$setDay = $PanagiotisGeorgiadis$elm_datetime$DateTime$Internal$setDay;
var $PanagiotisGeorgiadis$elm_datepicker$Common$getFirstDayOfTheMonth = $PanagiotisGeorgiadis$elm_datetime$DateTime$setDay(1);
var $PanagiotisGeorgiadis$elm_datepicker$Utils$Time$Matched = function (a) {
	return {$: 1, a: a};
};
var $PanagiotisGeorgiadis$elm_datepicker$Utils$Time$Searching = function (a) {
	return {$: 0, a: a};
};
var $PanagiotisGeorgiadis$elm_datepicker$Common$getSortedWeekdays = function (startingWeekday) {
	switch (startingWeekday) {
		case 6:
			return _List_fromArray(
				[6, 0, 1, 2, 3, 4, 5]);
		case 0:
			return _List_fromArray(
				[0, 1, 2, 3, 4, 5, 6]);
		case 1:
			return _List_fromArray(
				[1, 2, 3, 4, 5, 6, 0]);
		case 2:
			return _List_fromArray(
				[2, 3, 4, 5, 6, 0, 1]);
		case 3:
			return _List_fromArray(
				[3, 4, 5, 6, 0, 1, 2]);
		case 4:
			return _List_fromArray(
				[4, 5, 6, 0, 1, 2, 3]);
		default:
			return _List_fromArray(
				[5, 6, 0, 1, 2, 3, 4]);
	}
};
var $PanagiotisGeorgiadis$elm_datepicker$Utils$Time$precedingWeekdays = F2(
	function (firstDayOfTheMonth, startingWeekday) {
		var firstWeekdayOfTheMonth = $PanagiotisGeorgiadis$elm_datetime$DateTime$getWeekday(firstDayOfTheMonth);
		var precedingWeekdaysMatch = A3(
			$elm$core$List$foldl,
			F2(
				function (weekday, res) {
					if (res.$ === 1) {
						var c = res.a;
						return $PanagiotisGeorgiadis$elm_datepicker$Utils$Time$Matched(c);
					} else {
						var c = res.a;
						return _Utils_eq(weekday, firstWeekdayOfTheMonth) ? $PanagiotisGeorgiadis$elm_datepicker$Utils$Time$Matched(c) : $PanagiotisGeorgiadis$elm_datepicker$Utils$Time$Searching(c + 1);
					}
				}),
			$PanagiotisGeorgiadis$elm_datepicker$Utils$Time$Searching(0),
			$PanagiotisGeorgiadis$elm_datepicker$Common$getSortedWeekdays(startingWeekday));
		if (!precedingWeekdaysMatch.$) {
			return 0;
		} else {
			var c = precedingWeekdaysMatch.a;
			return c;
		}
	});
var $elm$core$List$repeatHelp = F3(
	function (result, n, value) {
		repeatHelp:
		while (true) {
			if (n <= 0) {
				return result;
			} else {
				var $temp$result = A2($elm$core$List$cons, value, result),
					$temp$n = n - 1,
					$temp$value = value;
				result = $temp$result;
				n = $temp$n;
				value = $temp$value;
				continue repeatHelp;
			}
		}
	});
var $elm$core$List$repeat = F2(
	function (n, value) {
		return A3($elm$core$List$repeatHelp, _List_Nil, n, value);
	});
var $PanagiotisGeorgiadis$elm_datepicker$Common$totalCalendarCells = 6 * 7;
var $PanagiotisGeorgiadis$elm_datepicker$Common$weekdaysHtml = F2(
	function (weekday, i18n) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('weekdays')
				]),
			A2(
				$elm$core$List$map,
				function (w) {
					return A2(
						$elm$html$Html$span,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								A2(i18n.ee, 0, w))
							]));
				},
				$PanagiotisGeorgiadis$elm_datepicker$Common$getSortedWeekdays(weekday)));
	});
var $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$View$calendarView = function (model) {
	var primaryDate = model.d0;
	var i18n = model.dL;
	var startingWeekday = model.d4;
	var precedingWeekdaysCount = function () {
		var _v0 = $PanagiotisGeorgiadis$elm_datepicker$Common$getFirstDayOfTheMonth(primaryDate);
		if (!_v0.$) {
			var firstDayOfTheMonth = _v0.a;
			return A2($PanagiotisGeorgiadis$elm_datepicker$Utils$Time$precedingWeekdays, firstDayOfTheMonth, startingWeekday);
		} else {
			return 0;
		}
	}();
	var precedingDatesHtml = A2($elm$core$List$repeat, precedingWeekdaysCount, $PanagiotisGeorgiadis$elm_datepicker$Common$emptyDateHtml);
	var monthDates = $PanagiotisGeorgiadis$elm_datetime$DateTime$getDatesInMonth(primaryDate);
	var followingDates = ($PanagiotisGeorgiadis$elm_datepicker$Common$totalCalendarCells - precedingWeekdaysCount) - $elm$core$List$length(monthDates);
	var followingDatesHtml = A2($elm$core$List$repeat, followingDates, $PanagiotisGeorgiadis$elm_datepicker$Common$emptyDateHtml);
	var datesHtml = A2(
		$elm$core$List$map,
		$PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$View$dateHtml(model),
		monthDates);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('calendar')
			]),
		_List_fromArray(
			[
				A2($PanagiotisGeorgiadis$elm_datepicker$Common$weekdaysHtml, startingWeekday, i18n),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('calendar_')
					]),
				_Utils_ap(
					precedingDatesHtml,
					_Utils_ap(datesHtml, followingDatesHtml)))
			]));
};
var $elm$svg$Svg$Attributes$height = _VirtualDom_attribute('height');
var $elm$json$Json$Encode$null = _Json_encodeNull;
var $elm$html$Html$Attributes$property = $elm$virtual_dom$VirtualDom$property;
var $PanagiotisGeorgiadis$elm_datepicker$Utils$Html$Attributes$none = A2($elm$html$Html$Attributes$property, '', $elm$json$Json$Encode$null);
var $elm$svg$Svg$Attributes$points = _VirtualDom_attribute('points');
var $elm$svg$Svg$trustedNode = _VirtualDom_nodeNS('http://www.w3.org/2000/svg');
var $elm$svg$Svg$polygon = $elm$svg$Svg$trustedNode('polygon');
var $elm$svg$Svg$svg = $elm$svg$Svg$trustedNode('svg');
var $elm$svg$Svg$Attributes$transform = _VirtualDom_attribute('transform');
var $elm$svg$Svg$Attributes$viewBox = _VirtualDom_attribute('viewBox');
var $elm$svg$Svg$Attributes$width = _VirtualDom_attribute('width');
var $PanagiotisGeorgiadis$elm_datepicker$Icons$chevron = F2(
	function (direction, size) {
		return A2(
			$elm$svg$Svg$svg,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$width(size.b1),
					$elm$svg$Svg$Attributes$height(size.bj),
					$elm$svg$Svg$Attributes$viewBox('0 0 24 24')
				]),
			_List_fromArray(
				[
					A2(
					$elm$svg$Svg$polygon,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$points('2.82 4.59 12 13.75 21.18 4.59 24 7.41 12 19.41 0 7.41'),
							function () {
							switch (direction) {
								case 0:
									return $elm$svg$Svg$Attributes$transform('translate(12.000000, 12.000000) rotate(180.000000) translate(-12.000000, -12.000000)');
								case 1:
									return $PanagiotisGeorgiadis$elm_datepicker$Utils$Html$Attributes$none;
								case 2:
									return $elm$svg$Svg$Attributes$transform('translate(12.000000, 12.000000) rotate(90.000000) translate(-12.000000, -12.000000)');
								default:
									return $elm$svg$Svg$Attributes$transform('translate(12.000000, 12.000000) scale(-1, 1) rotate(90.000000) translate(-12.000000, -12.000000)');
							}
						}()
						]),
					_List_Nil)
				]));
	});
var $PanagiotisGeorgiadis$elm_datepicker$Icons$Left = 2;
var $PanagiotisGeorgiadis$elm_datepicker$DatePicker$I18n$Full = 1;
var $PanagiotisGeorgiadis$elm_datepicker$MonthPicker$monthPickerText = F2(
	function (i18n, date) {
		var _v0 = _Utils_Tuple2(
			$PanagiotisGeorgiadis$elm_datetime$DateTime$getMonth(date),
			$PanagiotisGeorgiadis$elm_datetime$DateTime$getYear(date));
		var month = _v0.a;
		var year = _v0.b;
		return A2(i18n.dV, 1, month) + (' ' + $elm$core$String$fromInt(year));
	});
var $PanagiotisGeorgiadis$elm_datepicker$MonthPicker$todayButtonHtml = F2(
	function (todayButtonText, msg) {
		if (!msg.$) {
			var m = msg.a;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('today-button'),
						$elm$html$Html$Events$onClick(m)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(todayButtonText)
					]));
		} else {
			return $elm$html$Html$text('');
		}
	});
var $PanagiotisGeorgiadis$elm_datepicker$Icons$triangle = F2(
	function (direction, size) {
		return A2(
			$elm$svg$Svg$svg,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$width(size.b1),
					$elm$svg$Svg$Attributes$height(size.bj),
					$elm$svg$Svg$Attributes$viewBox('0 0 24 24')
				]),
			_List_fromArray(
				[
					A2(
					$elm$svg$Svg$polygon,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$points('0 6 12 18 24 6'),
							function () {
							switch (direction) {
								case 1:
									return $PanagiotisGeorgiadis$elm_datepicker$Utils$Html$Attributes$none;
								case 0:
									return $elm$svg$Svg$Attributes$transform('translate(12.000000, 12.000000) rotate(180.000000) translate(-12.000000, -12.000000)');
								case 2:
									return $elm$svg$Svg$Attributes$transform('translate(12.000000, 12.000000) rotate(90.000000) translate(-12.000000, -12.000000)');
								default:
									return $elm$svg$Svg$Attributes$transform('translate(12.000000, 12.000000) scale(-1, 1) rotate(90.000000) translate(-12.000000, -12.000000)');
							}
						}()
						]),
					_List_Nil)
				]));
	});
var $PanagiotisGeorgiadis$elm_datepicker$MonthPicker$doubleMonthPickerView = function (_v0) {
	var date = _v0.cJ;
	var previousButtonHandler = _v0.c5;
	var nextButtonHandler = _v0.c_;
	var todayButtonHandler = _v0.dl;
	var i18n = _v0.dL;
	var previousButtonHtml = function () {
		if (!previousButtonHandler.$) {
			var action = previousButtonHandler.a;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('action'),
						$elm$html$Html$Events$onClick(action)
					]),
				_List_fromArray(
					[
						A2(
						$PanagiotisGeorgiadis$elm_datepicker$Icons$triangle,
						2,
						A2($PanagiotisGeorgiadis$elm_datepicker$Icons$Size, '15', '15'))
					]));
		} else {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('action disabled')
					]),
				_List_fromArray(
					[
						A2(
						$PanagiotisGeorgiadis$elm_datepicker$Icons$triangle,
						2,
						A2($PanagiotisGeorgiadis$elm_datepicker$Icons$Size, '15', '15'))
					]));
		}
	}();
	var nextMonthDate = $PanagiotisGeorgiadis$elm_datetime$DateTime$incrementMonth(date);
	var nextButtonHtml = function () {
		if (!nextButtonHandler.$) {
			var action = nextButtonHandler.a;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('action'),
						$elm$html$Html$Events$onClick(action)
					]),
				_List_fromArray(
					[
						A2(
						$PanagiotisGeorgiadis$elm_datepicker$Icons$triangle,
						3,
						A2($PanagiotisGeorgiadis$elm_datepicker$Icons$Size, '15', '15'))
					]));
		} else {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('action disabled')
					]),
				_List_fromArray(
					[
						A2(
						$PanagiotisGeorgiadis$elm_datepicker$Icons$triangle,
						3,
						A2($PanagiotisGeorgiadis$elm_datepicker$Icons$Size, '15', '15'))
					]));
		}
	}();
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('double-month-picker')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('previous-month')
					]),
				_List_fromArray(
					[
						previousButtonHtml,
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('month-name')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								A2($PanagiotisGeorgiadis$elm_datepicker$MonthPicker$monthPickerText, i18n, date))
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('next-month')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('month-name')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								A2($PanagiotisGeorgiadis$elm_datepicker$MonthPicker$monthPickerText, i18n, nextMonthDate))
							])),
						nextButtonHtml
					])),
				A2($PanagiotisGeorgiadis$elm_datepicker$MonthPicker$todayButtonHtml, i18n.d9, todayButtonHandler)
			]));
};
var $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$NextMonth = {$: 1};
var $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$View$getNextButtonAction = function (isButtonActive) {
	return isButtonActive ? $elm$core$Maybe$Just($PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$NextMonth) : $elm$core$Maybe$Nothing;
};
var $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$PreviousMonth = {$: 0};
var $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$View$getPreviousButtonAction = function (isButtonActive) {
	return isButtonActive ? $elm$core$Maybe$Just($PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$PreviousMonth) : $elm$core$Maybe$Nothing;
};
var $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$MoveToToday = {$: 11};
var $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$View$getTodayButtonAction = function (isButtonActive) {
	return isButtonActive ? $elm$core$Maybe$Just($PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$MoveToToday) : $elm$core$Maybe$Nothing;
};
var $elm$html$Html$Events$onMouseLeave = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'mouseleave',
		$elm$json$Json$Decode$succeed(msg));
};
var $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$updatePrimaryDate = F2(
	function (dt, _v0) {
		var model = _v0;
		return _Utils_update(
			model,
			{d0: dt});
	});
var $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$View$doubleCalendarView = function (model) {
	var today = model.d8;
	var primaryDate = model.d0;
	var dateLimit = model.dz;
	var range = model.d;
	var timePickers = model.u;
	var i18n = model.dL;
	var switchViewButton = function () {
		if ((range.$ === 2) && (range.a.$ === 1)) {
			var _v4 = range.a;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('switch-view-button'),
						$elm$html$Html$Events$onClick($PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$ShowClockView)
					]),
				_List_fromArray(
					[
						A2(
						$PanagiotisGeorgiadis$elm_datepicker$Icons$chevron,
						3,
						A2($PanagiotisGeorgiadis$elm_datepicker$Icons$Size, '20', '20'))
					]));
		} else {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('switch-view-button disabled')
					]),
				_List_fromArray(
					[
						A2(
						$PanagiotisGeorgiadis$elm_datepicker$Icons$chevron,
						3,
						A2($PanagiotisGeorgiadis$elm_datepicker$Icons$Size, '20', '20'))
					]));
		}
	}();
	var nextDate = $PanagiotisGeorgiadis$elm_datetime$DateTime$incrementMonth(primaryDate);
	var nextModel = A2($PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$updatePrimaryDate, nextDate, model);
	var _v0 = function () {
		if (!dateLimit.$) {
			var minDate = dateLimit.a.dS;
			var maxDate = dateLimit.a.dQ;
			return _Utils_Tuple3(
				!A2($PanagiotisGeorgiadis$elm_datepicker$Utils$DateTime$compareYearMonth, minDate, primaryDate),
				A2($PanagiotisGeorgiadis$elm_datepicker$Utils$DateTime$compareYearMonth, maxDate, nextDate) === 2,
				(!A2($PanagiotisGeorgiadis$elm_datepicker$Utils$DateTime$compareYearMonth, minDate, today)) && (A2($PanagiotisGeorgiadis$elm_datepicker$Utils$DateTime$compareYearMonth, maxDate, today) === 2));
		} else {
			return _Utils_Tuple3(true, true, true);
		}
	}();
	var isPreviousButtonActive = _v0.a;
	var isNextButtonActive = _v0.b;
	var isTodayButtonActive = _v0.c;
	var pickerConfig = {
		cJ: primaryDate,
		dL: i18n,
		c_: $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$View$getNextButtonAction(isNextButtonActive),
		c5: $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$View$getPreviousButtonAction(isPreviousButtonActive),
		dl: $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$View$getTodayButtonAction(isTodayButtonActive)
	};
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('double-calendar-view no-select'),
				$elm$html$Html$Events$onMouseLeave($PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$ResetVisualSelection)
			]),
		_List_fromArray(
			[
				$PanagiotisGeorgiadis$elm_datepicker$MonthPicker$doubleMonthPickerView(pickerConfig),
				$PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$View$calendarView(model),
				$PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$View$calendarView(nextModel),
				function () {
				if (!timePickers.$) {
					return $elm$html$Html$text('');
				} else {
					return switchViewButton;
				}
			}()
			]));
};
var $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$ShowCalendarView = {$: 6};
var $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$ToggleTimeMirroring = {$: 7};
var $elm$svg$Svg$Attributes$d = _VirtualDom_attribute('d');
var $elm$svg$Svg$path = $elm$svg$Svg$trustedNode('path');
var $PanagiotisGeorgiadis$elm_datepicker$Icons$blankCheckboxPath = A2(
	$elm$svg$Svg$path,
	_List_fromArray(
		[
			$elm$svg$Svg$Attributes$d('M2.66666667,0 C1.2092496,0 0,1.2092496 0,2.66666667 L0,21.3333333 C0,22.7907507 1.2092496,24 2.66666667,24 L21.3333333,24 C22.7907507,24 24,22.7907507 24,21.3333333 L24,2.66666667 C24,1.2092496 22.7907507,0 21.3333333,0 L2.66666667,0 Z M2,2 L22,2 L22,22 L2,22 L2,2 Z')
		]),
	_List_Nil);
var $PanagiotisGeorgiadis$elm_datepicker$Icons$tickedCheckboxPath = A2(
	$elm$svg$Svg$path,
	_List_fromArray(
		[
			$elm$svg$Svg$Attributes$d('M21.3333333,0 L2.66666667,0 C1.19333333,0 0,1.19333333 0,2.66666667 L0,21.3333333 C0,22.8066667 1.19333333,24 2.66666667,24 L21.3333333,24 C22.8066667,24 24,22.8066667 24,21.3333333 L24,2.66666667 C24,1.19333333 22.8066667,0 21.3333333,0 Z M10.276,18.276 C9.75466667,18.7973333 8.91066667,18.7973333 8.39066667,18.276 L4,13.8853333 C3.48,13.3653333 3.48,12.52 4,12 C4.52,11.48 5.36533333,11.48 5.88533333,12 L9.33333333,15.448 L18.1146667,6.66666667 C18.6346667,6.14666667 19.48,6.14666667 20,6.66666667 C20.52,7.18666667 20.52,8.032 20,8.552 L10.276,18.276 Z')
		]),
	_List_Nil);
var $PanagiotisGeorgiadis$elm_datepicker$Icons$checkbox = F2(
	function (size, isChecked) {
		return A2(
			$elm$svg$Svg$svg,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$width(size.b1),
					$elm$svg$Svg$Attributes$height(size.bj),
					$elm$svg$Svg$Attributes$viewBox('0 0 24 24')
				]),
			_List_fromArray(
				[
					isChecked ? $PanagiotisGeorgiadis$elm_datepicker$Icons$tickedCheckboxPath : $PanagiotisGeorgiadis$elm_datepicker$Icons$blankCheckboxPath
				]));
	});
var $PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$getPickerTypeString = function (_v0) {
	var pickerType = _v0.c2;
	switch (pickerType.$) {
		case 0:
			return 'hh';
		case 1:
			return 'hh_mm';
		case 2:
			return 'hh_mm_ss';
		default:
			return 'hh_mm_ss_mmmm';
	}
};
var $elm$html$Html$map = $elm$virtual_dom$VirtualDom$map;
var $PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$toHumanReadableTime = function (_v0) {
	var pickerType = _v0.c2;
	var hours = _v0.cR;
	var minutes = _v0.cX;
	var seconds = _v0.de;
	var milliseconds = _v0.cW;
	switch (pickerType.$) {
		case 0:
			return hours;
		case 1:
			return A2(
				$elm$core$String$join,
				':',
				_List_fromArray(
					[hours, minutes]));
		case 2:
			return A2(
				$elm$core$String$join,
				':',
				_List_fromArray(
					[hours, minutes, seconds]));
		default:
			return A2(
				$elm$core$String$join,
				'.',
				_List_fromArray(
					[
						A2(
						$elm$core$String$join,
						':',
						_List_fromArray(
							[hours, minutes, seconds])),
						milliseconds
					]));
	}
};
var $PanagiotisGeorgiadis$elm_datepicker$TimePicker$Internal$Update$Decrement = function (a) {
	return {$: 3, a: a};
};
var $PanagiotisGeorgiadis$elm_datepicker$Icons$Down = 1;
var $PanagiotisGeorgiadis$elm_datepicker$TimePicker$Internal$Update$Increment = function (a) {
	return {$: 2, a: a};
};
var $PanagiotisGeorgiadis$elm_datepicker$TimePicker$Internal$Update$InputHandler = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $PanagiotisGeorgiadis$elm_datepicker$Icons$Up = 0;
var $PanagiotisGeorgiadis$elm_datepicker$TimePicker$Internal$Update$Update = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$html$Html$input = _VirtualDom_node('input');
var $elm$html$Html$Attributes$maxlength = function (n) {
	return A2(
		_VirtualDom_attribute,
		'maxlength',
		$elm$core$String$fromInt(n));
};
var $elm$html$Html$Events$onBlur = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'blur',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$html$Html$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $elm$html$Html$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $elm$html$Html$Events$onInput = function (tagger) {
	return A2(
		$elm$html$Html$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue)));
};
var $elm$html$Html$Attributes$value = $elm$html$Html$Attributes$stringProperty('value');
var $PanagiotisGeorgiadis$elm_datepicker$TimePicker$Internal$View$hourPicker = function (_v0) {
	var hours = _v0.cR;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('hours-picker')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('button'),
						$elm$html$Html$Events$onClick(
						$PanagiotisGeorgiadis$elm_datepicker$TimePicker$Internal$Update$Increment(0))
					]),
				_List_fromArray(
					[
						A2(
						$PanagiotisGeorgiadis$elm_datepicker$Icons$chevron,
						0,
						A2($PanagiotisGeorgiadis$elm_datepicker$Icons$Size, '24', '24'))
					])),
				A2(
				$elm$html$Html$input,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('time-input'),
						$elm$html$Html$Events$onInput(
						$PanagiotisGeorgiadis$elm_datepicker$TimePicker$Internal$Update$InputHandler(0)),
						$elm$html$Html$Events$onBlur(
						A2($PanagiotisGeorgiadis$elm_datepicker$TimePicker$Internal$Update$Update, 0, hours)),
						$elm$html$Html$Attributes$value(hours),
						$elm$html$Html$Attributes$maxlength(2)
					]),
				_List_Nil),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('button'),
						$elm$html$Html$Events$onClick(
						$PanagiotisGeorgiadis$elm_datepicker$TimePicker$Internal$Update$Decrement(0))
					]),
				_List_fromArray(
					[
						A2(
						$PanagiotisGeorgiadis$elm_datepicker$Icons$chevron,
						1,
						A2($PanagiotisGeorgiadis$elm_datepicker$Icons$Size, '24', '24'))
					]))
			]));
};
var $PanagiotisGeorgiadis$elm_datepicker$TimePicker$Internal$View$millisSegmentSeparator = A2(
	$elm$html$Html$span,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('time-separator no-select')
		]),
	_List_fromArray(
		[
			$elm$html$Html$text('.')
		]));
var $PanagiotisGeorgiadis$elm_datepicker$TimePicker$Internal$View$millisecondsPicker = function (_v0) {
	var milliseconds = _v0.cW;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('milliseconds-picker')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('button'),
						$elm$html$Html$Events$onClick(
						$PanagiotisGeorgiadis$elm_datepicker$TimePicker$Internal$Update$Increment(3))
					]),
				_List_fromArray(
					[
						A2(
						$PanagiotisGeorgiadis$elm_datepicker$Icons$chevron,
						0,
						A2($PanagiotisGeorgiadis$elm_datepicker$Icons$Size, '24', '24'))
					])),
				A2(
				$elm$html$Html$input,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('time-input'),
						$elm$html$Html$Events$onInput(
						$PanagiotisGeorgiadis$elm_datepicker$TimePicker$Internal$Update$InputHandler(3)),
						$elm$html$Html$Events$onBlur(
						A2($PanagiotisGeorgiadis$elm_datepicker$TimePicker$Internal$Update$Update, 3, milliseconds)),
						$elm$html$Html$Attributes$value(milliseconds),
						$elm$html$Html$Attributes$maxlength(3)
					]),
				_List_Nil),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('button'),
						$elm$html$Html$Events$onClick(
						$PanagiotisGeorgiadis$elm_datepicker$TimePicker$Internal$Update$Decrement(3))
					]),
				_List_fromArray(
					[
						A2(
						$PanagiotisGeorgiadis$elm_datepicker$Icons$chevron,
						1,
						A2($PanagiotisGeorgiadis$elm_datepicker$Icons$Size, '24', '24'))
					]))
			]));
};
var $PanagiotisGeorgiadis$elm_datepicker$TimePicker$Internal$View$minutePicker = function (_v0) {
	var minutes = _v0.cX;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('minutes-picker')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('button'),
						$elm$html$Html$Events$onClick(
						$PanagiotisGeorgiadis$elm_datepicker$TimePicker$Internal$Update$Increment(1))
					]),
				_List_fromArray(
					[
						A2(
						$PanagiotisGeorgiadis$elm_datepicker$Icons$chevron,
						0,
						A2($PanagiotisGeorgiadis$elm_datepicker$Icons$Size, '24', '24'))
					])),
				A2(
				$elm$html$Html$input,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('time-input'),
						$elm$html$Html$Events$onInput(
						$PanagiotisGeorgiadis$elm_datepicker$TimePicker$Internal$Update$InputHandler(1)),
						$elm$html$Html$Events$onBlur(
						A2($PanagiotisGeorgiadis$elm_datepicker$TimePicker$Internal$Update$Update, 1, minutes)),
						$elm$html$Html$Attributes$value(minutes),
						$elm$html$Html$Attributes$maxlength(2)
					]),
				_List_Nil),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('button'),
						$elm$html$Html$Events$onClick(
						$PanagiotisGeorgiadis$elm_datepicker$TimePicker$Internal$Update$Decrement(1))
					]),
				_List_fromArray(
					[
						A2(
						$PanagiotisGeorgiadis$elm_datepicker$Icons$chevron,
						1,
						A2($PanagiotisGeorgiadis$elm_datepicker$Icons$Size, '24', '24'))
					]))
			]));
};
var $PanagiotisGeorgiadis$elm_datepicker$TimePicker$Internal$View$secondsPicker = function (_v0) {
	var seconds = _v0.de;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('seconds-picker')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('button'),
						$elm$html$Html$Events$onClick(
						$PanagiotisGeorgiadis$elm_datepicker$TimePicker$Internal$Update$Increment(2))
					]),
				_List_fromArray(
					[
						A2(
						$PanagiotisGeorgiadis$elm_datepicker$Icons$chevron,
						0,
						A2($PanagiotisGeorgiadis$elm_datepicker$Icons$Size, '24', '24'))
					])),
				A2(
				$elm$html$Html$input,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('time-input'),
						$elm$html$Html$Events$onInput(
						$PanagiotisGeorgiadis$elm_datepicker$TimePicker$Internal$Update$InputHandler(2)),
						$elm$html$Html$Events$onBlur(
						A2($PanagiotisGeorgiadis$elm_datepicker$TimePicker$Internal$Update$Update, 2, seconds)),
						$elm$html$Html$Attributes$value(seconds),
						$elm$html$Html$Attributes$maxlength(2)
					]),
				_List_Nil),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('button'),
						$elm$html$Html$Events$onClick(
						$PanagiotisGeorgiadis$elm_datepicker$TimePicker$Internal$Update$Decrement(2))
					]),
				_List_fromArray(
					[
						A2(
						$PanagiotisGeorgiadis$elm_datepicker$Icons$chevron,
						1,
						A2($PanagiotisGeorgiadis$elm_datepicker$Icons$Size, '24', '24'))
					]))
			]));
};
var $PanagiotisGeorgiadis$elm_datepicker$TimePicker$Internal$View$timeSegmentSeparator = A2(
	$elm$html$Html$span,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('time-separator no-select')
		]),
	_List_fromArray(
		[
			$elm$html$Html$text(':')
		]));
var $PanagiotisGeorgiadis$elm_datepicker$TimePicker$Internal$View$view = function (model) {
	var pickerType = model.c2;
	switch (pickerType.$) {
		case 0:
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('time-picker')
					]),
				_List_fromArray(
					[
						$PanagiotisGeorgiadis$elm_datepicker$TimePicker$Internal$View$hourPicker(model)
					]));
		case 1:
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('time-picker')
					]),
				_List_fromArray(
					[
						$PanagiotisGeorgiadis$elm_datepicker$TimePicker$Internal$View$hourPicker(model),
						$PanagiotisGeorgiadis$elm_datepicker$TimePicker$Internal$View$timeSegmentSeparator,
						$PanagiotisGeorgiadis$elm_datepicker$TimePicker$Internal$View$minutePicker(model)
					]));
		case 2:
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('time-picker')
					]),
				_List_fromArray(
					[
						$PanagiotisGeorgiadis$elm_datepicker$TimePicker$Internal$View$hourPicker(model),
						$PanagiotisGeorgiadis$elm_datepicker$TimePicker$Internal$View$timeSegmentSeparator,
						$PanagiotisGeorgiadis$elm_datepicker$TimePicker$Internal$View$minutePicker(model),
						$PanagiotisGeorgiadis$elm_datepicker$TimePicker$Internal$View$timeSegmentSeparator,
						$PanagiotisGeorgiadis$elm_datepicker$TimePicker$Internal$View$secondsPicker(model)
					]));
		default:
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('time-picker')
					]),
				_List_fromArray(
					[
						$PanagiotisGeorgiadis$elm_datepicker$TimePicker$Internal$View$hourPicker(model),
						$PanagiotisGeorgiadis$elm_datepicker$TimePicker$Internal$View$timeSegmentSeparator,
						$PanagiotisGeorgiadis$elm_datepicker$TimePicker$Internal$View$minutePicker(model),
						$PanagiotisGeorgiadis$elm_datepicker$TimePicker$Internal$View$timeSegmentSeparator,
						$PanagiotisGeorgiadis$elm_datepicker$TimePicker$Internal$View$secondsPicker(model),
						$PanagiotisGeorgiadis$elm_datepicker$TimePicker$Internal$View$millisSegmentSeparator,
						$PanagiotisGeorgiadis$elm_datepicker$TimePicker$Internal$View$millisecondsPicker(model)
					]));
	}
};
var $PanagiotisGeorgiadis$elm_datepicker$TimePicker$View$view = $PanagiotisGeorgiadis$elm_datepicker$TimePicker$Internal$View$view;
var $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$View$doubleClockView = function (_v0) {
	var range = _v0.d;
	var timePickers = _v0.u;
	var viewType = _v0.ed;
	var i18n = _v0.dL;
	if (timePickers.$ === 1) {
		var timePickers_ = timePickers.a;
		var startPicker = timePickers_.M;
		var endPicker = timePickers_.P;
		var mirrorTimes = timePickers_.aH;
		var titleHtml = function (str) {
			return $elm$core$String$isEmpty(str) ? $elm$html$Html$text('') : A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('header')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(str)
					]));
		};
		var pickerTypeString = function () {
			if (!viewType) {
				return $PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$getPickerTypeString(startPicker);
			} else {
				return '';
			}
		}();
		var displayDateHtml = F2(
			function (date, timePicker) {
				if (!date.$) {
					var d = date.a;
					var dateTimeStr = A2(
						$elm$core$String$join,
						' ',
						_List_fromArray(
							[
								A2($PanagiotisGeorgiadis$elm_datepicker$Utils$Time$toHumanReadableDate, i18n, d),
								$PanagiotisGeorgiadis$elm_datepicker$TimePicker$Update$toHumanReadableTime(timePicker)
							]));
					return A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('date')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(dateTimeStr)
							]));
				} else {
					return A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('placeholder')
							]),
						_List_Nil);
				}
			});
		var _v2 = function () {
			if ((range.$ === 2) && (range.a.$ === 1)) {
				var _v4 = range.a;
				var start = _v4.a;
				var end = _v4.b;
				return _Utils_Tuple2(
					$elm$core$Maybe$Just(start),
					$elm$core$Maybe$Just(end));
			} else {
				return _Utils_Tuple2($elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing);
			}
		}();
		var rangeStart = _v2.a;
		var rangeEnd = _v2.b;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('double-clock-view ' + pickerTypeString)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('time-picker-container no-select')
						]),
					_List_fromArray(
						[
							titleHtml(timePickers_.dL.bT),
							A2(displayDateHtml, rangeStart, startPicker),
							A2(
							$elm$html$Html$map,
							$PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$RangeStartPickerMsg,
							$PanagiotisGeorgiadis$elm_datepicker$TimePicker$View$view(startPicker)),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('checkbox'),
									$elm$html$Html$Events$onClick($PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$ToggleTimeMirroring)
								]),
							_List_fromArray(
								[
									A2(
									$PanagiotisGeorgiadis$elm_datepicker$Icons$checkbox,
									A2($PanagiotisGeorgiadis$elm_datepicker$Icons$Size, '16', '16'),
									mirrorTimes),
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('text')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(timePickers_.dL.dy)
										]))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('time-picker-container no-select')
						]),
					_List_fromArray(
						[
							titleHtml(timePickers_.dL.bb),
							A2(displayDateHtml, rangeEnd, endPicker),
							A2(
							$elm$html$Html$map,
							$PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$RangeEndPickerMsg,
							$PanagiotisGeorgiadis$elm_datepicker$TimePicker$View$view(endPicker)),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('filler')
								]),
							_List_Nil)
						])),
					function () {
					if (viewType === 2) {
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('switch-view-button'),
									$elm$html$Html$Events$onClick($PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$ShowCalendarView)
								]),
							_List_fromArray(
								[
									A2(
									$PanagiotisGeorgiadis$elm_datepicker$Icons$chevron,
									2,
									A2($PanagiotisGeorgiadis$elm_datepicker$Icons$Size, '20', '20'))
								]));
					} else {
						return $elm$html$Html$text('');
					}
				}()
				]));
	} else {
		return $elm$html$Html$text('');
	}
};
var $PanagiotisGeorgiadis$elm_datepicker$MonthPicker$singleMonthPickerView = function (_v0) {
	var date = _v0.cJ;
	var previousButtonHandler = _v0.c5;
	var nextButtonHandler = _v0.c_;
	var todayButtonHandler = _v0.dl;
	var i18n = _v0.dL;
	var previousButtonHtml = function () {
		if (!previousButtonHandler.$) {
			var action = previousButtonHandler.a;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('action'),
						$elm$html$Html$Events$onClick(action)
					]),
				_List_fromArray(
					[
						A2(
						$PanagiotisGeorgiadis$elm_datepicker$Icons$triangle,
						2,
						A2($PanagiotisGeorgiadis$elm_datepicker$Icons$Size, '15', '15'))
					]));
		} else {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('action disabled')
					]),
				_List_fromArray(
					[
						A2(
						$PanagiotisGeorgiadis$elm_datepicker$Icons$triangle,
						2,
						A2($PanagiotisGeorgiadis$elm_datepicker$Icons$Size, '15', '15'))
					]));
		}
	}();
	var nextButtonHtml = function () {
		if (!nextButtonHandler.$) {
			var action = nextButtonHandler.a;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('action'),
						$elm$html$Html$Events$onClick(action)
					]),
				_List_fromArray(
					[
						A2(
						$PanagiotisGeorgiadis$elm_datepicker$Icons$triangle,
						3,
						A2($PanagiotisGeorgiadis$elm_datepicker$Icons$Size, '15', '15'))
					]));
		} else {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('action disabled')
					]),
				_List_fromArray(
					[
						A2(
						$PanagiotisGeorgiadis$elm_datepicker$Icons$triangle,
						3,
						A2($PanagiotisGeorgiadis$elm_datepicker$Icons$Size, '15', '15'))
					]));
		}
	}();
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('single-month-picker')
			]),
		_List_fromArray(
			[
				previousButtonHtml,
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('month-name')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						A2($PanagiotisGeorgiadis$elm_datepicker$MonthPicker$monthPickerText, i18n, date))
					])),
				nextButtonHtml,
				A2($PanagiotisGeorgiadis$elm_datepicker$MonthPicker$todayButtonHtml, i18n.d9, todayButtonHandler)
			]));
};
var $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$View$singleCalendarView = function (model) {
	var today = model.d8;
	var primaryDate = model.d0;
	var dateLimit = model.dz;
	var i18n = model.dL;
	var _v0 = function () {
		if (!dateLimit.$) {
			var minDate = dateLimit.a.dS;
			var maxDate = dateLimit.a.dQ;
			return _Utils_Tuple3(
				!A2($PanagiotisGeorgiadis$elm_datepicker$Utils$DateTime$compareYearMonth, minDate, primaryDate),
				A2($PanagiotisGeorgiadis$elm_datepicker$Utils$DateTime$compareYearMonth, maxDate, primaryDate) === 2,
				(!A2($PanagiotisGeorgiadis$elm_datepicker$Utils$DateTime$compareYearMonth, minDate, today)) && (A2($PanagiotisGeorgiadis$elm_datepicker$Utils$DateTime$compareYearMonth, maxDate, today) === 2));
		} else {
			return _Utils_Tuple3(true, true, true);
		}
	}();
	var isPreviousButtonActive = _v0.a;
	var isNextButtonActive = _v0.b;
	var isTodayButtonActive = _v0.c;
	var pickerConfig = {
		cJ: primaryDate,
		dL: i18n,
		c_: $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$View$getNextButtonAction(isNextButtonActive),
		c5: $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$View$getPreviousButtonAction(isPreviousButtonActive),
		dl: $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$View$getTodayButtonAction(isTodayButtonActive)
	};
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('single-calendar-view no-select'),
				$elm$html$Html$Events$onMouseLeave($PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$Update$ResetVisualSelection)
			]),
		_List_fromArray(
			[
				$PanagiotisGeorgiadis$elm_datepicker$MonthPicker$singleMonthPickerView(pickerConfig),
				$PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$View$calendarView(model)
			]));
};
var $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$View$view = function (model) {
	var viewType = model.ed;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('date-range-picker')
			]),
		function () {
			switch (viewType) {
				case 0:
					return _List_fromArray(
						[
							$PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$View$singleCalendarView(model),
							$PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$View$doubleClockView(model)
						]);
				case 1:
					return _List_fromArray(
						[
							$PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$View$doubleCalendarView(model)
						]);
				default:
					return _List_fromArray(
						[
							$PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$View$doubleClockView(model)
						]);
			}
		}());
};
var $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$view = $PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$Internal$View$view;
var $author$project$Component$DateRange$view = F2(
	function (language, model) {
		var translation = A2(
			$author$project$Language$getTranslation,
			language,
			{dG: $author$project$Component$DateRange$en, dI: $author$project$Component$DateRange$fr});
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$displayFlex,
							$rtfeldman$elm_css$Css$marginLeft(
							$rtfeldman$elm_css$Css$rem(1)),
							$rtfeldman$elm_css$Css$marginBottom(
							$rtfeldman$elm_css$Css$rem(1.5)),
							$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column)
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$marginBottom(
									$rtfeldman$elm_css$Css$rem(2))
								]))
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$map,
							$author$project$Component$DateRange$PickerMsg,
							$rtfeldman$elm_css$Html$Styled$fromUnstyled(
								$PanagiotisGeorgiadis$elm_datepicker$DateRangePicker$view(model.aL)))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$flex(
									$rtfeldman$elm_css$Css$int(1)),
									$rtfeldman$elm_css$Css$displayFlex,
									$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center)
								]))
						]),
					_List_fromArray(
						[
							function () {
							var _v0 = model.ap;
							if (!_v0.$) {
								var startDate = _v0.a.dh;
								var endDate = _v0.a.cL;
								return A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$author$project$Component$DateRange$label,
											translation.bT,
											A3($author$project$Component$DateRange$dayMonthYear, language, translation, startDate)),
											A2(
											$author$project$Component$DateRange$label,
											translation.bb,
											A3($author$project$Component$DateRange$dayMonthYear, language, translation, endDate)),
											A2(
											$author$project$Component$DateRange$label,
											translation.ba,
											_Utils_ap(
												$elm$core$String$fromInt(
													A2($PanagiotisGeorgiadis$elm_datetime$DateTime$getDayDiff, startDate, endDate)),
												translation.a7))
										]));
							} else {
								return A2(
									$rtfeldman$elm_css$Html$Styled$p,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(translation.a6)
										]));
							}
						}()
						]))
				]));
	});
var $author$project$ROI$View$timeSpared = F4(
	function (language, translation, numberOfGreenhouses, model) {
		var timeSavings = A2($author$project$ROI$View$calculateTimeSaving, numberOfGreenhouses, model);
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$author$project$Style$Grid$displayGrid,
							$author$project$Style$Grid$gridRowGap('1rem')
						]))
				]),
			_List_fromArray(
				[
					A2(
					$author$project$S$h4,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(translation.bZ)
						])),
					A2(
					$author$project$S$ul,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$author$project$S$li,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(translation.b3)
								])),
							A2(
							$author$project$S$li,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(translation.b0)
								]))
						])),
					A4($author$project$ROI$View$field, translation.bM, translation.aG, model.cp, $author$project$ROI$Update$RollupTime),
					A4($author$project$ROI$View$field, translation.cg, translation.aG, model.cg, $author$project$ROI$Update$IrrigationTime),
					A4($author$project$ROI$View$field, translation.cv, '$/h', model.cv, $author$project$ROI$Update$Wage),
					A2(
					$author$project$S$label,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(translation.bP)
						])),
					function () {
					var _v0 = model.a5;
					if (!_v0.$) {
						var subModel = _v0.a;
						return A2(
							$rtfeldman$elm_css$Html$Styled$map,
							$author$project$ROI$Update$DateRangeMsg,
							A2($author$project$Component$DateRange$view, language, subModel));
					} else {
						return $author$project$S$empty;
					}
				}(),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$fontSize(
									$rtfeldman$elm_css$Css$rem(1.2))
								]))
						]),
					_List_fromArray(
						[
							A3($author$project$ROI$View$resultLine, translation.bN, timeSavings, translation.A)
						]))
				]));
	});
var $rtfeldman$elm_css$Html$Styled$Attributes$href = function (url) {
	return A2($rtfeldman$elm_css$Html$Styled$Attributes$stringProperty, 'href', url);
};
var $rtfeldman$elm_css$Html$Styled$Attributes$target = $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('target');
var $rtfeldman$elm_css$Css$circle = {c: 0, b: 0, D: 'circle'};
var $author$project$Style$List$ul1 = function (attrs) {
	return $rtfeldman$elm_css$Html$Styled$ul(
		A2(
			$elm$core$List$cons,
			$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						$author$project$Style$Grid$displayGrid,
						$author$project$Style$Grid$gridRowGap('0.75em'),
						$rtfeldman$elm_css$Css$marginTop(
						$rtfeldman$elm_css$Css$em(0.75)),
						$rtfeldman$elm_css$Css$marginLeft(
						$rtfeldman$elm_css$Css$em(2)),
						$rtfeldman$elm_css$Css$marginBottom(
						$rtfeldman$elm_css$Css$em(1)),
						$rtfeldman$elm_css$Css$listStyle($rtfeldman$elm_css$Css$circle)
					])),
			attrs));
};
var $author$project$S$ul1 = $author$project$Style$List$ul1;
var $author$project$ROI$View$toConsider = function (translation) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$author$project$S$h4,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(translation.bg)
					])),
				A2(
				$author$project$S$ul,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$author$project$S$li,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$a,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank'),
										$rtfeldman$elm_css$Html$Styled$Attributes$href('https://docs.google.com/document/d/1aUqrhYBRZN8Jl-743LIzvgfm_LS56JDy3WDlvxHlN2Q/edit#heading=h.6fanfmabmzl3')
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text(translation.a1)
									]))
							])),
						A2(
						$author$project$S$li,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(translation.aX)
							])),
						A2(
						$author$project$S$li,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(translation.bl),
								A2($author$project$S$ul1, _List_Nil, translation.bm)
							]))
					]))
			]));
};
var $author$project$ROI$View$view = F6(
	function (language, numberOfGreenhouses, systemPrice, monthlySubscription, numberOfEquipments, gui) {
		var translation = A2(
			$author$project$Language$getTranslation,
			language,
			{dG: $author$project$ROI$View$en, dI: $author$project$ROI$View$fr});
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$author$project$S$h2,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(translation.bp)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$p,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$marginBottom(
									$rtfeldman$elm_css$Css$rem(2))
								]))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(translation.aY)
						])),
					$author$project$S$talk1(translation.a4),
					A2(
					$rtfeldman$elm_css$Html$Styled$p,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$marginTop(
									$rtfeldman$elm_css$Css$rem(-1.5)),
									$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center)
								]))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text($author$project$Info$phoneNumber)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$author$project$Style$Grid$displayGrid,
									$author$project$Style$Grid$gridRowGap('2rem')
								]))
						]),
					_List_fromArray(
						[
							A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
							A4($author$project$ROI$View$timeSpared, language, translation, numberOfGreenhouses, gui),
							A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
							A3($author$project$ROI$View$gains, translation, gui, numberOfEquipments),
							A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
							A6($author$project$ROI$View$results, translation, numberOfGreenhouses, systemPrice, monthlySubscription, numberOfEquipments, gui),
							A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
							$author$project$ROI$View$toConsider(translation)
						]))
				]));
	});
var $author$project$Main$roiContent = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class($author$project$Style$Bulma$box),
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Css$padding2,
						$rtfeldman$elm_css$Css$rem(6),
						$rtfeldman$elm_css$Css$rem(10)),
						$author$project$Style$Media$onMobile(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$width(
								$rtfeldman$elm_css$Css$pct(100)),
								A2(
								$rtfeldman$elm_css$Css$padding2,
								$rtfeldman$elm_css$Css$rem(2),
								$rtfeldman$elm_css$Css$rem(1.5))
							]))
					]))
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$displayFlex,
								$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$flexEnd),
								$rtfeldman$elm_css$Css$marginBottom(
								$rtfeldman$elm_css$Css$rem(4))
							]))
					]),
				_List_fromArray(
					[
						$author$project$Main$languageSelectionView(model.aj)
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$map,
				$author$project$Main$ROIMsg,
				A6($author$project$ROI$View$view, model.aj, model.aK, model.bV, model.bw, model.bz, model.aO))
			]));
};
var $rtfeldman$elm_css$Css$url = function (urlValue) {
	return {ab: 0, D: 'url(' + (urlValue + ')')};
};
var $author$project$Main$view = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$displayFlex,
						$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center)
					]))
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$fixed),
								$rtfeldman$elm_css$Css$backgroundImage(
								$rtfeldman$elm_css$Css$url('/bg-1366.jpg')),
								$rtfeldman$elm_css$Css$backgroundSize($rtfeldman$elm_css$Css$cover),
								$rtfeldman$elm_css$Css$displayFlex,
								$rtfeldman$elm_css$Css$height(
								$rtfeldman$elm_css$Css$pct(100)),
								$rtfeldman$elm_css$Css$width(
								$rtfeldman$elm_css$Css$pct(100)),
								$rtfeldman$elm_css$Css$zIndex(
								$rtfeldman$elm_css$Css$int(-10))
							]))
					]),
				_List_Nil),
				$author$project$Main$roiContent(model)
			]));
};
var $author$project$Main$main = $elm$browser$Browser$element(
	{
		dN: function (url) {
			return $author$project$Main$init(url);
		},
		d5: $elm$core$Basics$always($elm$core$Platform$Sub$none),
		ea: $author$project$Main$update,
		ec: A2($elm$core$Basics$composeR, $author$project$Main$view, $rtfeldman$elm_css$Html$Styled$toUnstyled)
	});
_Platform_export({'Main':{'init':$author$project$Main$main($elm$json$Json$Decode$string)(0)}});}(this));