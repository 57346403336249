import "../node_modules/font-awesome/css/font-awesome.min.css"
import "../node_modules/bulma/css/bulma.css"

import "./css/main.css"
import "./css/datepicker.css"
import { Elm } from "./Main.elm"

const app = Elm.Main.init({
    node: document.getElementById("root"),
    flags: location.href
})
